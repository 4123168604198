import { Injectable } from '@angular/core';
import { EntityUIQuery, ID, Order, QueryEntity } from '@datorama/akita';
import { filter } from 'rxjs';
import { NewsfeedUI } from './newsfeed-ui.model';
import { Newsfeed } from './newsfeed.model';
import { NewsfeedState, NewsfeedStore, NewsfeedUIState } from './newsfeed.store';

@Injectable({ providedIn: 'root' })
export class NewsfeedQuery extends QueryEntity<NewsfeedState> {
  override ui: EntityUIQuery<NewsfeedUIState>;

  loadedGetDiscover$ = this.select('loadedGetDiscover').pipe(filter(x => x));
  loadedGetMyFeed$ = this.select('loadedGetMyFeed').pipe(filter(x => x));

  constructor(protected override store: NewsfeedStore) {
    super(store);
    this.createUIQuery();
  }

  getItem = (id: string | ID) => this.getEntity(id);
  selectItem = (id: string | ID) => this.selectEntity(id);
  selectPropertyItem = <K extends keyof Newsfeed>(id: string, property: K) => this.selectEntity(id, property);
  getActiveIdItem = () => this.getActiveId();
  selectActiveIdItem = () => this.selectActiveId();
  getActiveItem = () => this.getActive();
  selectActiveItem = () => this.selectActive();
  selectManyItem = (id: string[] | ID[]) => this.selectMany(id);
  getState = () => this.getValue();
  selectUIState = (channelId: string) => this.ui.selectEntity(channelId);
  selectPropertyUIState = <K extends keyof NewsfeedUI>(id: string | ID, property: K) =>
    this.ui.selectEntity(id, property);

  getAllItemsMyOrg(orgUuid: string) {
    return this.getAll({
      filterBy: e => e.org?.uuid === orgUuid && !e.isArchived
    });
  }

  getFeedUiState(channelId: string | ID) {
    return this.ui.getEntity(channelId);
  }

  getMyFeeds() {
    return this.getAll({
      filterBy: e => e.isMyFeed,
      sortBy: (a, b) => a.channelName.localeCompare(b.channelName),
      sortByOrder: Order.ASC
    });
  }

  getMyFeedsAndArchived() {
    return this.getAll({
      filterBy: e => e.isMyFeed && e.isArchived,
      sortBy: (a, b) => a.channelName.localeCompare(b.channelName),
      sortByOrder: Order.ASC
    });
  }

  getDiscoveries() {
    return this.getAll({ filterBy: e => !e.isMyFeed && !e.isArchived });
  }

  getFeedCanSubsriber(limit: number) {
    return this.getAll({
      filterBy: e => !e.role && !e.isArchived,
      sortBy: (a, b) => a.channelName.localeCompare(b.channelName),
      sortByOrder: Order.ASC,
      limitTo: limit
    });
  }

  countMyFeeds() {
    return this.selectCount(entity => entity.isMyFeed);
  }
}
