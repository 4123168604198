import { Injectable } from '@angular/core';
import { StatusUserResponse } from '@b3networks/api/workspace';
import { ActiveState, EntityState, EntityStore, EntityUIStore, HashMap, StoreConfig } from '@datorama/akita';
import { UserDirectory, UserDirectoryUI } from './user.model';

export interface UserDirectoryState extends EntityState<UserDirectory>, ActiveState {
  loadedAllUser: boolean;
  userStatus: HashMap<StatusUserResponse>; // chatUserid
  userDeleted: HashMap<boolean>; // chatUserId
}

export type UserUIState = EntityState<UserDirectoryUI>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'directory_user', idKey: 'chatUserId' })
export class UserDirectoryStore extends EntityStore<UserDirectoryState> {
  override ui: EntityUIStore<UserUIState>;
  constructor() {
    super({
      loadedAllUser: false,
      userStatus: <HashMap<StatusUserResponse>>{},
      userDeleted: <HashMap<boolean>>{}
    });

    this.createUIStore({}, { deepFreezeFn: obj => obj }).setInitialEntityState(
      entity =>
        <UserDirectoryUI>{
          loadedDetailFromAuth: false,
          loadedTeams: false
        }
    );
  }
}
