import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HashMap } from '@datorama/akita';
import { Observable, tap } from 'rxjs';
import { SelfPermissionStore } from './self-permission.store';

@Injectable({
  providedIn: 'root'
})
export class SelfPermissionService {
  constructor(
    private http: HttpClient,
    private store: SelfPermissionStore
  ) {}

  getPermissionsV2(): Observable<HashMap<string>> {
    return this.http.get<HashMap<string>>(`auth/private/v1/self/permissions`).pipe(
      tap(permissions => {
        this.store.update(permissions);
      })
    );
  }
}
