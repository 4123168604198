<ng-container *ngIf="data$ | async as data; else spinner">
  <div class="landing-page__container" [class.dark-theme-portal-base]="isDarkMode$ | async">
    <ng-container *ngIf="data.currentOrg; let currentOrg">
      <div
        *ngIf="colorPallet$ | async as colorPallet"
        [style.background]="colorPallet.bgColor"
        class="org-home__container"
      >
        <div class="org-home__wrapper">
          <div class="org-home__header">
            <div>
              <div [style.color]="colorPallet.fontColor" class="title">Welcome, {{ data.profile?.displayName }}!</div>
              <div *ngIf="data.lastLogin; let lastLogin" [style.color]="colorPallet.fontColor" class="last-login">
                <span class="content" (click)="navigateToActivity()">
                  Last sign-in on web client:
                  {{ lastLogin.time | userDate }}
                </span>
                -
                <span [ngClass]="lastLogin.successful ? 'successful' : 'failed'">
                  {{ lastLogin.successful ? 'Successful' : 'Failed' }}
                </span>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <section class="org-home__body">
            <b3n-landing-page-announcements></b3n-landing-page-announcements>
          </section>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #spinner>
  <div class="org-home-spinner flex flex-col justify-center items-center">
    <mat-spinner diameter="40" mode="indeterminate" strokeWidth="2"></mat-spinner>
  </div>
</ng-template>
