<mat-toolbar class="header-toolbar flex !flex-col items-start !mb-4">
  <div class="mt-4 mb-[10px] w-full">
    <div class="flex items-center">
      <div class="item-link" (click)="goBack()">Organization connect</div>
      <mat-icon class="icon-link">chevron_right</mat-icon>
      <div class="page-title">{{ orgName }}</div>
    </div>
    <span>Manage sharing resources with connected organizations.</span>
  </div>
  <mat-divider></mat-divider>
</mat-toolbar>
<div class="container-org">
  <mat-tab-group
    [animationDuration]="0"
    [(selectedIndex)]="currentIndexTab"
    (selectedTabChange)="tabChange($event)"
    [mat-stretch-tabs]="false"
  >
    <mat-tab *ngFor="let tab of listTabs" [label]="tab === resourceType.channel ? 'Support ticket channel' : tab">
      <ng-template
        [ngTemplateOutlet]="resource"
        [ngTemplateOutletContext]="{
          dataSource: dataSource,
          key: tab
        }"
      ></ng-template>
    </mat-tab>
  </mat-tab-group>

  <ng-template #resource let-dataSource="dataSource" let-key="key">
    <div class="content-form mat-elevation-z1">
      <mat-toolbar-row class="header-action-table">
        <form class="flex justify-between items-center w-full" autocomplete="off" [formGroup]="form">
          <div class="flex gap-4">
            <mat-form-field class="status">
              <mat-label>View</mat-label>
              <mat-select formControlName="view">
                <mat-option [value]="view_resource.shared">Shared resources</mat-option>
                <mat-option [value]="view_resource.received">Received resources</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="status" *ngIf="currentTab !== resourceType.numbers">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option value="">All</mat-option>
                <mat-option value="ACTIVE">Active</mat-option>
                <mat-option value="INACTIVE">Inactive</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Search</mat-label>
              <input
                matInput
                formControlName="search"
                [placeholder]="'Search by ' + (key === 'DNC' ? key : (key | lowercase))"
                #input
              />
              <button type="button" mat-icon-button matSuffix>
                <mat-icon class="btn-search">search</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <button
            *ngIf="viewResourceCtrl.value === view_resource.shared"
            mat-raised-button
            color="primary"
            type="button"
            (click)="addResource()"
          >
            + Add
          </button>
        </form>
      </mat-toolbar-row>
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      <div class="px-4" *ngIf="!loading">
        <table mat-table [dataSource]="dataSource" class="org-link__container org-link__table">
          <ng-container matColumnDef="uuid">
            <th mat-header-cell *matHeaderCellDef [hidden]="key === resourceType.numbers">UUID</th>
            <td mat-cell *matCellDef="let e" [hidden]="key === resourceType.numbers">
              <button
                class="box-uuid"
                mat-button
                matTooltip="Click to copy"
                [cdkCopyToClipboard]="e.resourceId"
                (cdkCopyToClipboardCopied)="copied()"
                (click)="$event.stopPropagation()"
              >
                {{ e.resourceId | slice: 0 : 8 }}
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="resource">
            <th mat-header-cell *matHeaderCellDef>{{ key }}</th>
            <td mat-cell *matCellDef="let e">
              {{ key === resourceType.numbers ? e.key?.substring(0, e.key.indexOf('@')) : e.resourceName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef>Created at</th>
            <td mat-cell *matCellDef="let e">{{ e.createdAt | userDate }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef [hidden]="key === resourceType.numbers">Status</th>
            <td mat-cell *matCellDef="let e" [hidden]="key === resourceType.numbers">
              <div [ngClass]="e.status | lowercase">
                {{ e.status | capitalizeCase }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let e">
              <mat-icon
                *ngIf="viewResourceCtrl.value === view_resource.shared"
                (click)="deleteResource(e)"
                matTooltip="Revoke"
                color="warn"
                class="actions-icon"
              >
                delete_outline
              </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="noData">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
              <div class="flex justify-center items-center">
                <span>No data available</span>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="['noData']"
            class="no-data-table"
            [hidden]="dataSource?.data?.length"
          ></tr>
        </table>

        <mat-paginator
          [showFirstLastButtons]="true"
          [pageSize]="pageSize"
          [hidePageSize]="true"
          [length]="dataSource?.data?.length"
          [pageIndex]="currentPage"
          (page)="onChangePage($event.pageIndex)"
        ></mat-paginator>
      </div>
    </div>
  </ng-template>
</div>
