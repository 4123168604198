import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from '@b3networks/api/common';
import { ID } from '@datorama/akita';
import { map, tap } from 'rxjs';
import { NewsfeedUI } from './newsfeed-ui.model';
import {
  MediaNewsFeed,
  Newsfeed,
  NewsfeedBase,
  NewsfeedStatus,
  OrganizationNewsFeed,
  ReqCreateNewsFeed,
  ReqSendMessageNF,
  ReqUpdateNewsFeed,
  ReqUploadFileNF,
  ResUpdateFileNF
} from './newsfeed.model';
import { NewsfeedQuery } from './newsfeed.query';
import { NewsfeedState, NewsfeedStore } from './newsfeed.store';

@Injectable({
  providedIn: 'root'
})
export class NewsfeedService {
  constructor(
    private http: HttpClient,
    private store: NewsfeedStore,
    private query: NewsfeedQuery
  ) {}

  createNewsFeed(req: ReqCreateNewsFeed, orgInfo: OrganizationNewsFeed) {
    return this.http.post<NewsfeedBase>('/inbox/private/v1/newsfeed/_create', req).pipe(
      map(res => this.sanitizeModel(res, orgInfo.uuid)),
      tap(data => {
        this.upsertOneFeed(data);
      })
    );
  }

  updateNewsFeed(channelId: string, req: ReqUpdateNewsFeed, orgUuid: string) {
    return this.http.put<Newsfeed>(`/inbox/private/v1/newsfeed/${channelId}`, req).pipe(
      map(resp => this.sanitizeModel(resp, orgUuid)),
      tap(data => {
        this.upsertOneFeed(data);
      })
    );
  }

  closeNF(channelId: string) {
    return this.http.put<void>(`/inbox/private/v1/newsfeed/${channelId}/_close`, {}).pipe(
      tap(resp => {
        const feed = this.query.getItem(channelId);
        if (feed) {
          this.upsertOneFeed(<Newsfeed>{
            channelId: feed.channelId,
            status: NewsfeedStatus.archive
          });
        }
      })
    );
  }

  reopenNF(channelId: string) {
    return this.http.put<void>(`/inbox/private/v1/newsfeed/${channelId}/_reopen`, {}).pipe(
      tap(resp => {
        const feed = this.query.getItem(channelId);
        if (feed) {
          this.upsertOneFeed(<Newsfeed>{
            channelId: feed.channelId,
            status: NewsfeedStatus.active
          });
        }
      })
    );
  }

  subscribeNF(channelId: string) {
    return this.http.post<void>(`/inbox/private/v1/newsfeed/${channelId}/_subscribe`, {});
  }

  unsubscribeNF(channelId: string) {
    return this.http.post<void>(`/inbox/private/v1/newsfeed/${channelId}/_unsubscribe`, {});
  }

  getMyNF(orgUuid: string) {
    return this.http.get<Newsfeed[]>(`/inbox/private/v1/newsfeed/_getMyNewsfeed`, {}).pipe(
      map(ls => ls?.map(x => this.sanitizeModel(x, orgUuid))),
      tap(feeds => {
        this.upsertManyFeeds(feeds);
        this.updateStateStore({
          loadedGetMyFeed: true
        });
      })
    );
  }

  getOne(newsfeedId: string, orgUuid: string) {
    return this.http.get<Newsfeed>(`/inbox/private/v1/newsfeed/${newsfeedId}`).pipe(
      map(x => this.sanitizeModel(x, orgUuid)),
      tap(feed => {
        this.upsertOneFeed(feed);
      })
    );
  }

  getDiscoverNF(orgUuid: string) {
    return this.http.get<Newsfeed[]>(`/inbox/private/v1/newsfeed/_discover`, {}).pipe(
      map(ls => ls?.map(x => this.sanitizeModel(x, orgUuid))),
      tap(feeds => {
        this.upsertManyFeeds(feeds);
        this.updateStateStore({
          loadedGetDiscover: true
        });
      })
    );
  }

  sendMessage(channelId: string, req: ReqSendMessageNF) {
    return this.http.post<void>(`inbox/private/v1/newsfeed/${channelId}/messages`, req);
  }

  getToken(channelId: string, req: ReqUploadFileNF) {
    return this.http.post<ResUpdateFileNF>(`inbox/private/v1/newsfeed/${channelId}/medias/_uploadToken`, req);
  }

  getListMedia(channelId: string, pageable: Pageable) {
    let params = new HttpParams();
    Object.keys(pageable).forEach(key => {
      if (pageable?.[key]) {
        params = params.append(key, pageable?.[key] + '');
      }
    });
    return this.http.get<MediaNewsFeed[]>(`inbox/private/v1/newsfeed/${channelId}/medias`, { params });
  }

  upsertManyFeeds(channels: Newsfeed[]) {
    this.store.upsertMany(channels, { baseClass: Newsfeed });
  }

  upsertOneFeed(channel: Newsfeed) {
    this.store.upsert(channel.channelId, channel, { baseClass: Newsfeed });
  }

  removeFeed(channelId: string) {
    if (channelId) this.store.remove(channelId);
  }

  updateFeedViewState(channelId: string | string[], state: Partial<NewsfeedUI>) {
    this.store.ui.update(channelId, entity => ({
      ...entity,
      ...state
    }));
  }

  updateStateStore(data: Partial<NewsfeedState>) {
    this.store.update(data);
  }

  setActive(feedId: string | ID) {
    this.store.setActive(feedId);
  }

  removeActive(feedId: string | ID) {
    this.store.removeActive(feedId);
  }

  private sanitizeModel(item: NewsfeedBase, orgUuid: string) {
    if (!item) return null;

    const clone = <Partial<Newsfeed>>{};
    if (item.channelId) clone.channelId = item.channelId;
    if (item.channelName) clone.channelName = item.channelName;
    if (item.status) clone.status = item.status;
    if (item.org) {
      clone.org = <OrganizationNewsFeed>{};
      if (item.org.uuid) clone.org.uuid = item.org.uuid;
      if (item.org.name) clone.org.name = item.org.name;
      if (item.org.logoUrl) clone.org.logoUrl = item.org.logoUrl;
    }
    if (item.description) clone.description = item.description;
    if (item.at) clone.at = item.at;
    if (item.role) clone.role = item.role;
    return new Newsfeed(clone).withOrgUuid(orgUuid);
  }
}
