import { encodeUserId } from '@b3networks/shared/common';
import { ID } from '@datorama/akita';
import { RoleNewsFeed } from '../newsfeed.model';

export interface MemberNewsfeedUI {
  loaded: boolean;
}

export class MemberNewsfeed {
  newsfeedId?: string;
  participant: ParticipantNewsfeed[];

  constructor(obj?: Partial<MemberNewsfeed>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj?.participant) this.participant = obj?.participant?.map(x => new ParticipantNewsfeed(x));
    }
  }
}

export class ParticipantNewsfeedBase {
  chatUserId: string; // required. Unique for each identity and org
  orgUuid: string; // required
  memberUuid: string; // required
  name: string;
  role: RoleNewsFeed;
  createdAt: number;

  constructor(obj?: Partial<ParticipantNewsfeedBase>) {
    if (obj) {
      Object.assign(this, obj);

      try {
        if (!this.chatUserId && (!this.orgUuid || !this.memberUuid)) {
          throw new Error();
        }

        if (!this.chatUserId && this.orgUuid && this.memberUuid) {
          this.chatUserId = encodeUserId(this.orgUuid, this.memberUuid);
        }
      } catch (error) {
        console.error('chatUserId need check: ', this);
      }

      if (!this.chatUserId || !this.orgUuid || !this.memberUuid) {
        console.warn('warning input: ', this);
      }
    }
  }

  get id() {
    return this.chatUserId as ID;
  }

  get isPublisher() {
    return this.role && [RoleNewsFeed.owner, RoleNewsFeed.admin].includes(this.role);
  }

  get isSubscriber() {
    return this.role && [RoleNewsFeed.subscriber].includes(this.role);
  }

  get isOwner() {
    return this.role && this.role === RoleNewsFeed.owner;
  }
}

export class ParticipantNewsfeed extends ParticipantNewsfeedBase {
  constructor(obj?: Partial<ParticipantNewsfeed>) {
    super(obj);
    if (obj) {
    }
  }
}

export interface RequestUpdateManyMemberNF {
  orgUuid: string;
  members: string[]; // identityUuids
  role: RoleNewsFeed;
  action: RequestUpdateMemberNFAction;
}

//*
// "u-1": "ok",
// "u-2": "inbox.memberNotExists",
// "u-3": "inbox.memberAlreadyExists",
// "u-4": "error message"
// *//
export interface ResponseUpdateManyMemberNF {
  [key: string]: string;
}

export enum RequestUpdateMemberNFAction {
  update = 'update',
  add = 'add',
  remove = 'remove',
  transferOwne = 'transferOwner' // user belongs participant
}
