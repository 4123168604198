import { Injectable } from '@angular/core';
import { IdentityProfile, ProfileOrg } from '@b3networks/api/auth';
import { Store, StoreConfig } from '@datorama/akita';

export const SESSION_KEY = 'sessionToken';
export const TRUSTED_BROWSER = 'trustedBrowserID';

export interface SessionState {
  validatedSession?: boolean;
  sessionExpipryAt?: Date;
  fallbackSessionToken?: string;
  profile: IdentityProfile;
  currentOrg: ProfileOrg;
  servicedOrgs: ProfileOrg[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'portal_session', resettable: true })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super({});
  }

  logout() {
    this.reset();
  }
}
