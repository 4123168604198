<mat-toolbar class="header-toolbar flex !flex-col !items-start" *ngIf="appID === APP_IDS.ORG_MANAGEMENTS">
  <div class="mt-[16px] mb-[10px]">
    <div class="page-header">Organization connect</div>
    <span>Connect your organizations with one another to share directory and make inter-organization calls.</span>
  </div>
  <mat-divider></mat-divider>
</mat-toolbar>

<div
  class="org-link__container notification cursor-pointer"
  *ngIf="form.controls['status'].value !== 'PENDING' && statusPendingCount > 0"
  (click)="this.form.patchValue({ status: 'PENDING' })"
>
  There are {{ statusPendingCount }} pending invites
</div>

<div class="org-link__container" *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="content-form mat-elevation-z1" [hidden]="loading">
  <mat-toolbar-row class="header-action-table">
    <form class="org-link__toolbar w-100per" autocomplete="off" [formGroup]="form">
      <div class="filter__wrapper">
        <mat-form-field>
          <mat-label>Search</mat-label>
          <input matInput formControlName="uuid" placeholder="Search by uuid" #input />
          <button type="button" mat-icon-button matSuffix>
            <mat-icon class="btn-search">search</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="status">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option value="PENDING">Pending</mat-option>
            <mat-option value="ACTIVE">Active</mat-option>
            <mat-option value="REJECTED">Declined</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary" type="button" (click)="openOrgLinkInvite()">Create connection</button>
    </form>
  </mat-toolbar-row>

  <div class="px-4">
    <table mat-table [dataSource]="dataSource" class="org-link__container org-link__table">
      <ng-container matColumnDef="uuid">
        <th mat-header-cell *matHeaderCellDef>Connection ID</th>
        <td mat-cell *matCellDef="let orgLink">
          <div class="flex items-center gap-1">
            <div class="font-[550] text-[13px]">{{ orgLink.uuid | slice: 0 : 8 }}</div>
            <shc-copy-icon [textCopy]="orgLink.uuid" class="invisible action-copy"></shc-copy-icon>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="organization">
        <th mat-header-cell *matHeaderCellDef>Organization</th>
        <td mat-cell *matCellDef="let orgLink">
          {{ currentOrgUuid === orgLink.initiator.uuid ? orgLink.recipient.name : orgLink.initiator.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>Created at</th>
        <td mat-cell *matCellDef="let orgLink">{{ orgLink.createdAt | userDate }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let orgLink">
          <div [ngClass]="orgLink.status | lowercase">
            {{ (orgLink.status === 'REJECTED' ? 'Declined' : orgLink.status) | capitalizeCase }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let orgLink">
          <div class="icons">
            <mat-icon
              (click)="onConnectDetail(orgLink)"
              *ngIf="orgLink.status === 'ACTIVE'"
              matTooltip="Share"
              class="actions-icon"
              >share</mat-icon
            >
            <mat-icon
              *ngIf="orgLink.status === 'REJECTED'"
              matTooltip="Resend"
              class="actions-icon"
              (click)="reinvite(orgLink?.recipient?.uuid)"
            >
              send
            </mat-icon>
            <ng-container *ngIf="orgLink.isRecipientOrg">
              <mat-icon color="primary" matTooltip="Accept" (click)="accept(orgLink.uuid)">done</mat-icon>
              <mat-icon color="warn" matTooltip="Deny" (click)="deny(orgLink.uuid)">close</mat-icon>
            </ng-container>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="noData">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <div class="flex flex-row items-center justify-center">
            <span class="mat-body-1">No data available</span>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['noData']"
        class="no-data-table"
        [class.hidden]="orgLinksFilter?.length"
      ></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" hidePageSize [pageSize]="pageSize"></mat-paginator>
  </div>
</div>
