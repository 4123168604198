import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormControlStatus, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Country, CountryQuery, CountryService } from '@b3networks/api/auth';
import { ButtonLoadingModule } from '@b3networks/shared/ui/material';
import {
  CountryCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
  PhoneNumber
} from 'libphonenumber-js/max';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { DestroySubscriberComponent } from '../destroy-subscriber.component';

export interface AdditionalErrs {
  msg: string;
  isInvalid: boolean;
}

/**
 * How to use:
 *
 *  Container:
 *    <shc-phone-number
 *      [formControlNumber]="formControl"               // Form control. (1)
 *      [(number)]="number"                             // Tracks the phone number bound. (2)
 *      [disabled]="true/false"                         // Default is false, used to disable phone input.
 *      [required]="true/false"                         // Default is false, used to check require phone input.
 *      [placeholder]="Input number"                    // Default is 'Input number'.
 *      [maxLength]="0"                                 // Default is 0, used to set the input number to the maximum length excluding the prefix country.
 *      [preferredCountry]="SG"                         // Default is SG, used to set the selected country default.
 *      [countriesWhiteList]="countriesWhiteList"       // Default is [], get list country filtered for dropdown list country.
 *      [hasCountryWhiteList]="true/false"              // Default is false, check has countriesWhiteList, if has not countriesWhiteList, dropdown list country are all country.
 *      [additionalErrs]="[{                            // Default is [], used to set errs additional.
 *        msg: 'Number has existed',
 *        isInvalid: phoneNumber.hasError('duplicated')
 *      }]"
 *      (keyupEnter)="handleKeyupEnter()"               // Catch action on enter when input phone number valid.
 *      (inputPhoneNumChange)="inputPhoneNumChange()"   // Catch action on input phone number change.
 *      (countryChanged)="countryChanged()"             // Catch action on country selector change.
 *    >
 *      <div matSuffix></div>                           // 'matSuffix' Suffix to be placed at the end of the form field.
 *      <div></div>                                     // Infix to be placed in center of the form field.
 *      <div matPrefix></div>                           // 'matPrefix' Prefix to be placed in front of the form field.
 *      <mat-hint></mat-hint>                           // Hint description.
 *    </shc-phone-number>
 *
 *  Only one of the two input values ((1),(2)) above can be used as the main control for input.
 */

@Component({
  selector: 'shc-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    ButtonLoadingModule,
    MatTooltipModule,
    MatDividerModule,
    ReactiveFormsModule
  ]
})
export class PhoneNumberComponent extends DestroySubscriberComponent implements OnInit, OnChanges {
  countries: Country[] = [];
  countriesOrigin: Country[] = [];
  countryCode: string;
  countryFilter = new FormControl('');
  countryCtr = new FormControl(null);
  phoneNumber = new FormControl(null);
  phoneNumberParseTemp: '' | PhoneNumber;

  @Output() keyupEnter = new EventEmitter();
  @Output() numberChange = new EventEmitter<string>();
  @Output() inputPhoneNumChange = new EventEmitter<string>();
  @Output() countryChanged = new EventEmitter<Country>();
  @Input() number: string;
  @Input() formControlNumber: FormControl = new FormControl('');
  @Input() disabled = false;
  @Input() required = false;
  @Input() noValidator = false;
  @Input() placeholder = 'Input number';
  @Input() maxLength = 0;
  @Input() preferredCountry = 'SG';
  @Input() className = '';
  @Input() countriesWhiteList: string[] = [];
  @Input() hasCountryWhiteList = false;
  @Input() additionalErrs: AdditionalErrs[] = [];
  @ViewChild('matFormField') matFormField: MatFormField;

  constructor(
    private countryService: CountryService,
    private countryQuery: CountryQuery
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const numberChange = changes['number'];
    const disabledChange = changes['disabled'];
    const countriesWhiteList = changes['countriesWhiteList'];
    const preferredCountry = changes['preferredCountry'];
    if (numberChange?.currentValue !== numberChange?.previousValue) {
      const phoneNumber = numberChange?.currentValue ? parsePhoneNumber(numberChange?.currentValue) : '';
      if (numberChange.firstChange) {
        this.phoneNumberParseTemp = phoneNumber;
        return;
      } else this.phoneNumberParseTemp = null;
      if (phoneNumber) {
        const countryFound = this.countriesOrigin?.find(c => phoneNumber?.country === c.code);
        this.countryCtr.setValue(countryFound);
        this.phoneNumber.setValue(phoneNumber?.nationalNumber);
      }
    }
    if (disabledChange) {
      disabledChange?.currentValue
        ? (this.countryCtr.disable(), this.phoneNumber.disable(), this.formControlNumber.disable())
        : (this.countryCtr.enable(), this.phoneNumber.enable(), this.formControlNumber.enable());
    }

    if (countriesWhiteList?.currentValue) {
      this.countriesOrigin = structuredClone(this.countriesOrigin).filter(
        country =>
          !!Number(country.prefix) &&
          (this.hasCountryWhiteList ? this.countriesWhiteList?.includes(country.code) : true)
      );
      this._updateCountryFiltered(this.countriesOrigin);
    }

    if (preferredCountry?.currentValue) {
      this._setCountryCode();
    }
  }

  ngOnInit(): void {
    this.phoneNumber.setValidators(this.formControlNumber.validator);
    !this.countryQuery.getAll().length && this.countryService.getCountry().subscribe();
    this.countryQuery.countries$
      .pipe(
        takeUntil(this.destroySubscriber$),
        filter(countries => !!countries.length)
      )
      .subscribe(countries => {
        this.countriesOrigin = structuredClone(countries).filter(country => !!Number(country.prefix));
        this._updateCountryFiltered(countries);
        this._setCountryCode();
        this.phoneNumberParseTemp && this.phoneNumber.setValue(this.phoneNumberParseTemp?.nationalNumber);
        !this.number && this._updatePhoneNum(this.formControlNumber.value);
      });

    this._checkStatusForm(this.formControlNumber.status);

    this.phoneNumber.valueChanges.pipe(distinctUntilChanged()).subscribe(number => {
      this.inputPhoneNumChange.emit(number);
      if (!number?.toString()?.length) {
        this.formControlNumber.setValue('');
        // Tracking number change.
        this.numberChange.emit('');
        return;
      }
      const isValidPhoneNumber = this._checkValidForm(number.toString(), this.countryCtr.value?.code as CountryCode);
      if (isValidPhoneNumber) {
        const phoneNumber = parsePhoneNumber(number.toString(), this.countryCtr.value?.code as CountryCode);
        this.formControlNumber.setValue(phoneNumber?.number);
        this.phoneNumber.setValue(phoneNumber?.nationalNumber);
        this.countryCode = phoneNumber?.country;

        // Tracking number change.
        this.numberChange.emit(phoneNumber?.number);
      }
      this.phoneNumber.setErrors(this.formControlNumber.errors);
    });

    this.countryFilter.valueChanges.subscribe((searchText: string) => {
      this.countries = this.countriesOrigin?.filter(country => {
        const { name, prefix } = country;
        return name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || prefix.includes(searchText);
      });
    });

    this.countryCtr.valueChanges
      .pipe(
        filter(county => !!county),
        distinctUntilChanged()
      )
      .subscribe((country: Country) => {
        this.countryChanged.emit(country);
        this.phoneNumber.value?.length && this.phoneNumber.setValue('');
      });

    // Tracking form control change.
    this.formControlNumber.valueChanges.pipe(distinctUntilChanged()).subscribe(value => this._updatePhoneNum(value));

    // Subscribe to statusChanges observable to watch for changes in the control's status
    this.formControlNumber.statusChanges
      .pipe(distinctUntilChanged())
      .subscribe(status => this._checkStatusForm(status));
  }

  onEnterKey() {
    this.formControlNumber.valid && this.keyupEnter.emit(this.formControlNumber.value);
  }

  selectFocusChange(focus: boolean) {
    setTimeout(() => {
      focus
        ? this.matFormField._textField.nativeElement.classList.add('mdc-text-field--focused')
        : this.matFormField._textField.nativeElement.classList.remove('mdc-text-field--focused');
    }, 10);
  }

  private _checkStatusForm(status: FormControlStatus) {
    if (status === 'DISABLED') {
      this.phoneNumber.disable();
      this.countryCtr.disable();
    }
    if (status === 'VALID') {
      this.phoneNumber.enable();
      this.countryCtr.enable();
    }
  }

  private _updatePhoneNum(value: string) {
    if (!value) this.phoneNumber.setValue('');
    else {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        const countryFound = this.countriesOrigin?.find(c => phoneNumber?.country === c.code);
        this.countryCode = phoneNumber?.country;
        this.countryCtr.setValue(countryFound);
        this.phoneNumber.setValue(phoneNumber?.nationalNumber);
      }
    }
  }

  private _checkValidForm(phoneNumber: string, code: CountryCode) {
    const isValidLength = this.maxLength ? phoneNumber.length <= this.maxLength : true;
    const isValidPhoneNum = isPossiblePhoneNumber(phoneNumber, code) && isValidPhoneNumber(phoneNumber, code);
    const isValid = this.maxLength ? isValidLength && isValidPhoneNum : isValidPhoneNum;
    if (!isValid) {
      this.formControlNumber.setErrors(!isValidLength ? { maxLength: true } : { pattern: true });
      this.phoneNumber.setErrors(!isValidLength ? { maxLength: true } : { pattern: true });
    } else {
      this.formControlNumber.setErrors(null);
      this.phoneNumber.setErrors(null);
    }
    return isValid;
  }

  private _updateCountryFiltered(countries: Country[]) {
    const tempCountries = structuredClone(countries).filter(country => !!Number(country.prefix));
    const countriesWhitelist = tempCountries?.filter(res => this.countriesWhiteList?.includes(res?.code));

    this.countries = this.hasCountryWhiteList ? countriesWhitelist : tempCountries;
  }

  private _setCountryCode() {
    const countryFound = this.countriesOrigin?.find(c => {
      if (this.phoneNumberParseTemp) return this.phoneNumberParseTemp?.country === c.code;
      return this.countryCode ? c.code === this.countryCode : c.code === this.preferredCountry;
    });
    this.countryCtr.setValue(countryFound);
  }
}
