import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ResourcesNewsfeed } from './resources.model';
import { ResourcesNewsfeedState, ResourcesNewsfeedStore } from './resources.store';

@Injectable({ providedIn: 'root' })
export class ResourcesNewsfeedQuery extends QueryEntity<ResourcesNewsfeedState> {
  constructor(protected override store: ResourcesNewsfeedStore) {
    super(store);
  }

  getItem(feedId: string) {
    return this.getEntity(feedId);
  }

  selectItem(feedId: string) {
    return this.selectEntity(feedId);
  }

  selectPropertyChannel<K extends keyof ResourcesNewsfeed>(id: string, property: K) {
    return this.selectEntity(id, property);
  }
}
