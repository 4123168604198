import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import {
  IdentityProfileQuery,
  OrgLinkService,
  ResourceActive,
  ShareResourceReq,
  SharingResource,
  SharingResourceNumber
} from '@b3networks/api/auth';
import { ToastService } from '@b3networks/shared/ui/toast';
import { lastValueFrom } from 'rxjs';
import { API_TYPE_CONNECT, RESOURCE_CONNECT } from '../../shared/constants';

export class ResourceData {
  resourceActive: ResourceActive[];
  currentTab: string;
  recipientOrgUuid: string;
  dataShared: SharingResource[] | SharingResourceNumber[];
}

@Component({
  selector: 'b3n-share-resource-dialog',
  templateUrl: './share-resource-dialog.component.html',
  styleUrls: ['./share-resource-dialog.component.scss']
})
export class ShareResourceDialogComponent implements OnInit {
  @ViewChild('optionSelect') selectElem: MatSelect;

  readonly BOTTOM_SCROLL_OFFSET = 25;
  resourceValue: ResourceActive;
  type: string = '';
  progressing: boolean;
  resourceActive: ResourceActive[] = [];
  selection = new SelectionModel<ResourceActive>(true, []);
  page = 0;
  isLoadMore = true;

  constructor(
    public dialog: MatDialog,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<ShareResourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceData,
    private orgLinkService: OrgLinkService,
    private identityProfileQuery: IdentityProfileQuery
  ) {
    this.resourceActive = data.resourceActive;
    this.type = data.currentTab;
  }

  ngOnInit(): void {}

  scrollEvent() {
    const panel = this.selectElem.panel?.nativeElement;
    panel.addEventListener('scroll', async event => await this.checkLoadMoreNumber(event));
  }

  async onSave() {
    this.progressing = true;

    switch (this.type) {
      // TODO: Implement DNC
      // case RESOURCE_CONNECT.dnc:
      //   break;
      // case RESOURCE_CONNECT.inbox:
      //   this.orgLinkService.shareResourceInbox(this._buildBodyReq(RESOURCE_CONNECT.inbox)).subscribe(
      //     _ => {
      //       this.toastService.success('Share resource inboxes successfully');
      //       this.progressing = false;
      //       this.dialogRef.close(true);
      //     },
      //     err => {
      //       this.toastService.warning(err.message);
      //       this.progressing = false;
      //     }
      //   );
      //   break;
      case RESOURCE_CONNECT.channel:
        this.orgLinkService.shareResourceTicketChannel(this._buildBodyReq(RESOURCE_CONNECT.channel)).subscribe(
          _ => {
            this.toastService.success('Share resource channel successfully');
            this.progressing = false;
            this.dialogRef.close(true);
          },
          err => {
            this.toastService.warning(err.message);
            this.progressing = false;
          }
        );
        break;
      case RESOURCE_CONNECT.numbers:
        await this._shareResource(RESOURCE_CONNECT.numbers);
        break;
      case RESOURCE_CONNECT.teams:
        await this._shareResource(RESOURCE_CONNECT.teams);
        break;
      default:
        break;
    }
  }

  onSelected() {
    this.selection.selected.push(this.resourceValue);
    this.resourceActive = this.resourceActive.filter(item => item.uuid !== this.resourceValue.uuid);
    this.resourceValue = null;
  }

  dropDispositionCodes(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selection.selected, event.previousIndex, event.currentIndex);
  }

  deleteResource(resource: ResourceActive) {
    this.selection.selected.splice(this.selection.selected.map(s => s.uuid).indexOf(resource.uuid), 1);
    !!this.resourceActive.find(item => item.uuid !== resource.uuid) && this.resourceActive.push(resource);
  }

  private async checkLoadMoreNumber(event) {
    if (
      event.target.scrollTop >= event.target.scrollHeight - event.target.offsetHeight - this.BOTTOM_SCROLL_OFFSET &&
      this.isLoadMore
    ) {
      this.page++;
      await this._getMoreNumbersResource();
    }
  }

  private async _getMoreNumbersResource() {
    await this.orgLinkService
      .getNumbersResource(this.identityProfileQuery.currentOrg.orgUuid, { page: this.page, perPage: 100 })
      .pipe()
      .subscribe(data => {
        data = data.filter(
          rc =>
            !this.data.dataShared.some(item =>
              this.type === RESOURCE_CONNECT.numbers
                ? item.key?.replace(/@.*$/, '') === rc.name
                : item.resourceId === rc.name
            )
        );
        if (data.length) {
          this.resourceActive = [...this.resourceActive, ...data];
        } else {
          this.isLoadMore = false;
        }
      });
  }

  private async _shareResource(type: RESOURCE_CONNECT) {
    let shareSuccess = [];

    if (type === RESOURCE_CONNECT.numbers) {
      await Promise.all(
        this.selection.selected.map(async item => {
          await lastValueFrom(this.orgLinkService.shareResourceNumber(item.uuid, this.data.recipientOrgUuid))
            .then(_ => {
              shareSuccess.push(item);
            })
            .catch(err => console.log(err))
            .finally();
        })
      );
    }
    if (type === RESOURCE_CONNECT.teams) {
      await Promise.all(
        this.selection.selected.map(async item => {
          await lastValueFrom(
            this.orgLinkService.shareResourceTeams(this._buildBodyReq(RESOURCE_CONNECT.teams, item.uuid))
          )
            .then(_ => {
              shareSuccess.push(item);
            })
            .catch(err => console.log(err))
            .finally();
        })
      );
    }

    if (shareSuccess.length === this.selection.selected.length) {
      this.toastService.success(`Share resource ${type} successfully`);
      this.dialogRef.close(true);
    } else if (shareSuccess.length === 0) {
      this.toastService.error(`Share all resource ${type} failed`);
    } else {
      // Remove resource successfully to share resource failed again.
      shareSuccess.forEach(item =>
        this.selection.selected.splice(this.selection.selected.map(s => s.uuid).indexOf(item), 1)
      );
      this.toastService.warning(`Some resource ${type} failed: ${this.selection.selected.join(', ')}`);
    }
    this.progressing = false;
  }

  private _buildBodyReq(type: RESOURCE_CONNECT, teamUuid?: string): ShareResourceReq {
    return {
      recipientOrgUuid: this.data.recipientOrgUuid,
      ...(teamUuid && { teamUuid: teamUuid }),
      ...(type === RESOURCE_CONNECT.channel && {
        originatorOrgUuid: this.identityProfileQuery.currentOrg.orgUuid,
        resources: this.selection.selected.map(item => {
          return { type: API_TYPE_CONNECT.Ticket_Channel, id: item.uuid };
        })
      })
    };
  }
}
