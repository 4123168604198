import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, HashMap, StoreConfig } from '@datorama/akita';
import { ChatMessage, MessageBody } from '../chat-message/chat-message.model';
import { MESSAGE_STORE_NAME } from './history-message.model';

export interface HistoryMessageState extends EntityState<ChatMessage>, ActiveState {
  bookmarkExpandMap: HashMap<ChatMessage>; // messageId => msg expaned
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: MESSAGE_STORE_NAME, idKey: 'clientId' })
export class HistoryMessageStore extends EntityStore<HistoryMessageState> {
  constructor() {
    super({
      bookmarkExpandMap: {}
    });
  }

  override akitaPreUpdateEntity(_: Readonly<ChatMessage>, nextEntity: ChatMessage) {
    nextEntity.body = new MessageBody({
      ..._.body,
      ...nextEntity.body
    });
    return nextEntity;
  }
}
