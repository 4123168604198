<div class="h-6">
  <mat-icon
    matTooltip="{{ tooltipCopied ? 'Copied' : 'Copy' }}"
    [matTooltipHideDelay]="tooltipCopied ? timeDelayHide : 0"
    [matTooltipPosition]="position"
    class="cursor-pointer !text-[24px] !text-[#575A5C]"
    (click)="$event.stopPropagation()"
    (mouseenter)="isHovered = true; tooltipCopied = false"
    (mouseleave)="isHovered = false"
    [cdkCopyToClipboard]="textCopy"
    (cdkCopyToClipboardCopied)="copied(tooltip)"
    #tooltip="matTooltip"
    >content_copy
  </mat-icon>
</div>
