<mat-form-field
  [class]="'search-field ' + classSearchFormFiled"
  class="!max-w-[400px]"
  [ngClass]="advanceSearchMenuTrigger.menuOpen ? [classPopupSearchAdvance] : [classSearchFormFiled]"
  [@expandWidth]="isExpanded ? 'expanded' : 'collapsed'"
>
  <mat-icon class="s-20 !p-2 text-[#1F212399]" matPrefix>search</mat-icon>
  <ng-container *ngIf="isAdvanceSearch; else inputData">
    <mat-chip-set class="overflow-hidden !h-6 !min-h-6">
      <ng-container *ngFor="let item of listChipHasValue">
        <mat-chip
          *ngIf="
            (item.type !== typeField.multiSelect && item.value) ||
            (item.type === typeField.multiSelect && item.value?.length)
          "
          (click)="openMenuAdvanceSeach(advanceSearchMenuTrigger)"
          [matTooltip]="item.type === typeField.multiSelect ? getListValueMultiSelect(item) : ''"
          class="!h-6 !min-h-6 !m-0 !ml-2"
        >
          <ng-container [ngSwitch]="item.type">
            <ng-container *ngSwitchCase="typeField.checkBox">{{ item.info }}</ng-container>
            <ng-container *ngSwitchCase="typeField.singleSelect">{{
              item.info + ' = ' + item.value?.value
            }}</ng-container>
            <ng-container *ngSwitchCase="typeField.multiSelect">{{
              item.info +
                ' = ' +
                (item.value?.length > 1
                  ? item.value[0].value +
                    ' (+' +
                    (item.value?.length - 1) +
                    (item.value?.length === 2 ? ' other' : ' others') +
                    ')'
                  : item.value[0].value)
            }}</ng-container>
            <ng-container *ngSwitchDefault>{{
              item.info + ' = ' + (item.isUUID ? (item.value | slice: 0 : 8) : item.value)
            }}</ng-container>
          </ng-container>
        </mat-chip>
        <ng-container *ngIf="!item.value && item.groupCheckbox?.length">
          <ng-container *ngFor="let checkbox of item.groupCheckbox">
            <mat-chip
              *ngIf="checkbox.value"
              (click)="openMenuAdvanceSeach(advanceSearchMenuTrigger)"
              class="!h-6 !min-h-6 !m-0 !ml-2"
              >{{ checkbox.info }}</mat-chip
            >
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-chip-set>
    <input matInput [formControl]="searchCtrl" class="!hidden" />
  </ng-container>
  <ng-template #inputData>
    <input
      matInput
      #inputKeyword
      [formControl]="searchCtrl"
      placeholder="Search"
      (click)="isAdvanceSearch && openMenuAdvanceSeach(advanceSearchMenuTrigger)"
      (keydown.enter)="$event.preventDefault()"
    />
  </ng-template>
  <button mat-icon-button matSuffix *ngIf="searchCtrl.value || isAdvanceSearch" (click)="clearInput()">
    <mat-icon class="s-20 text-[#575A5C]">close</mat-icon>
  </button>
  <button
    mat-icon-button
    matSuffix
    #advanceSearchMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="isExpanded ? advanceSearchMenu : null"
    [matMenuTriggerRestoreFocus]="true"
    (click)="$event.stopPropagation(); $event.preventDefault(); openMenuAdvanceSeach(advanceSearchMenuTrigger)"
    matTooltip="Advance search"
  >
    <mat-icon class="s-20 text-[#575A5C]">tune</mat-icon>
  </button>
</mat-form-field>
<mat-menu
  #advanceSearchMenu="matMenu"
  [class]="'advanceSearchMenu ' + classPopupSearchAdvance"
  xPosition="before"
  (closed)="isExpanded = false"
>
  <div (click)="$event.stopPropagation()" class="flex flex-col gap-4 p-4">
    <ng-container *ngFor="let item of filterResource">
      <div *ngIf="!item.isInvisibility && item.type === typeField.input" class="flex justify-between items-center">
        <span [class]="item.isDisable ? 'text-[#1F212366]' : 'text-[#1F212399]'">{{ item.info }}</span>
        <mat-form-field class="input-search-advance no-validator w-full">
          <input
            matInput
            #inputSearch
            [value]="item.value"
            [disabled]="item.isDisable"
            [(ngModel)]="item.value"
            (ngModelChange)="onChangeItemSearchAdvance(item)"
          />
          <button
            mat-icon-button
            matSuffix
            *ngIf="item.value"
            (click)="item.value = ''; onChangeItemSearchAdvance(item)"
          >
            <mat-icon class="s-20 text-[#1F212399]">close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div
        *ngIf="!item.isInvisibility && item.type === typeField.singleSelect"
        class="flex justify-between items-center"
      >
        <span [class]="item.isDisable ? 'text-[#1F212366]' : 'text-[#1F212399]'">{{ item.info }}</span>
        <mat-form-field class="input-search-advance selection no-validator">
          <mat-select
            [(ngModel)]="item.value"
            #singleSelect
            [disabled]="item.isDisable"
            (ngModelChange)="onChangeItemSearchAdvance(item)"
            (keydown.enter)="$event.preventDefault(); singleSelect.close()"
          >
            <mat-select-trigger>
              <div class="flex justify-between items-center w-full">
                <span>{{ item.value?.value }}</span
                ><button
                  mat-icon-button
                  matSuffix
                  *ngIf="item.value"
                  (click)="item.value = ''; onChangeItemSearchAdvance(item)"
                >
                  <mat-icon class="s-20 text-[#1F212399]">close</mat-icon>
                </button>
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let i of item.data" [value]="i">
              {{ i.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="!item.isInvisibility && item.type === typeField.multiSelect"
        class="flex justify-between items-center"
      >
        <span [class]="item.isDisable ? 'text-[#1F212366]' : 'text-[#1F212399]'">{{ item.info }}</span>
        <mat-form-field class="input-search-advance selection no-validator">
          <mat-select
            [(ngModel)]="item.value"
            #multiSelect
            [disabled]="item.isDisable"
            (ngModelChange)="onChangeItemSearchAdvance(item)"
            (keydown.enter)="$event.preventDefault(); multiSelect.close()"
            multiple
          >
            <mat-select-trigger>
              <div class="flex justify-between items-center w-full">
                <div>
                  {{ item.value?.[0]?.value || '' }}
                  <span *ngIf="(item.value?.length || 0) > 1">
                    (+{{ (item.value?.length || 0) - 1 }} {{ item.value?.length === 2 ? 'other' : 'others' }})
                  </span>
                </div>
                <button
                  mat-icon-button
                  matSuffix
                  *ngIf="item.value"
                  (click)="item.value = ''; onChangeItemSearchAdvance(item)"
                >
                  <mat-icon class="s-20 text-[#1F212399]">close</mat-icon>
                </button>
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let i of item.data" [value]="i">{{ i.value }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!item.isInvisibility && item.type === typeField.checkBox" class="flex items-center gap-4 flex-wrap">
        <ng-container *ngFor="let checkbox of item.groupCheckbox">
          <mat-checkbox
            *ngIf="!checkbox.isInvisibility"
            [(ngModel)]="checkbox.value"
            class="resource-checkbox"
            [disabled]="item.isDisable"
            (ngModelChange)="onChangeItemSearchAdvance(checkbox)"
          >
            <span [class]="item.isDisable ? 'text-[#1F212366]' : 'text-[#1F2123]'">{{ checkbox.info }}</span>
          </mat-checkbox>
        </ng-container>
      </div>
    </ng-container>
    <div class="flex justify-end">
      <button
        mat-flat-button
        color="primary"
        [disabled]="!enableSeachAdvance"
        (click)="advanceSearch(advanceSearchMenuTrigger)"
      >
        Search
      </button>
    </div>
  </div>
</mat-menu>
