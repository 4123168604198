import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'shc-copy-icon',
  templateUrl: './copy-icon.component.html',
  styleUrls: ['./copy-icon.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTooltipModule, ClipboardModule, MatIconModule]
})
export class CopyIconComponent {
  @Input() textCopy: string;
  @Input() position: TooltipPosition = 'below';

  readonly timeDelayHide = 1000;

  tooltipCopied: boolean;
  isHovered: boolean;

  constructor() {}

  copied(matTooltipCopy: MatTooltip) {
    this.tooltipCopied = true;
    matTooltipCopy.show();
    setTimeout(() => {
      this.isHovered && matTooltipCopy.hide();
    }, 0);
  }
}
