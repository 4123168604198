import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { debounceTime, takeUntil } from 'rxjs';
import { DestroySubscriberComponent } from '../destroy-subscriber.component';
import { DataSearchString, FieldType, FilterItemSearch } from './single-search-bar.model';

/**
 * How to use:

 *  Container:
      <shc-single-search-bar
        [classSearchFormFiled]="'w-[350px]'"                          // Set class search form field (note: min-width = 250px)
        [classPopupSearchAdvance]="'w-[400px]'"                       // Set class mat-menu (popup) advance search (note: max-width = 400px)
        [filterResource]="filterItemResource"                         // Data resource filter
        (onSearchSingleBar)="onSearchSingleBar($event)"               // Output() handle action button 'Search'
        (onChangeSingleSearchBar)="onChangeSingleSearchBar($event)"   // Output() handle change each items filter
        (onSearchString)="onSearchString($event)"                     // Output() handle search string of search form field
      ></shc-single-search-bar>
 *
 *  All behaviour of Output() will be handle at parent component, where use this lib component.
 */

const delayAnimation = 200;

@Component({
  selector: 'shc-single-search-bar',
  templateUrl: './single-search-bar.component.html',
  styleUrls: ['./single-search-bar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    MatChipsModule,
    FormsModule
  ],
  animations: [
    trigger('expandWidth', [
      state('collapsed', style({ width: '350px' })), // initial width
      state('expanded', style({ width: '400px' })), // expanded width
      transition('collapsed <=> expanded', [animate(`${delayAnimation}ms ease-in-out`)])
    ])
  ]
})
export class SingleSearchBarComponent extends DestroySubscriberComponent implements OnChanges, OnInit {
  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;
  @ViewChild('advanceSearchMenuTrigger', { static: false }) advanceSearchMenu: MatMenuTrigger;

  @Input() filterResource: FilterItemSearch[] = [];
  @Input() resetSearchString: boolean = false;
  @Input() classSearchFormFiled: string = '';
  @Input() classPopupSearchAdvance: string = '!w-[400px]';
  @Output() onSearchSingleBar: EventEmitter<FilterItemSearch[]> = new EventEmitter();
  @Output() onSearchString: EventEmitter<DataSearchString> = new EventEmitter();
  @Output() onChangeSingleSearchBar: EventEmitter<FilterItemSearch> = new EventEmitter();

  typeField = FieldType;
  searchCtrl = new FormControl();
  callRecording: boolean;
  voicemail: boolean;
  isAdvanceSearch: boolean;
  enableSeachAdvance: boolean;
  listChipHasValue: FilterItemSearch[] = [];

  isExpanded = false;

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (this.enableSeachAdvance && this.advanceSearchMenu.menuOpen) {
      this.advanceSearch(this.advanceSearchMenu);
    }
  }

  constructor() {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resetSearchString']) {
      this.clearInput();
    }
    this.filterResource.sort((a, b) => a.order - b.order);
  }

  ngOnInit(): void {
    this.searchCtrl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroySubscriber$)).subscribe(value => {
      !this.isAdvanceSearch && this.searchTypeAutoDetect(value);
    });
  }

  openMenuAdvanceSeach(advanceSearchMenuTrigger: MatMenuTrigger) {
    if (this.searchCtrl.value) {
      this.enableSeachAdvance = true;
      this.searchTypeAutoDetect(this.searchCtrl.value, false);
    }

    if (this.listChipHasValue?.length) {
      this.filterResource = structuredClone(this.listChipHasValue);
    }
    this.isExpanded = !this.isExpanded;
    setTimeout(() => {
      this.isExpanded && advanceSearchMenuTrigger.openMenu();
    }, delayAnimation);
  }

  clearInput() {
    this.isAdvanceSearch = false;
    this.enableSeachAdvance = false;
    this.listChipHasValue = [];
    this.filterResource.forEach(item => {
      item.value = '';
      if (item.groupCheckbox?.length) {
        item.groupCheckbox.forEach(c => (c.value = false));
      }
      this.onChangeItemSearchAdvance(item);
    });
    this.searchCtrl.setValue('');
  }

  onChangeItemSearchAdvance(data: FilterItemSearch) {
    const dataGroupCheckbox = this.filterResource?.find(item => item.groupCheckbox?.length);

    this.enableSeachAdvance =
      this.filterResource?.some(item => item.value) || dataGroupCheckbox?.groupCheckbox?.some(c => c.value);

    if (data.type === FieldType.checkBox && data.onlyOne) {
      this.filterResource.forEach(item => {
        if (item.groupCheckbox?.length) {
          item.groupCheckbox.forEach(checkbox => {
            if (data.key !== checkbox.key) {
              checkbox.value = false;
            }
          });
        }
      });
    }

    this.onChangeSingleSearchBar.emit(data);
  }

  advanceSearch(advanceSearchMenuTrigger: MatMenuTrigger) {
    const dataGroupCheckbox = this.filterResource?.find(item => item.groupCheckbox?.length);

    this.isAdvanceSearch =
      this.filterResource?.some(item => item.value) || dataGroupCheckbox?.groupCheckbox?.some(c => c.value);
    this.searchCtrl.setValue('');
    this.isAdvanceSearch && this.onSearchSingleBar.emit(this.filterResource);
    this.listChipHasValue = structuredClone(this.filterResource);
    setTimeout(() => {
      advanceSearchMenuTrigger.closeMenu();
    });
  }

  searchTypeAutoDetect(keyword: string, reload: boolean = true): void {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    this.onSearchString.emit({ keyword: keyword, isUUID: uuidRegex.test(keyword), reload: reload });
  }

  getListValueMultiSelect(dataFilter: FilterItemSearch) {
    return dataFilter.value.map(i => i.value).join(', ');
  }
}
