import { Pipe, PipeTransform } from '@angular/core';
import { addSeconds, intervalToDuration } from 'date-fns';

@Pipe({
  name: 'durationV4',
  pure: true,
  standalone: true
})
export class DurationV4Pipe implements PipeTransform {
  transform(duration: number, unit: string = 'second', format: string = 'minute') {
    if (!duration) duration = 0;
    let durationInSec;

    switch (unit) {
      case 'millisecond':
        durationInSec = Math.round(duration / 1000);
        break;
      case 'minute':
        durationInSec = duration * 60;
        break;
      default:
        durationInSec = Math.round(duration);
    }

    const dateDuration = intervalToDuration({
      start: new Date(),
      end: addSeconds(new Date(), durationInSec)
    });
    const formatNumber = (number: number) => (number < 10 ? `0${number}` : number);
    switch (format) {
      case 'hour':
        return `${formatNumber(dateDuration.hours)}:${formatNumber(dateDuration.minutes)}:${formatNumber(dateDuration.seconds)}`;
      case 'minute':
        return `${formatNumber(dateDuration.minutes)}:${formatNumber(dateDuration.seconds)}`;
      default:
        return `${formatNumber(durationInSec)}`;
    }
  }
}
