export interface FilterItemSearch {
  key: string;
  value: any;
  info?: string;
  type?: FieldType;
  order?: number;
  data?: ResourceItem[];
  isInvisibility?: boolean;
  isDisable?: boolean;
  isUUID?: boolean;
  onlyOne?: boolean;
  groupCheckbox?: FilterItemSearch[];
}

export interface ResourceItem {
  key: string;
  value: string;
  selected?: boolean;
}

export interface DataSearchString {
  keyword: string;
  isUUID: boolean;
  reload: boolean;
}

export enum FieldType {
  input = 'input',
  singleSelect = 'singleSelect',
  multiSelect = 'multiSelect',
  checkBox = 'checkBox'
}
