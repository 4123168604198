<h1 mat-dialog-title>{{ data.type === 'Create' ? 'Create connection' : 'Invite organization' }}</h1>
<form mat-dialog-content [formGroup]="form" class="invite__wrapper" autocomplete="off">
  <mat-form-field [ngClass]="orgUuid.invalid ? '' : 'no-validator'">
    <mat-label>Org UUID</mat-label>
    <input matInput formControlName="orgUuid" placeholder="Enter the Organization UUID" />
    <mat-error *ngIf="orgUuid.invalid && (orgUuid.touched || orgUuid.dirty)">
      * Organization UUID is required
    </mat-error>
  </mat-form-field>
</form>

<div mat-dialog-actions align="end">
  <button mat-stroked-button type="button" (click)="dialogRef.close()">Cancel</button>
  <button mat-flat-button color="primary" type="button" (click)="invite()" [disabled]="form.invalid">Invite</button>
</div>
