export interface CallerIdDelegate {
  type: TypeCaller;
  key: string;
  label: string;
  callerId: string;
}

export enum TypeCaller {
  default = 'default',
  delegate = 'delegate',
  queue = 'queue',
  forward = 'forward'
}
