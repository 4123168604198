export enum AppNamePermission {
  sip = 'sip',
  autoAttendant = 'autoAttendant',
  call = 'call',
  fax = 'fax',
  byop = 'byop',
  byoc = 'byoc',
  hotDesking = 'hotDesking',
  leave = 'leave',
  consent = 'consent',
  language = 'language',
  simpleSbc = 'simpleSbc',
  iam = 'iam'
}

export enum AppNameActionPermission {
  viewFlow = 'viewFlow',
  editFlow = 'editFlow',
  deployFlow = 'deployFlow',
  grantAutoAttendantPermissions = 'grantAutoAttendantPermissions',

  // SipTrunk Permissions ----------------
  viewSip = 'viewSip',
  modifySip = 'modifySip',
  grantSipPermissions = 'grantSipPermissions',

  // Fax Permissions ----------------
  viewFax = 'viewFax',
  modifyFax = 'modifyFax',
  grantFaxPermissions = 'grantFaxPermissions',

  // BYOP Permissions ----------------
  viewByop = 'viewByop',
  modifyByop = 'modifyByop',
  grantByopPermissions = 'grantByopPermissions',

  // BYOC Permissions ----------------
  viewByoc = 'viewByoc',
  modifyByoc = 'modifyByoc',
  grantByocPermissions = 'grantByocPermissions',

  // hotDesking Permissions ----------------
  viewHotdesking = 'viewHotdesking',
  modifyHotdesking = 'modifyHotdesking',
  grantHotdeskingPermissions = 'grantHotdeskingPermissions',

  // leave Permissions ----------------
  manageLeave = 'manageLeave',

  // Consent Permission ------------
  viewConsent = 'viewConsent',
  modifyConsent = 'modifyConsent',

  // language Permissions ----------------
  modifyLanguages = 'modifyLanguages',
  viewLanguages = 'viewLanguages',

  // Phone System Permissions ----------------
  viewUserConfigurations = 'viewUserConfigurations',
  modifyUserConfigurations = 'modifyUserConfigurations',
  viewSystemConfigurations = 'viewSystemConfigurations',
  modifySystemConfigurations = 'modifySystemConfigurations',

  // simpleSbc Permissions ----------------
  manageSimpleSbc = 'manage',

  // iam Permissions ----------------
  modifyActions = 'modifyActions',
  modifyRoles = 'modifyRoles',
  viewActions = 'viewActions',
  viewRoles = 'viewRoles'
}
