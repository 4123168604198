import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MediasStore, MediaState } from './medias.store';

@Injectable({ providedIn: 'root' })
export class MediasQuery extends QueryEntity<MediaState> {
  constructor(protected override store: MediasStore) {
    super(store);
  }

  selectByConvo(convoId: string) {
    return this.selectAll({
      filterBy: entity => entity.sourceId === convoId
      // sortBy: '',
      // sortByOrder: Order.DESC
    });
  }
}
