<h1 mat-dialog-title>{{ 'Share ' + type | titlecase }}</h1>
<div mat-dialog-content>
  <mat-form-field class="w-full no-validator !mb-4">
    <mat-select
      [placeholder]="'Select ' + (type === 'DNC' ? type : (type | lowercase))"
      [(ngModel)]="resourceValue"
      [value]="resourceValue?.name"
      (selectionChange)="onSelected()"
      (opened)="type === 'Numbers' && scrollEvent()"
      #optionSelect
    >
      <mat-option *ngFor="let item of resourceActive; contries" [value]="item">
        {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="selection.selected.length === 0; else loadList">
    <mat-hint class="flex justify-center items-center !mb-2">{{
      'No selected ' + (type === 'DNC' ? type : (type | lowercase))
    }}</mat-hint>
  </ng-container>
  <ng-template #loadList>
    <h3 class="mb-2">{{ 'Selected ' + (type === 'DNC' ? type : (type | lowercase)) + ':' }}</h3>
    <div cdkDropList class="item_rows !mb-2" (cdkDropListDropped)="dropDispositionCodes($event)">
      <div class="sortable-box" *ngFor="let resource of selection.selected; let i = index" cdkDrag>
        <div class="item rounded-md flex !flex-row justify-between items-center !mx-2">
          <span>{{ i + 1 }}. {{ resource.name }}</span>
          <mat-icon
            (click)="deleteResource(resource)"
            color="warn"
            class="code-option__btn-delete material-icons-outlined"
            matTooltip="Delele"
          >
            delete
          </mat-icon>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button
    [loading]="progressing"
    [disabled]="selection.selected.length < 1"
    (click)="onSave()"
    mat-flat-button
    color="primary"
  >
    Save
  </button>
</div>
