import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from '@b3networks/api/common';
import { Contact } from '@b3networks/api/contact';
import { ConversationGroupQuery, ConversationGroupService, User } from '@b3networks/api/workspace';
import { ID, resetStores } from '@datorama/akita';
import { unionBy } from 'lodash';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { CreatedTxn } from '../created-txn/created-txn.model';
import { CreatedTxnsService } from '../created-txn/created-txn.service';
import { ResponseUpdateFile } from '../livechat/livechat.model';
import { RelatedTxnsService } from '../related-txn/related-txn.service';
import { CaseActivity } from '../support-center/activities/activities.model';
import { WatchersService } from '../watchers/watchers.service';
import { TxnUI } from './txn-ui.model';
import {
  ActionTxn,
  AssignLeftReq,
  CaseInfo,
  CreateCaseCommentReq,
  ReqSendMsgInbox,
  ReqTxnActiveByMeHasUnreadCount,
  RequestActionTxn,
  RequestFilterCustomerTxnsV2,
  RequestFilterTxnsV4,
  RequestFilterUniversalSearch,
  RequestUpdateTxns,
  RequestUploadFileTxn,
  RespActivePendingTxn,
  RespDetailTxn,
  ResponseTokenInbox,
  ResponseTxnActiveByMeHasUnreadCount,
  Txn,
  TxnChannel,
  TxnStatus,
  ViewMode
} from './txn.model';
import { TxnQuery } from './txn.query';
import { TxnStore } from './txn.store';

export const TXNS_SUPPORT = [TxnChannel.livechat, TxnChannel.whatsapp, TxnChannel.call, TxnChannel.supportCenter];
export const PENDING_TXNS = [TxnStatus.waiting];

@Injectable({
  providedIn: 'root'
})
export class TxnService {
  listNotifyAssign2Me: string[] = []; // txnUuid

  constructor(
    private http: HttpClient,
    private store: TxnStore,
    private query: TxnQuery,
    private watchersService: WatchersService,
    private relatedTxnsService: RelatedTxnsService,
    private createdTxnservice: CreatedTxnsService,
    private conversationGroupService: ConversationGroupService,
    private conversationGroupQuery: ConversationGroupQuery
  ) {}

  assign(req: AssignLeftReq) {
    return this.http.post<void>('callcenter/private/v1/chat/txn/assign', req);
  }

  archive(convoId: string) {
    return this.http.post<void>(`callcenter/private/v1/chat/txn/end`, { txnUuid: convoId });
  }

  left(req: AssignLeftReq) {
    return this.http.post<void>(`callcenter/private/v1/chat/txn/left`, req);
  }

  join(convoId: string) {
    return this.http.post<void>(`workspace/private/v1/chat/${convoId}/join`, null);
  }

  verifiedTxnsV2(txnUuid: string) {
    return this.http
      .put<Contact>(`inbox/private/v2/txn/${txnUuid}/markVerified`, {})
      .pipe(map(contact => new Contact(contact)));
  }

  endTxnsV2(txnUuids: string[]) {
    return this.http.post(`inbox/private/v2/livechat/_end`, { txnUuids: txnUuids });
  }

  endTxnsV3(txnUuid: string) {
    return this.http.put(`inbox/private/v2/txn/${txnUuid}/_end`, {});
  }

  reopenTxnsV3(txnUuid: string) {
    return this.http.put(`inbox/private/v2/txn/${txnUuid}/_reopen`, {});
  }

  joinTxnV2(txnUuid: string, agentUuid: string) {
    return this.http.post(`inbox/private/v2/txn/${txnUuid}/_assign`, { agentUuid: agentUuid });
  }

  leftTxnV2(txnUuid: string, agentUuid: string) {
    return this.http.post(`inbox/private/v2/txn/${txnUuid}/_unassign`, { agentUuid: agentUuid });
  }

  moveInbox(txnUuid: string, assignees: string[], destinationInbox: string) {
    return this.http.put(`inbox/private/v2/txn/${txnUuid}/_move`, {
      assignees,
      destinationInbox
    });
  }

  updateTxnV2(myOrgUuid: string, txnUuid: string, req: RequestUpdateTxns) {
    return this.http.put<Txn>(`inbox/private/v2/txn/${txnUuid}`, req).pipe(
      map(txn => this.convertTxn(txn, { isTemporary: true })),
      map(txn => new Txn(txn).withOrgUuid(myOrgUuid)),
      tap(txn => this.updateTxns2Store([txn]))
    );
  }

  acceptOrRejectTxn(txnUuid: string, action: ActionTxn, notificationId: number) {
    return this.http.post<Txn>(`inbox/private/v2/txn/${txnUuid}/${notificationId}/${action}`, {});
  }

  requestAssignTxn(txnUuid: string, req: RequestActionTxn) {
    return this.http.post<Txn>(`inbox/private/v2/txn/${txnUuid}/_requestAssign`, req);
  }

  getTxnByFilterV4(
    myOrgUuid: string,
    req: RequestFilterTxnsV4,
    pageable: Pageable,
    meIdentity?: string
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pageable).forEach(key => {
      if (pageable[key]) {
        params = params.append(key, String(pageable[key]));
      }
    });

    return this.http.post<Txn[]>('inbox/private/v5/txn/_query', req, { params: params }).pipe(
      map(res => {
        let txns: Txn[] = [];
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        txns = res.map(item => {
          const data = this.convertTxn(item, { isTemporary: true });
          if (
            req.viewMode === ViewMode.assigned &&
            (!data.lastAssignedAgents || data.lastAssignedAgents?.length === 0) &&
            !data.lastAssignedAgents?.includes(meIdentity)
          ) {
            data.lastAssignedAgents = [meIdentity];
          }
          if (data.channel === TxnChannel.supportCenter) delete data?.unreadPublicConvo;
          return new Txn(data).withOrgUuid(myOrgUuid);
        });

        if (req.viewMode === ViewMode.watcher) {
          txns?.forEach(txn => {
            this.watchersService.addWatcher(txn?.txnUuid, [meIdentity]);
          });
        }

        if (req.viewMode === ViewMode.mention) {
          txns?.forEach(item => {
            this.relatedTxnsService.addRelated(item.txnUuid, [meIdentity]);
          });
        }

        if (req.viewMode === ViewMode.createdByMe) {
          const createdByMe: CreatedTxn[] = txns?.map(txn => {
            return {
              txnUuid: txn.txnUuid,
              createdBy: meIdentity
            } as CreatedTxn;
          });

          this.createdTxnservice.updateCreatedTxns2Store(createdByMe);
        }

        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns))
    );
  }

  // has unread count and txn not ended
  getAllTxnActiveByMe(
    myOrgUuid: string,
    req: ReqTxnActiveByMeHasUnreadCount,
    pageable: Pageable,
    meIdentity?: string
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pageable).forEach(key => {
      if (pageable[key]) {
        params = params.append(key, String(pageable[key]));
      }
    });

    return this.http
      .post<ResponseTxnActiveByMeHasUnreadCount>('inbox/private/v5/txn/_me/all', req, { params: params })
      .pipe(
        map(res => {
          const contactInstance = (txn: Txn) =>
            txn?.['customer']
              ? new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
              : null;
          let contacts: Contact[] = [
            ...res.assigned.map(x => contactInstance(x)),
            ...res.createdByMe.map(x => contactInstance(x)),
            ...res.watcher.map(x => contactInstance(x)),
            ...res.mention.map(x => contactInstance(x))
          ].filter(x => !!x);
          contacts = unionBy(contacts, 'uuid');

          let txns: Txn[] = [];

          if (res.assigned?.length > 0) {
            txns = [
              ...txns,
              ...res.assigned.map(item => {
                const data = this.convertTxn(item, { isTemporary: true });
                if (
                  (!data.lastAssignedAgents || data.lastAssignedAgents?.length === 0) &&
                  !data.lastAssignedAgents?.includes(meIdentity)
                ) {
                  data.lastAssignedAgents = [meIdentity];
                }

                if (data.channel === TxnChannel.supportCenter) delete data?.unreadPublicConvo;

                return new Txn(data).withOrgUuid(myOrgUuid);
              })
            ];
          }

          if (res.createdByMe?.length > 0) {
            txns = [
              ...txns,
              ...res.createdByMe.map(item => {
                const data = this.convertTxn(item, { isTemporary: true });
                const createdByMe: CreatedTxn[] = txns?.map(txn => {
                  return {
                    txnUuid: txn.txnUuid,
                    createdBy: meIdentity
                  } as CreatedTxn;
                });
                this.createdTxnservice.updateCreatedTxns2Store(createdByMe);
                if (data.channel === TxnChannel.supportCenter) delete data?.unreadPublicConvo;
                return new Txn(data).withOrgUuid(myOrgUuid);
              })
            ];
          }

          if (res.watcher?.length > 0) {
            txns = [
              ...txns,
              ...res.watcher.map(item => {
                const data = this.convertTxn(item, { isTemporary: true });
                this.watchersService.addWatcher(item?.txnUuid, [meIdentity]);
                if (data.channel === TxnChannel.supportCenter) delete data?.unreadPublicConvo;
                return new Txn(data).withOrgUuid(myOrgUuid);
              })
            ];
          }

          if (res.mention?.length > 0) {
            txns = [
              ...txns,
              ...res.mention.map(item => {
                const data = this.convertTxn(item, { isTemporary: true });
                this.relatedTxnsService.addRelated(item.txnUuid, [meIdentity]);
                if (data.channel === TxnChannel.supportCenter) delete data?.unreadPublicConvo;
                return new Txn(data).withOrgUuid(myOrgUuid);
              })
            ];
          }

          return <RespActivePendingTxn>{ txns, contacts };
        }),
        tap(txnList => this.updateTxns2Store(txnList.txns))
      );
  }

  // has unread count
  getTxnFilterByMe(
    myOrgUuid: string,
    req: RequestFilterTxnsV4,
    pageable: Pageable,
    meIdentity?: string
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pageable).forEach(key => {
      if (pageable[key]) {
        params = params.append(key, String(pageable[key]));
      }
    });

    return this.http.post<Txn[]>('inbox/private/v5/txn/_me', req, { params: params }).pipe(
      map(res => {
        let txns: Txn[] = [];
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        txns = res.map(item => {
          const data = this.convertTxn(item, { isTemporary: true });
          if (
            req.viewMode === ViewMode.assigned &&
            (!data.lastAssignedAgents || data.lastAssignedAgents?.length === 0) &&
            !data.lastAssignedAgents?.includes(meIdentity)
          ) {
            data.lastAssignedAgents = [meIdentity];
          }
          if (data.channel === TxnChannel.supportCenter) delete data?.unreadPublicConvo;
          return new Txn(data).withOrgUuid(myOrgUuid);
        });

        if (req.viewMode === ViewMode.watcher) {
          txns?.forEach(item => {
            this.watchersService.addWatcher(item?.txnUuid, [meIdentity]);
          });
        }

        if (req.viewMode === ViewMode.mention) {
          txns?.forEach(item => {
            this.relatedTxnsService.addRelated(item.txnUuid, [meIdentity]);
          });
        }

        if (req.viewMode === ViewMode.createdByMe) {
          const createdByMe: CreatedTxn[] = txns?.map(txn => {
            return {
              txnUuid: txn.txnUuid,
              createdBy: meIdentity
            } as CreatedTxn;
          });

          this.createdTxnservice.updateCreatedTxns2Store(createdByMe);
        }

        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns))
    );
  }

  getDetailTxnV2(myOrgUuid: string, txnUuid: string): Observable<RespDetailTxn> {
    return this.http.get<Txn>(`inbox/private/v3/txn/${txnUuid}`).pipe(
      map(item => {
        const data = this.convertTxn(item, { isTemporary: true });
        return <RespDetailTxn>{
          txn: new Txn(data).withOrgUuid(myOrgUuid),
          contact: item?.['customerInfo'] ? new Contact(item['customerInfo']) : null
        };
      }),
      tap(data => {
        this.addTxns2Store(data.txn);
        this.updateTxnViewState(data.txn.txnUuid, {
          loadedDetail: true
        });
      })
    );
  }

  getTxnByCustomerV2(
    myOrgUuid: string,
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v3/txn/_getByCustomer', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });
          return new Txn(data).withOrgUuid(myOrgUuid);
        });
        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getAllTicketByViewMode(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v3/txn/_query/ticket', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });

          return new Txn(data);
        });

        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getTxnWatchedByCustomerV2(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v1/txn/_getWatchedByCustomer', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });

          return new Txn(data);
        });
        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getTxnRelatedByCustomerV2(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v1/txn/_getMentionedByCustomer', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });

          return new Txn(data);
        });
        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getTxnCreatedByMeForCustomerV2(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v1/txn/_getCreatedByMeForCustomer', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });

          return new Txn(data);
        });
        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getTxnByTeamConvo(orgUuid: string, internalConvo: string) {
    return this.http.get<Txn>(`inbox/private/v3/txn/internalConvo/${internalConvo}`).pipe(
      map(res => {
        const contact: Contact = res?.['customer']
          ? new Contact({
              uuid: res?.['customer']?.['uuid'],
              displayName: res?.['customer']?.['displayName'],
              chatCustomerId: res?.['customer']?.['chatUserId']
            })
          : null;

        const store = this.query.getEntity(res?.txnUuid);
        const data = this.convertTxn(res, { isTemporary: store?.isTemporary });
        return <RespDetailTxn>{ txn: new Txn(data).withOrgUuid(orgUuid), contact: contact };
      }),
      tap(data => this.addTxns2Store(data.txn))
    );
  }

  countPendingTxn(inboxUuids: string[]) {
    return this.http
      .post<{ [key: string]: string[] }>(`inbox/private/v5/txn/_query/pendingTxn`, { inboxes: inboxUuids })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  updateTxn2Store(txnUuid: string | ID, data: Partial<Txn>) {
    const txn = this.query.getEntity(txnUuid);
    if (TXNS_SUPPORT.includes(txn?.channel)) {
      this.store.upsert(txnUuid, data, { baseClass: Txn });
    }
  }

  updateTxns2Store(data: Partial<Txn[]>) {
    const list = data.filter(txn => TXNS_SUPPORT.includes(txn.channel));
    if (list.length > 0) {
      const txnStored = list.filter(x => this.query.hasEntity(x.txnUuid));
      const txnUnStored = list.filter(x => !this.query.hasEntity(x.txnUuid));

      txnStored.length > 0 && this.store.upsertMany(list, { baseClass: Txn });
      if (txnUnStored.length > 0) {
        this.store.upsertMany(list, { baseClass: Txn });
      }
    }
  }

  addTxns2Store(txn: Txn) {
    if (TXNS_SUPPORT.includes(txn?.channel)) {
      this.store.upsert(txn.txnUuid, txn, { baseClass: Txn });
      return true;
    }
    return false;
  }

  updateTxnViewState(txnUuid: string | string[], state: Partial<TxnUI>) {
    this.store.ui.update(txnUuid, entity => ({
      ...entity,
      ...state
    }));
  }

  markSeenAll(txnUuid: string) {
    this.store.update(txnUuid, { unreadPublicConvo: 0, unreadTeamConvo: 0 });
  }

  markSeenPublic(txnUuid: string) {
    this.store.update(txnUuid, { unreadPublicConvo: 0 });
  }

  markSeenInternal(txnUuid: string) {
    this.store.update(txnUuid, { unreadTeamConvo: 0 });
  }

  countUnreadPublic(txnUuid: string) {
    const txn = this.query.getEntity(txnUuid);
    if (txn) {
      this.updateTxn2Store(txnUuid, {
        unreadPublicConvo: (txn.unreadPublicConvo || 0) + 1
      });
    }
  }

  countUnreadInternal(txnUuid: string) {
    const txn = this.query.getEntity(txnUuid);
    if (txn) {
      this.updateTxn2Store(txnUuid, {
        unreadTeamConvo: (txn.unreadTeamConvo || 0) + 1
      });
    }
  }

  resetStoreTxn() {
    resetStores();
    this.store.ui.reset();
  }

  removeWithoutActive() {
    const activeId = this.query.getActiveId();
    this.store.remove(entity => entity.txnUuid !== activeId);
  }

  setActive(channelId: string | ID) {
    this.store.setActive(channelId);
  }

  removeActive(channelId: string | ID) {
    this.store.removeActive(channelId);
  }

  createComment(txnUuid: string, comment: CreateCaseCommentReq) {
    return this.http.post<CaseActivity>(`inbox/private/v1/txn/${txnUuid}/comments`, comment).pipe();
  }

  updateComment(txnUuid: string, commentId: number, comment: CreateCaseCommentReq) {
    return this.http.put<CaseActivity>(`inbox/private/v1/txn/${txnUuid}/comments/${commentId}`, comment);
  }

  deleteComment(txnUuid: string, commentId: number) {
    return this.http.delete<void>(`inbox/private/v1/txn/${txnUuid}/comments/${commentId}`);
  }

  uploadFile(txnUuid: string, req: RequestUploadFileTxn) {
    return this.http.post<ResponseUpdateFile>(`inbox/private/v2/txn/${txnUuid}/files`, req);
  }

  uploadFileAsync(txnUuid: string, req: RequestUploadFileTxn) {
    return this.http.post<ResponseUpdateFile>(`inbox/private/v2/txn/${txnUuid}/files/async`, req);
  }

  statusUploadAsync(txnUuid: string, asyncId: number) {
    return this.http.get<any>(`inbox/private/v2/txn/${txnUuid}/files/async/${asyncId}`);
  }

  getTraceByTxn(txnUuid: string, txnTrace: string) {
    return this.http.get<any>(`inbox/private/v2/txn/${txnUuid}/trace?txnTrace=${txnTrace}`);
  }

  moveInboxUpdateState(txnUuid: string, fromInbox: string, toInbox: string) {
    const txn = this.query.getEntity(txnUuid);
    if (txn) {
      this.store.update(txnUuid, {
        inboxUuid: toInbox
      });
    }
  }

  migrateContactWhenMerged(toContact: string, mergedContacts: string[]) {
    const txns = this.query.getAll({
      filterBy: entity => mergedContacts?.includes(entity.customerUuid)
    });

    this.store.update(
      txns?.map(x => x.txnUuid),
      {
        customerUuid: toContact
      }
    );
  }

  sendMsgInbox(txnUuid: string, req: ReqSendMsgInbox) {
    return this.http.post(`inbox/private/v2/txn/${txnUuid}/messages`, req);
  }

  getTokenInbox(txnUuid: string, fileName: string) {
    return this.http.post<ResponseTokenInbox>(`inbox/private/v2/txn/${txnUuid}/files/_getToken`, { fileName });
  }

  queryUniversalSerach(req: RequestFilterUniversalSearch, pageable: Pageable) {
    let params = new HttpParams();
    params = params.append('perPage', pageable.perPage);
    Object.keys(pageable).forEach(key => {
      if (pageable[key]) {
        params = params.append(key, String(pageable[key]));
      }
    });
    return this.http.post<Txn[]>(`inbox/private/v5/txn/universal/_search`, req, { params });
  }

  private convertTxn(item: Partial<Txn>, state: { isTemporary: boolean }) {
    const txn = <Partial<Txn>>{};

    if (item.txnUuid) txn.txnUuid = item.txnUuid;
    if (item.inboxUuid) txn.inboxUuid = item.inboxUuid;
    if (item.channelUuid) txn.channelUuid = item.channelUuid;

    if (item.teamConvoId) txn.teamConvoId = item.teamConvoId;
    if (item.publicConvoId) txn.publicConvoId = item.publicConvoId;

    if (item?.['assignees']) txn.lastAssignedAgents = item['assignees'];
    if (item.metadata) txn.metadata = item.metadata;
    if (item.createdAt) txn.createdAt = item.createdAt;
    if (item.channel) txn.channel = item.channel;
    if (item.status) txn.status = item.status;
    if (item.whatsapp) txn.whatsapp = item.whatsapp;
    if (item.typeId) txn.typeId = item.typeId;
    if (item.productIds) txn.productIds = item.productIds;
    if (item.severityId) txn.severityId = item.severityId;
    if (item.transcriptKey) txn.transcriptKey = item.transcriptKey;
    if (item.assigningTo) txn.assigningTo = item.assigningTo;
    if (item.affectedOrgName) txn.affectedOrgName = item.affectedOrgName;
    if (item.affectedOrgUuid) txn.affectedOrgUuid = item.affectedOrgUuid;
    if (item.unreadPublicConvo) txn.unreadPublicConvo = item.unreadPublicConvo;
    if (item.unreadTeamConvo) txn.unreadTeamConvo = item.unreadTeamConvo;
    if (item.sid) txn.sid = item.sid;
    if (item.workingHourMode) txn.workingHourMode = item.workingHourMode;
    if (item.latestMsgInMilisecond) txn.latestMsgInMilisecond = item.latestMsgInMilisecond;
    if (item.failReason) txn.failReason = item.failReason;
    txn.title = item?.title; // allow title is empty

    //support ticket
    if (item.caseInfo) {
      txn.caseInfo = this.convertCaseInfo(item.caseInfo);
    } else {
      const caseInfoBase = <CaseInfo>{ ...this.query.getEntity(txn.txnUuid)?.caseInfo };
      if (item['ownerOrgUuid']) caseInfoBase.ownerOrgUuid = item['ownerOrgUuid'];
      if (item['ownerOrgName']) caseInfoBase.ownerOrgName = item['ownerOrgName'];
      if (item['createdByIdentity']) caseInfoBase.createdByIdentity = item['createdByIdentity'];
      if (item['createdByIdentityName']) caseInfoBase.createdByIdentityName = item['createdByIdentityName'];
      if (item['createdByOrgName']) caseInfoBase.createdByOrgName = item['createdByOrgName'];
      if (item['createdByOrg']) caseInfoBase.createdByOrg = item['createdByOrg'];
      if (item['sid']) caseInfoBase.sid = item['sid'];
      if (item['severityId']) caseInfoBase.severityId = item['severityId'];
      if (item['typeId']) caseInfoBase.typeId = item['typeId'];

      txn.caseInfo = this.convertCaseInfo(caseInfoBase);
    }
    if (item?.['ownerAssignees']?.length > 0) {
      txn.lastOwnerAssignedAgents = new User(item['ownerAssignees'][0]);
    } else {
      const infoOwnerAssignedBase = new User(<User>{ ...this.query.getEntity(txn.txnUuid)?.lastOwnerAssignedAgents });
      txn.lastOwnerAssignedAgents = infoOwnerAssignedBase;
    }

    if (item.endedAt) txn.endedAt = item.endedAt;

    // customer
    if (item?.['customer']) {
      if (item['customer']?.uuid) txn.customerUuid = item['customer']?.uuid;
      if (item['customer']?.displayName) txn.customerName = item['customer']?.displayName;
      if (item['customer']?.chatUserId) txn.customerChatUserId = item['customer']?.chatUserId;
    }

    // customer Info
    if (item?.['customerInfo']) {
      if (item['customerInfo']?.uuid) txn.customerUuid = item['customerInfo']?.uuid;
      if (item['customerInfo']?.displayName) txn.customerName = item['customerInfo']?.displayName;
      if (item['customerInfo']?.chatCustomerId) txn.customerChatUserId = item['customerInfo']?.chatCustomerId;
    }

    txn.isTemporary = state?.isTemporary;
    return txn;
  }

  private convertCaseInfo(item: CaseInfo) {
    const caseInfo = <CaseInfo>{};
    if (item.id) caseInfo.id = item.id;
    if (item.sid) caseInfo.sid = item.sid;
    if (item.txnUuid) caseInfo.txnUuid = item.txnUuid;
    if (item.description) caseInfo.description = item.description;

    if (item.ownerOrgUuid) caseInfo.ownerOrgUuid = item.ownerOrgUuid;
    if (item.ownerOrgName) caseInfo.ownerOrgName = item.ownerOrgName;

    if (item.srcOrgUuid) caseInfo.srcOrgUuid = item.srcOrgUuid;
    if (item.srcDomain) caseInfo.srcDomain = item.srcDomain;

    if (item.createdByIdentity) caseInfo.createdByIdentity = item.createdByIdentity;
    if (item.createdByIdentityName) caseInfo.createdByIdentityName = item.createdByIdentityName;

    if (item.createdByOrg) caseInfo.createdByOrg = item.createdByOrg;
    if (item.createdByOrgName) caseInfo.createdByOrgName = item.createdByOrgName;

    if (item.accessControlId) caseInfo.accessControlId = item.accessControlId;
    if (item.dueAt) caseInfo.dueAt = item.dueAt;

    if (item.watchers) caseInfo.watchers = item.watchers;
    if (item.activities) caseInfo.activities = item.activities;
    if (item.relatedCases) caseInfo.relatedCases = item.relatedCases;
    if (item.severityId) caseInfo.severityId = item.severityId;
    if (item.typeId) caseInfo.typeId = item.typeId;

    caseInfo.newDescription = null;
    return caseInfo;
  }
}
