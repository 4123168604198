import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { ActionByApp, IAMV2, IAMV2Resource } from './iam-v2.modal';

export interface IAMV2State extends EntityState<IAMV2> {
  resourceMap: Map<string, IAMV2Resource[]>;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'iamV2',
  idKey: 'serviceName'
})
export class IAMV2Store extends EntityStore<IAMV2State> {
  constructor() {
    super({ resourcesMap: new Map() });
  }

  override akitaPreAddEntity(newEntity: IAMV2): IAMV2 & { serviceName: string } {
    return {
      ...newEntity,
      serviceName: newEntity.service.name
    };
  }
}
