export enum ChannelType {
  gc = 'GC',
  dm = 'DM',
  PERSONAL = 'PERSONAL',
  THREAD = 'THREAD',
  NEWSFEED = 'NEWSFEED',
  KB = 'KB'
}

export function MappingChannelType(channelType: ChannelType) {
  const ct = channelType?.toUpperCase() as ChannelType;
  if (['GROUP'].includes(ct)) {
    return ChannelType.gc;
  } else if (['DIRECT'].includes(ct)) {
    return ChannelType.dm;
  }
  return ct;
}

export enum NameChannelPersonal {
  BOOKMARKS = 'BOOKMARKS'
}
