import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PasswordPolicy, RealDomainService, UpdatePersonalRequestBuilder } from '@b3networks/api/auth';
import {
  DEFAULT_PASSWORD_POLICIES,
  PASSWORD_POLICIES,
  SessionService,
  setDefaultValidators,
  updateValidators
} from '@b3networks/portal/base/shared';
import { encrypt, isLocalhost } from '@b3networks/shared/common';
import { ModalService } from '../../../shared/modal/modal.service';

export class UpdatePersonalError {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  serverError: string;

  hasError() {
    return this.oldPassword || this.newPassword || this.confirmPassword || this.serverError;
  }
}

@Component({
  selector: 'b3n-account-password-modal-settings',
  template: `
    <div class=" page-header" mat-dialog-title>Change password</div>
    <form [formGroup]="changePasswordForm" mat-dialog-content>
      <div class="row">
        <mat-form-field>
          <mat-label>Old password</mat-label>
          <input
            #oldPasswordInput
            readonly
            matInput
            type="password"
            placeholder="Old password"
            formControlName="oldPassword"
            (keyup.enter)="updatePersonal()"
            (keypress)="resetError()"
            (focus)="removeReadonlyAttr(oldPasswordInput)"
          />
          <mat-hint class="error-hint" *ngIf="error.oldPassword">{{ error.oldPassword }}</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>New password</mat-label>
          <input
            #newPasswordInput
            readonly
            matInput
            type="password"
            placeholder="New password"
            formControlName="newPassword"
            (keyup.enter)="updatePersonal()"
            (keypress)="resetError()"
            (focus)="removeReadonlyAttr(newPasswordInput)"
          />
        </mat-form-field>
        <div class="password-complexity">
          <div>Password must comply with the following requirements:</div>
          <div
            [class.touched]="newPasswordCtrl.touched || newPasswordCtrl.dirty"
            [ngClass]="{
              error: newPasswordCtrl.hasError('minlength') || newPasswordCtrl.hasError('required'),
              success: !newPasswordCtrl.hasError('minlength') && !newPasswordCtrl.hasError('required')
            }"
          >
            - At least {{ passwordPolicy?.minimumLength || 6 }} characters
          </div>
          <div
            *ngIf="passwordPolicy?.maximumLength"
            [class.touched]="newPasswordCtrl.touched || newPasswordCtrl.dirty"
            [ngClass]="{
              error: newPasswordCtrl.hasError('maxlength'),
              success: !newPasswordCtrl.hasError('maxlength')
            }"
          >
            - At most {{ passwordPolicy.maximumLength }} characters
          </div>
          <div *ngFor="let policy of policies">
            <span
              *ngIf="passwordPolicy?.[policy.name] || !passwordPolicy"
              [class.touched]="newPasswordCtrl.touched || newPasswordCtrl.dirty"
              [ngClass]="{
                error: newPasswordCtrl.hasError(policy.name),
                success: !newPasswordCtrl.hasError(policy.name)
              }"
              >{{ policy.displayText }}</span
            >
          </div>
        </div>

        <mat-form-field>
          <mat-label>Confirm new password</mat-label>
          <input
            #confirmPasswordInput
            readonly
            matInput
            type="password"
            placeholder="Confirm new password"
            formControlName="confirmNewPassword"
            (keyup.enter)="updatePersonal()"
            (keypress)="resetError()"
            (focus)="removeReadonlyAttr(confirmPasswordInput)"
          />
          <mat-hint class="error-hint" *ngIf="error.confirmPassword">{{ error.confirmPassword }}</mat-hint>
        </mat-form-field>
      </div>
      <div class="ui error message" *ngIf="error.serverError">{{ error.serverError }}</div>
    </form>

    <div mat-dialog-actions align="end">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        mat-raised-button
        [disabled]="changePasswordForm.invalid"
        [loading]="progressing"
        (click)="updatePersonal()"
        color="primary"
      >
        Save
      </button>
    </div>
  `,
  styleUrls: ['./modal.scss']
})
export class PasswordModalSettingsComponent {
  error = new UpdatePersonalError();
  progressing: boolean;
  changePasswordForm: FormGroup;
  policies = PASSWORD_POLICIES;

  get oldPasswordCtrl() {
    return this.changePasswordForm.get('oldPassword') as FormControl;
  }

  get newPasswordCtrl() {
    return this.changePasswordForm.get('newPassword') as FormControl;
  }

  get confirmNewPasswordCtrl() {
    return this.changePasswordForm.get('confirmNewPassword') as FormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public passwordPolicy: PasswordPolicy | null,
    private router: Router,
    private realDomainService: RealDomainService,
    private sessionService: SessionService,
    private modalService: ModalService,
    private dialogRef: MatDialogRef<PasswordModalSettingsComponent>,
    private fb: FormBuilder
  ) {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    });

    this.passwordPolicy
      ? updateValidators(this.newPasswordCtrl, this.passwordPolicy)
      : this.setDefaultPolicesAndValidators();
  }

  private setDefaultPolicesAndValidators() {
    this.policies = DEFAULT_PASSWORD_POLICIES;
    setDefaultValidators(this.newPasswordCtrl);
  }

  resetError() {
    this.error = new UpdatePersonalError();
  }

  updatePersonal() {
    if (this.validate()) {
      this.progressing = true;
      const updatePersonalRequest = new UpdatePersonalRequestBuilder().createUpdatePersonalRequestForPassword(
        this.oldPasswordCtrl.value,
        this.newPasswordCtrl.value
      );
      this.realDomainService.getRealDomainFromPortalDomain().subscribe(async realDomain => {
        if (realDomain.publicKey) {
          updatePersonalRequest.password = await encrypt(updatePersonalRequest.password, realDomain.publicKey);
          updatePersonalRequest.newPassword = await encrypt(updatePersonalRequest.newPassword, realDomain.publicKey);
        }
        this.sessionService.updatePersonalInfo(updatePersonalRequest).subscribe(
          _ => {
            this.dialogRef.close({ success: true });
            this.modalService.openSuccessModal(
              'Your password has been successfully updated. Please login with your new password'
            );
            setTimeout(() => {
              this.relogin();
            }, 3000);
          },
          error => {
            this.progressing = false;
            this.clearInput();
            const data = error?.error;
            if (data?.code === 'auth.AccessDenied' || error?.code === 'auth.AccessDenied') {
              this.error.oldPassword = 'Wrong old password';
            } else {
              this.error.serverError = data?.message || error?.message;
            }
          }
        );
      });
    }
  }

  removeReadonlyAttr(input: HTMLInputElement) {
    input.removeAttribute('readonly');
  }

  private validate() {
    this.error = new UpdatePersonalError();
    if (!this.oldPasswordCtrl.value) {
      this.error.oldPassword = 'Please enter your old password';
    }
    if (!this.newPasswordCtrl.value) {
      this.error.newPassword = 'Please enter your new password';
    } else if (this.newPasswordCtrl.value !== this.confirmNewPasswordCtrl.value) {
      this.error.confirmPassword = 'Your new password and confirm password do not match';
    }

    return !this.error.hasError();
  }

  private relogin() {
    this.sessionService.logout();
    this.router.navigate(
      ['auth'],
      isLocalhost()
        ? {
            queryParams: {
              redirectUrl: encodeURIComponent(location.href)
            }
          }
        : null
    );
  }

  private clearInput() {
    this.changePasswordForm.reset();
  }
}
