import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, take } from 'rxjs';
import { MeState, MeStore } from './me.store';

@Injectable({ providedIn: 'root' })
export class MeQuery extends Query<MeState> {
  me$ = this.select(state => state.me);
  meOne$ = this.select(state => state.me).pipe(
    filter(x => x != null),
    take(1)
  );

  constructor(protected override store: MeStore) {
    super(store);
  }

  getMe() {
    return this.getValue().me;
  }
}
