import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AllowedCallerIdsState, AllowedCallerIdsStore } from './allowed-caller-id.store';

@Injectable({ providedIn: 'root' })
export class AllowedCallerIdsQuery extends QueryEntity<AllowedCallerIdsState> {
  constructor(protected override store: AllowedCallerIdsStore) {
    super(store);
  }
}
