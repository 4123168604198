import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CHAT_PUBLIC_PREFIX, UNKNOWN_USER } from '@b3networks/shared/common';
import { HashMap, ID } from '@datorama/akita';
import { lastValueFrom, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { CallCenterAgent, RequestIntegration, StatusUserResponse, UserDirectory, UserDirectoryUI } from './user.model';
import { UserDirectoryQuery } from './user.query';
import { UserDirectoryState, UserDirectoryStore } from './user.store';

export type ChatUserType = 'chatId' | 'identity';

@Injectable({
  providedIn: 'root'
})
export class UserDirectoryService {
  private _mapFetchingUser: HashMap<boolean> = {};

  constructor(
    private http: HttpClient,
    private userStore: UserDirectoryStore,
    private userQuery: UserDirectoryQuery
  ) {}

  fetchUsers(orgUuid: string, lastUpdated: number = 0) {
    let lastUpdatedTime: number = lastUpdated;
    let limit = 100;
    const defaultLimit: number = limit;
    let hasMore = false;
    const tempUsers: UserDirectory[] = [];

    const handleFetchUsers = async () => {
      try {
        const users = (await lastValueFrom(this._queryUsers(orgUuid, lastUpdatedTime, limit))) || [];
        let minTime = -1;
        let maxTime = -1;
        users.forEach(user => {
          if (!tempUsers.some(x => x.chatUserId === user.chatUserId)) {
            tempUsers.push(user);
          }
          minTime = user.lastUpdated < minTime || minTime < 0 ? user.lastUpdated : minTime;
          maxTime = user.lastUpdated > maxTime ? user.lastUpdated : maxTime;
        });
        hasMore = users.length === limit;
        lastUpdatedTime = maxTime;
        if (hasMore) {
          if (minTime === maxTime && limit < 1000) {
            limit += 100;
          } else if (minTime === maxTime && limit >= 1000) {
            hasMore = false;
          } else limit = defaultLimit;
        }
        if (hasMore) handleFetchUsers();
        else {
          this.updateUsers2Store(tempUsers);
          this.userStore.update({
            loadedAllUser: true
          });
        }
      } catch (error) {
        console.log('fetchUsers: has error when fetching users', error);
      }
    };
    handleFetchUsers();
  }

  getDetailByIdentity(identityUuid: string) {
    return this.http.get<UserDirectory>(`directory/private/v2/members/${identityUuid}/_getDetail`).pipe(
      map(res => new UserDirectory(res)),
      tap(member => {
        this.updateUsers2Store([member]);
      })
    );
  }

  getDetailByChatUserId(chatUserId: string) {
    return this.http.get<UserDirectory>(`directory/private/v2/members/${chatUserId}/_getDetailFromChatUserId`).pipe(
      map(res => new UserDirectory({ ...res, isTemporary: false })),
      tap(member => {
        this.updateUsers2Store([member]);
      })
    );
  }

  findOneByChatUserIdSequentially(chatUserId: string) {
    if (!this._mapFetchingUser?.[chatUserId]) {
      this._mapFetchingUser[chatUserId] = true;
      return this.getDetailByChatUserId(chatUserId).pipe(
        catchError(err => {
          delete this._mapFetchingUser[chatUserId];
          const user = new UserDirectory({ name: UNKNOWN_USER, chatUserId });
          this.storeDeleteUser(user.memberUuid);
          return of(user);
        }),
        tap(() => {
          delete this._mapFetchingUser[chatUserId];
        })
      );
    } else {
      return this.userQuery.selectEntity(chatUserId)?.pipe(
        map(user => (user ? user : new UserDirectory({ name: UNKNOWN_USER, chatUserId }))),
        take(1)
      );
    }
  }

  fetchAllIntegration() {
    return this.http.get<RequestIntegration[]>('/workspace/private/v1/integrations').pipe(
      map(integrations => {
        return integrations?.map(i => UserDirectory.fromIntegrationJson(i));
      }),
      tap(users => {
        this.updateUsers2Store(users);
      })
    );
  }

  getAgents(orgUuid: string) {
    this.userStore.setLoading(true);
    return this.http.get<CallCenterAgent[]>(`workspace/private/v1/user/agents`).pipe(
      map(agents => {
        return agents.map(a => UserDirectory.fromAgentJson({ ...a, orgUuid: orgUuid }));
      }),
      tap(users => {
        this.updateUsers2Store(users);
      })
    );
  }

  initUsersStatus() {
    return this.http
      .post<{ Status: HashMap<StatusUserResponse> }>(`${CHAT_PUBLIC_PREFIX}/status/_all`, null)
      .pipe(map(x => x?.Status || <HashMap<StatusUserResponse>>{}));
  }

  updateStatusUserV2(map: HashMap<StatusUserResponse>) {
    const mapCurrent = { ...this.userQuery.getValue()?.userStatus };
    Object.keys(map)?.forEach(x => {
      mapCurrent[x] = map[x];
    });
    this.updateStateUser({
      userStatus: mapCurrent
    });
  }

  activeMember(uuid: string) {
    this.userStore.setActive(uuid);
  }

  removeActive(uuid: ID | string) {
    if (uuid) {
      this.userStore.removeActive(uuid);
    }
  }

  updateUsers2Store(users: UserDirectory[]) {
    this.userStore.upsertMany(users, { baseClass: UserDirectory });
  }

  updateUserViewState(uuid: string | string[], state: Partial<UserDirectoryUI>) {
    this.userStore.ui.update(uuid, entity => ({
      ...entity,
      ...state
    }));
  }

  updateStateUser(state: Partial<UserDirectoryState>) {
    this.userStore.update(state);
  }

  cleanUp() {
    this.updateStateUser({
      userStatus: {}
    });
  }

  storeDeleteUser(memberUuid: string) {
    this.userStore.update(state => {
      return {
        ...state,
        userDeleted: {
          ...state.userDeleted,
          [memberUuid]: true
        }
      };
    });
  }

  private _queryUsers(orgUuid: string, lastUpdated: number, limit: number) {
    return this.http
      .post<UserDirectory[]>(`directory/private/v3/members/_query`, {
        lastUpdated,
        limit
      })
      .pipe(
        map(resp =>
          resp?.map(
            x =>
              new UserDirectory({
                ...x,
                orgUuid: orgUuid,
                isTemporary: true
              })
          )
        )
      );
  }
}
