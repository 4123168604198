import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SessionKB } from './sessions.model';

export interface SessionsState extends EntityState<SessionKB>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'kbbot_sessions', idKey: 'convo' })
export class SessionsStore extends EntityStore<SessionsState> {
  constructor() {
    super();
  }
}
