import { Injectable } from '@angular/core';
import { ExtensionBase } from '@b3networks/api/bizphone';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CarrierConnectApp, SipAccount } from './sip-trunk.model';
import { ConnectItem } from '@b3networks/api/connect';
import { OrgConnection } from '@b3networks/api/auth';

export interface SipTrunkState extends EntityState<SipAccount>, ActiveState {
  availableCallerIds: string[];
  isdnCallerIds: string[];
  availableExt: ExtensionBase[];
  carrierConnectApp: CarrierConnectApp[];
  availableSips: SipAccount[];
  assignedSips: SipAccount[];
  listSip: SipAccount[];
  customParams: string[];
  listApp: ConnectItem[];
  listOrgConnector: OrgConnection[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'callcenter_sip-trunk', idKey: 'sipUsername' })
export class SipTrunkStore extends EntityStore<SipTrunkState> {
  constructor() {
    super({
      availableCallerIds: [],
      isdnCallerIds: [],
      availableExt: [],
      carrierConnectApp: [],
      availableSips: [],
      assignedSips: [],
      listSip: [],
      customParams: [],
      listApp: [],
      listOrgConnector: []
    });
  }
}
