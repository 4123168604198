import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { arrayAdd, arrayRemove, ID } from '@datorama/akita';
import { map, tap } from 'rxjs';
import {
  MemberNewsfeed,
  MemberNewsfeedUI,
  ParticipantNewsfeed,
  RequestUpdateManyMemberNF,
  ResponseUpdateManyMemberNF
} from './member.model';
import { MemberNewsfeedQuery } from './member.query';
import { MemberNewsfeedStore } from './member.store';

@Injectable({
  providedIn: 'root'
})
export class MemberNewsFeedService {
  constructor(
    private http: HttpClient,
    private store: MemberNewsfeedStore,
    private query: MemberNewsfeedQuery
  ) {}

  getMemberByFeedID(feedId: string) {
    return this.http.get<ParticipantNewsfeed[]>(`inbox/private/v1/newsfeed/${feedId}/members`).pipe(
      map(
        resp =>
          new MemberNewsfeed({
            newsfeedId: feedId,
            participant: resp
          })
      ),
      tap(data => {
        this.store.upsert(data.newsfeedId, data);
      })
    );
  }

  updateManyMemberByFeedID(feedId: string, req: RequestUpdateManyMemberNF) {
    return this.http.put<ResponseUpdateManyMemberNF[]>(`inbox/private/v1/newsfeed/${feedId}/members`, req);
  }

  addParticipant(feedId: string | ID, members: ParticipantNewsfeed[]) {
    const entity = this.query.getEntity(feedId);
    if (entity) {
      this.store.update(feedId, ({ participant }) => ({
        participant: arrayAdd(participant, members)
      }));
    } else {
      const mentions = <MemberNewsfeed>{
        feedId: feedId,
        participant: members
      };
      this.store.upsertMany([mentions]);
    }
  }

  removeParticipant(feedId: string | ID, members: ParticipantNewsfeed[]) {
    this.store.update(feedId, ({ participant }) => ({
      participant: arrayRemove(
        participant,
        members.map(x => x.id)
      )
    }));
  }

  updateViewState(channelId: string | string[], state: Partial<MemberNewsfeedUI>) {
    this.store.ui.update(channelId, entity => ({
      ...entity,
      ...state
    }));
  }
}
