import { Injectable } from '@angular/core';
import { ID, Order, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChatMessage } from '../chat-message/chat-message.model';
import { ConvoType, MsgType } from '../enums.model';
import { HistoryMessageState, HistoryMessageStore } from './history-message.store';

@Injectable({ providedIn: 'root' })
export class HistoryMessageQuery extends QueryEntity<HistoryMessageState> {
  isLoading$ = this.selectLoading();

  constructor(protected override store: HistoryMessageStore) {
    super(store);
  }

  getItem = (id: string | ID) => this.getEntity(id);
  selectItem = (id: string | ID) => this.selectEntity(id);
  selectPropertyItem = <K extends keyof ChatMessage>(id: string, property: K) => this.selectEntity(id, property);
  getActiveIdItem = () => this.getActiveId();
  selectActiveIdItem = () => this.selectActiveId();
  getActiveItem = () => this.getActive();
  selectActiveItem = () => this.selectActive();
  selectManyItem = (id: string[] | ID[]) => this.selectMany(id);
  getState = () => this.getValue();

  getMsg(clientId: string | ID) {
    return this.getEntity(clientId);
  }

  selectAllByConversation(convoUuid: string): Observable<ChatMessage[]> {
    return this.selectAll({
      filterBy: entity => entity.channelId === convoUuid,
      sortBy: 'ts',
      sortByOrder: Order.ASC
    });
  }

  selectAllByOrder(convoUuid: string, order: Order): Observable<ChatMessage[]> {
    return this.selectAll({
      filterBy: entity => entity.channelId === convoUuid && !entity.aclActions.isDeletedMessage,
      sortBy: 'ts',
      sortByOrder: order
    });
  }

  selectMsgByListConvo(convoUuids: string[]) {
    return this.selectAll({
      filterBy: entity => convoUuids.indexOf(entity.channelId) > -1,
      sortBy: 'ts',
      sortByOrder: Order.ASC
    });
  }

  selectActiveCallMessage(): Observable<ChatMessage> {
    return this.selectActive().pipe(filter(msg => msg != null && msg.ct === ConvoType.call));
  }

  getlastestMsgByUser(convoId: string, userUuid: string) {
    return this.getAll({
      filterBy: entity => entity.channelId === convoId && entity.user === userUuid && entity.mt === MsgType.message,
      sortBy: 'ts',
      sortByOrder: Order.DESC,
      limitTo: 1
    });
  }

  getlastestMsg(convoId: string) {
    return this.getAll({
      filterBy: entity => entity.channelId === convoId && entity.isStore && !entity?.metadata?.deletedAt,
      sortBy: 'ts',
      sortByOrder: Order.DESC,
      limitTo: 1
    });
  }

  getAllByConvoNotReconcilation(
    convo: string,
    params?: { to: number; from: number; limit: number; exclude: boolean }
  ): ChatMessage[] {
    let sortByOrder: Order;
    if (params.to && !params.from) {
      sortByOrder = Order.DESC;
    } else if (params.from && !params.to) {
      sortByOrder = Order.ASC;
    } else if (params.from && params.to) {
      sortByOrder = Order.ASC;
    }
    return this.getAll({
      filterBy: entity => {
        if (entity.channelId !== convo) return false;
        let includeTime = true;
        if (params.to && !params.from) {
          includeTime = params.exclude ? entity.ts < params.to : entity.ts <= params.to;
        } else if (params.from && !params.to) {
          includeTime = params.exclude ? entity.ts > params.from : entity.ts >= params.from;
        }
        return entity.channelId === convo && includeTime;
      },
      sortBy: 'ts',
      sortByOrder: sortByOrder,
      limitTo: params.from && params.to ? null : params.limit
    });
  }

  getMsgByRoot(convo: string, root: number, limit: number, isForward): ChatMessage[] {
    return this.getAll({
      filterBy: entity => {
        if (entity.channelId !== convo) return false;
        const includeTime = isForward ? entity.ts >= root : entity.ts <= root;
        return entity.channelId === convo && includeTime;
      },
      sortBy: 'ts',
      sortByOrder: isForward ? Order.ASC : Order.DESC,
      limitTo: limit
    });
  }

  selectManyMessages(messageIds: string[]) {
    return this.selectMany(messageIds);
  }
}
