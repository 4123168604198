import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { downloadData } from '@b3networks/shared/common';

@Component({
  selector: 'b3n-recovery-key',
  templateUrl: './recovery-key.component.html',
  styleUrls: ['./recovery-key.component.scss']
})
export class RecoveryKeyComponent implements OnInit {
  confirming: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public recoveryKey: string,
    public dialogRef: MatDialogRef<RecoveryKeyComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  download() {
    const file = new Blob([`RECOVERY KEY:\n\n${this.recoveryKey}`], { type: 'text/plain;charset=utf-8' });
    downloadData(file, 'recovery-key.txt');
    this.dialogRef.close(true);
  }

  openConfirmDialog() {
    this.confirming = true;
  }

  back() {
    this.confirming = false;
  }

  finish() {
    this.dialogRef.close();
  }
}
