import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { NewsfeedUI, SelectedTabNewsfeed } from './newsfeed-ui.model';
import { Newsfeed } from './newsfeed.model';

export interface NewsfeedState extends EntityState<Newsfeed>, ActiveState {
  loadedGetMyFeed: boolean;
  loadedGetDiscover: boolean;
}

export type NewsfeedUIState = EntityState<NewsfeedUI>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_newsfeed', idKey: 'channelId' })
export class NewsfeedStore extends EntityStore<NewsfeedState> {
  override ui: EntityUIStore<NewsfeedUIState>;

  constructor() {
    super();

    this.createUIStore({}, { deepFreezeFn: obj => obj }).setInitialEntityState(
      entity =>
        <NewsfeedUI>{
          loaded: false,
          selectedTab: SelectedTabNewsfeed.posts
        }
    );
  }
}
