<mat-form-field
  [class]="'phone-number-input-shared ' + className"
  [ngClass]="{ 'no-validator': noValidator }"
  #matFormField
>
  <mat-divider *ngIf="countries?.length" matSuffix [vertical]="true" class="!my-[6px] !ml-1 !mr-2 !h-5"></mat-divider>
  <input
    matSuffix
    matInput
    [formControl]="phoneNumber"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    maxInputLength
    pattern="^[0-9]+$"
    (keyup.enter)="onEnterKey()"
    onkeypress="return 48 <= event.charCode && event.charCode <= 57"
  />
  <ng-content matPrefix select="[matPrefix]"></ng-content>
  <ng-content></ng-content>
  <ng-content matSuffix select="[matSuffix]"></ng-content>
  <ng-container *ngIf="countries?.length">
    <div class="flex items-center gap-1 cursor-pointer" (click)="countrySelector.open()">
      <i class="flag {{ countryCtr.value?.code | uppercase }}"></i>
      <div
        class="flex items-center gap-1 b3n-text"
        [ngClass]="{ 'grayscale opacity-50': countryCtr.disabled || disabled }"
      >
        <span>+{{ countryCtr.value?.prefix }}</span>
        <mat-icon class="!w-5 !h-5 !text-[20px]"> arrow_drop_down </mat-icon>
      </div>
    </div>
  </ng-container>
  <mat-select
    #countrySelector
    [style]="{ display: 'none' }"
    [formControl]="countryCtr"
    [matTooltip]="countryCtr.value?.name"
    [disabled]="disabled"
    [panelClass]="'custom-country-selection'"
    (openedChange)="selectFocusChange($event)"
  >
    <mat-option class="relative input-search-country">
      <ngx-mat-select-search
        [placeholderLabel]="'Search'"
        noEntriesFoundLabel=""
        [formControl]="countryFilter"
      ></ngx-mat-select-search>
      <mat-icon
        *ngIf="!countryFilter.value?.length"
        class="!w-5 !h-5 !text-[20px] !m-0 absolute right-2 top-[14px] z-[2]"
      >
        search
      </mat-icon>
    </mat-option>
    <mat-divider class="sticky top-[40px] !mb-2"></mat-divider>
    <mat-option *ngFor="let countryData of countries" [value]="countryData">
      <div class="flex items-center gap-2">
        <i class="flag {{ countryData.code | uppercase }}"></i>
        {{ countryData.name }} (+{{ countryData.prefix }})
      </div>
    </mat-option>
    <div *ngIf="!countries.length" class="text-center text-sm py-[10px] px-2">No search found</div>
  </mat-select>

  <mat-error *ngIf="!!maxLength && phoneNumber.hasError('maxLength')"> Maximum {{ maxLength }} digits </mat-error>
  <mat-error *ngIf="phoneNumber.value?.length && phoneNumber.hasError('pattern')"> Number is invalid </mat-error>
  <mat-error *ngIf="phoneNumber.hasError('required')"> Number is required </mat-error>
  <mat-error *ngFor="let err of additionalErrs" [style]="{ display: err.isInvalid ? 'block' : 'none' }">
    {{ err.msg }}
  </mat-error>
</mat-form-field>
<ng-content select="[matHint]"></ng-content>
