import { HashMap } from '@datorama/akita';
import {
  CallParkingConfig,
  CrmIntegration,
  PopupConfig,
  quotaConfig,
  Threshold,
  TimeRange
} from '../org-config/org-config.model';
import { Transfer2GenieConfig } from '../queue/queue.model';

export class DomainConfig {
  defaultWrapUpTimeInSeconds: number;
  defaultSmsPerCallerInDay: number;
  awayDetectionUnansweredThreshold: number;
  minimumRingTimeInSeconds: number;
  remarks: string[];
  crmIntegration: CrmIntegration;
  popupConfig: PopupConfig;
  transfer2GenieConfig: HashMap<Transfer2GenieConfig>;
  outboundConcurrentCallLimit: number;
  outboundConcurrentCallUsage: number;
  officeHours: Array<TimeRange[]>;
  enableCasesFeature: boolean;
  callParkingConfig: CallParkingConfig;
  thresholdConfig: Threshold;
  changeAgentStatusPrefix: string;
  forwardingSettingPrefix: string;
  pickupPrefix: string;
  enableExtensionDirectory: boolean;
  quotaConfig: quotaConfig;
  focusMode: boolean;
  callMonitorPrefix: string;
  changeCallerIdPrefix: string;
  changeTaggedQueuePrefix: string;
  autoAddPlus: boolean;

  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
      if (obj.crmIntegration) {
        this.crmIntegration = new CrmIntegration(obj.crmIntegration);
      }
      if (obj.popupConfig) {
        this.popupConfig = new PopupConfig(obj.popupConfig);
      }
    }
  }
}
