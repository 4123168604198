import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TfaService } from '@b3networks/api/auth';
import { ToastService } from '@b3networks/shared/ui/toast';
import { finalize } from 'rxjs';
import { EnableTwofaV2Data } from '../enable-twofa-v2.component';

@Component({
  selector: 'b3n-authenticator-app',
  templateUrl: './authenticator-app.component.html',
  styleUrls: ['./authenticator-app.component.scss']
})
export class AuthenticatorAppComponent implements OnInit {
  qrSecret: string;
  qrCode = '';
  loading = true;
  progressing: boolean;
  verificationCodeCtr = new FormControl('', Validators.required);
  isEnable2fa: boolean;

  getVerificationCodeError() {
    if (this.verificationCodeCtr.hasError('required')) {
      return 'Verification code is required';
    } else if (this.verificationCodeCtr.hasError('mismatched')) {
      return 'Verification code mismatched';
    }
    return '';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnableTwofaV2Data,
    private dialogRef: MatDialogRef<AuthenticatorAppComponent>,
    private tfaService: TfaService,
    private toastService: ToastService,
    private dialog: MatDialog
  ) {
    this.isEnable2fa = !data?.tfaInfo?.tfaEnabled;
  }

  ngOnInit() {
    this.verificationCodeCtr.valueChanges.subscribe(() => {
      this.verificationCodeCtr.setErrors(null);
    });

    this.tfaService.getTotpCode().subscribe(res => {
      this.qrCode = res.uri;
      this.qrSecret = res.secret;
      this.loading = false;
    });
  }

  verify() {
    if (this.verificationCodeCtr.valid) {
      this.progressing = true;
      this.tfaService
        .verifyTotpCode({ secret: this.qrSecret, code: this.verificationCodeCtr.value })
        .pipe(finalize(() => (this.progressing = false)))
        .subscribe({
          next: res => {
            if (!res?.recoveryKey) {
              this.toastService.success('Updated successfully');
              this.dialogRef.close();
              return;
            }
            this.toastService.success(
              "You're all set. From now on, you'll use Authenticator to sign in to your account."
            );
            this.dialogRef.close(res.recoveryKey);
          },
          error: () => this.verificationCodeCtr.setErrors({ mismatched: true })
        });
    }
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedText = clipboardData.getData('text');
    if (pastedText.length === 6) {
      setTimeout(() => {
        this.verify();
      }, 0);
    }
  }
}
