import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { map, tap } from 'rxjs/operators';
import { SessionKB } from './sessions.model';
import { SessionsQuery } from './sessions.query';
import { SessionsState, SessionsStore } from './sessions.store';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {
  baseURL = 'workspace/private/v1/';
  constructor(
    private httpClient: HttpClient,
    private store: SessionsStore,
    private query: SessionsQuery
  ) {}

  getAll() {
    return this.httpClient.get<SessionKB[]>(`kbbot/private/v1/kb/sessions`).pipe(
      map(res => res.map(x => new SessionKB(x))),
      tap(data => {
        this.store.upsertMany(data, { baseClass: SessionKB });
      })
    );
  }

  createSession(question: string) {
    return this.httpClient.post<SessionKB>(`kbbot/private/v1/kb/sessions`, { question }).pipe(
      map(x => new SessionKB(x)),
      tap(data => {
        this.store.upsertMany([data], { baseClass: SessionKB });
      })
    );
  }

  setActive(convoId: string | ID) {
    this.store.setActive(convoId);
  }

  removeActive(convoId: string | ID) {
    this.store.removeActive(convoId);
  }

  updateLastActivityTime(convoId: string, time: number) {
    if (this.query.hasEntity(convoId)) {
      this.store.upsert(convoId, {
        lastActivityTime: time
      });
    }
  }

  updateStateStore(data: Partial<SessionsState>) {
    this.store.update(data);
  }
}
