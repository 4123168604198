import { ChatMessage } from '@b3networks/api/chat';
import { ID } from '@datorama/akita';

export interface DraftMsgNewsfeedUI {
  id: ID;
  loaded: boolean;
  mode: ModeDraft;
}

export class DraftMsgNewsfeedBase {
  id: string;
  channelId: string;
  identityUuid: string;
  status: DraftStatusNf;
  publishedAt: number;
  createdAt: number;
  preview: DraftMsgPreview;

  // optional
  chatMsgData: ChatMessage;
  mediaIds: string[];

  constructor(obj?: Partial<DraftMsgNewsfeedBase>) {
    if (obj) {
      Object.assign(this, obj);
      if (this.chatMsgData && typeof obj.chatMsgData === 'string') {
        try {
          this.chatMsgData = new ChatMessage(JSON.parse(obj?.['chatMsgData']));
        } catch (error) {
          this.chatMsgData = null;
          console.error('DraftMsgNewsfeed typeof obj[data] === string: ', this);
        }
      }

      if (!!obj.preview && typeof obj.preview === 'string') {
        try {
          this.preview = JSON.parse(obj.preview);
        } catch (error) {
          this.preview = <DraftMsgPreview>{
            title: '',
            content: obj?.preview
          };
        }
      }
    }
  }
}

export interface DraftMsgPreview {
  title: string;
  content: string;
}

export class DraftMsgNewsfeed extends DraftMsgNewsfeedBase {
  aclAction: AclActionDraft = <AclActionDraft>{};

  constructor(obj?: Partial<DraftMsgNewsfeed>) {
    super(obj);
    if (obj) {
      if (obj?.aclAction) this.aclAction = <AclActionDraft>{ ...obj.aclAction };
      this._buildAclAction();
    }
  }

  private _buildAclAction() {
    this._resetAclAction();

    switch (this.status) {
      case DraftStatusNf.draft:
        this.aclAction = {
          canPublish: true,
          canSchedule: true,
          canReSchedule: false,
          canCancelSchedule: false,
          canEdit: true,
          canDelete: true
        };
        break;
      case DraftStatusNf.pending2Publish:
        this.aclAction = {
          canPublish: true,
          canSchedule: false,
          canReSchedule: !!this.publishedAt,
          canCancelSchedule: true,
          canEdit: true,
          canDelete: true
        };
        break;
      case DraftStatusNf.published:
        this.aclAction = {
          canPublish: false,
          canSchedule: false,
          canReSchedule: false,
          canCancelSchedule: false,
          canEdit: false,
          canDelete: false
        };
        break;
      default:
        break;
    }
  }

  private _resetAclAction() {
    this.aclAction = {
      canPublish: false,
      canSchedule: false,
      canReSchedule: false,
      canCancelSchedule: false,
      canEdit: false,
      canDelete: false
    };
  }
}

export interface AclActionDraft {
  canPublish: boolean;
  canSchedule: boolean;
  canReSchedule: boolean;
  canCancelSchedule: boolean;
  canEdit: boolean;
  canDelete: boolean;
}

export enum ModeDraft {
  editAndAdd = 'editAndAdd',
  preview = 'preview'
}

export interface ReqChatMessageFeed {
  chatMsgData: string; //json string of ChatMessage
  mediaIds: string[]; //list media inclue in this post
  preview?: string;
}

export enum DraftStatusNf {
  draft = 'draft',
  pending2Publish = 'pending2Publish',
  published = 'published'
}
