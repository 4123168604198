import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppByFeatureResponse,
  CreateProductSku,
  DistributingSku,
  DistributingSkuReq,
  ExtendedStoreProduct,
  GetSkuReq,
  PriceChain,
  Sku,
  StoreChannel,
  StoreSku
} from '@b3networks/api/store';
import { X_B3_HEADER } from '@b3networks/shared/common';
import { ID } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SkuService {
  constructor(private http: HttpClient) {}

  getSku(domain, productId, sku: string): Observable<Sku> {
    return this.http
      .get<Sku>(`store/private/v1/domains/${domain}/products/${productId}/skus/${sku}`)
      .pipe(map(res => new Sku(res)));
  }

  fetchSkus(domain, request: GetSkuReq): Observable<Sku[]> {
    const params = new HttpParams().set('filter', request.filter);
    return this.http
      .get<Sku[]>(`store/private/v1/domains/${domain}/products/${request.productId}/skus`, {
        params: params
      })
      .pipe(map(list => list.map(sku => new Sku(sku))));
  }

  getAvailableSkus(productId: string) {
    const params = new HttpParams().set('productId', productId);

    return this.http
      .get<DistributingSku[]>(`store/private/v2/products/${productId}/skus`, {
        params: params
      })
      .pipe(map(skus => skus.map(sku => new DistributingSku({ ...sku, isAvailable: true }))));
  }

  getDistributingSkus(channelOrgUuid: string, productId: string) {
    const params = new HttpParams().set('channelOrgUuid', channelOrgUuid).set('productId', productId);

    return this.http
      .get<DistributingSku[]>(`store/private/v3/distributingskus`, {
        params: params
      })
      .pipe(map(skus => skus.map(sku => new DistributingSku(sku))));
  }

  getProductSkus(productId: ID, filter?: string) {
    let params = new HttpParams();
    if (filter) {
      params = params.set('filter', filter);
    }

    return this.http
      .get<Sku[]>(`/store/private/v2/app/products/${productId}/skus`, { params })
      .pipe(map(list => list.map(i => new Sku(i))));
  }

  createProductSku(body: CreateProductSku, productId: ID) {
    return this.http.post<void>(`/store/private/v2/app/products/${productId}/skus`, body);
  }

  checkProductSku(productId: ID, sku: string) {
    return this.http.get<Sku[]>(`/store/private/v2/app/products/${productId}/skus/${sku}`);
  }

  getSaleModel(productId: string, skuCode: string, saleModelCode: string): Observable<PriceChain> {
    return this.http.get<PriceChain>(
      `/store/private/v2/utility/products/${productId}/skus/${skuCode}/salemodels/${saleModelCode}/chain`
    );
  }

  getDomainProducts(type?: string, includeDescription?: boolean): Observable<Array<ExtendedStoreProduct>> {
    let params = new HttpParams();
    if (!!type) {
      params = params.set('type', type);
    }
    if (!!includeDescription) {
      params = params.set('includeDescription', includeDescription ? 'true' : 'false');
    }
    return this.http
      .get<any[]>('/store/private/v2/app/products', { params })
      .pipe(map(res => res.map(r => new ExtendedStoreProduct(r))));
  }

  getChannelsBySellerUuid(sellerUuid: string): Observable<StoreChannel[]> {
    const params = {
      sellerUuid: sellerUuid
    };

    return this.http
      .get<any[]>('store/private/v1/admin/channels', { params: params })
      .pipe(map(res => res.map(r => new StoreChannel(r)).sort((a, b) => a.partnerName.localeCompare(b.partnerName))));
  }

  getDomainSkus(productId: string): Observable<Array<StoreSku>> {
    return this.http
      .get<any[]>(`/store/private/v2/products/${productId}/skus`)
      .pipe(map(res => res.map(r => new StoreSku(r)).sort((a, b) => a.name.localeCompare(b.name))));
  }

  getAppByFeatures(features: string): Observable<AppByFeatureResponse[]> {
    return this.http.get<AppByFeatureResponse[]>(
      `/apps/private/v3/apps?features=${features}&includeSku=false&includeFeature=false`
    );
  }

  updateProductVisibility(req: DistributingSkuReq) {
    return this.http.put<void>(`/store/private/v3/distributingproducts/visibility`, req);
  }

  getSkus(orgUuid: string, productId: string, filter?: string): Observable<Sku[]> {
    if (!productId) return of([]);

    let headers = new HttpHeaders();
    if (orgUuid) {
      headers = headers.set(X_B3_HEADER.orgUuid, orgUuid);
    }

    return this.http
      .get<Sku[]>(`/store/private/v2/superadmin/products/${productId}/skus`, {
        headers: headers,
        params: filter ? new HttpParams().set('filter', filter) : null
      })
      .pipe(map(data => data.map(x => new Sku(x))));
  }
}
