import { Injectable } from '@angular/core';
import { EntityUIQuery, ID, Order, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TxnUI } from './txn-ui.model';
import { Txn, TxnChannel, TxnStatus } from './txn.model';
import { TxnState, TxnStore, TxnUIState } from './txn.store';

@Injectable({ providedIn: 'root' })
export class TxnQuery extends QueryEntity<TxnState> {
  override ui: EntityUIQuery<TxnUIState>;

  hasMorePending$ = this.select('statePending').pipe(map(x => x?.hasMore));
  fetchingTxn$ = this.select('fetchingTxn');

  constructor(protected override store: TxnStore) {
    super(store);
    this.createUIQuery();
  }

  getTxn(txnUuid: string) {
    return this.getEntity(txnUuid);
  }

  selectEntity$(txnUuid: string) {
    return this.selectEntity(txnUuid);
  }

  getActiveTxn() {
    return this.getActive();
  }

  activeId$() {
    return this.selectActiveId();
  }

  activeTxn$() {
    return this.selectActive();
  }

  selectJourneyTxnByCustomer(customerUuid: string, limit = 5) {
    return this.selectAll({
      filterBy: entity => entity.customerUuid === customerUuid,
      limitTo: limit,
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    });
  }

  findTxnByConvo(convoId: string) {
    return this.getAll({
      filterBy: entity =>
        entity.publicConvoId === convoId || entity.teamConvoId === convoId || entity.txnUuid === convoId,
      limitTo: 1
    })?.[0];
  }

  findTxnByConvoPublic(convoId: string) {
    return this.getAll({
      filterBy: entity => entity.publicConvoId === convoId,
      limitTo: 1
    })?.[0];
  }

  findTxnByConvoInternal(convoId: string) {
    return this.getAll({
      filterBy: entity => entity.teamConvoId === convoId,
      limitTo: 1
    })?.[0];
  }

  getTeamConvoTxn(convoId: string) {
    return this.getAll({
      filterBy: entity => entity?.teamConvoId === convoId,
      limitTo: 1
    })?.[0];
  }

  selectPropertyTxn<K extends keyof Txn>(id: string, property: K) {
    return this.selectEntity(id, property);
  }

  selectPropertyFuncTxn<R>(id: string, project: (entity?: Txn) => R): Observable<R> {
    return this.selectEntity(id, project);
  }

  selectManyPropertyFuncTxn<R>(ids: string[], project: (entity?: Txn) => R): Observable<R[]> {
    return this.selectMany(ids, project);
  }

  selectUIState<K extends keyof TxnUI>(id: string | ID, property: K) {
    return this.ui.selectEntity(id, property);
  }

  getUiState(txnUuid: string | ID) {
    return this.ui.getEntity(txnUuid);
  }

  hasMorePending() {
    return this.select(entity => entity?.statePendingV2?.hasMore);
  }

  hasMoreActive() {
    return this.select(entity => entity?.stateActiveV2?.hasMore);
  }

  selectTxnsAssignToMeV2(meIdentity: string) {
    return this.selectAll({
      filterBy: entity =>
        !entity.isClosedV2 && entity?.lastAssignedAgents?.findIndex(identityUuid => identityUuid === meIdentity) > -1,
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    });
  }

  selectCountUnreadAssignedToMe(meIdentity: string) {
    return this.selectCount(entity => {
      const unread = (entity.unreadPublicConvo || 0) + (entity.unreadTeamConvo || 0);
      const hasAssingedToMe = entity.lastAssignedAgents?.includes(meIdentity);
      return !entity.isClosedV2 && unread > 0 && hasAssingedToMe;
    });
  }

  selectCountUnreadCreated(meIdentity: string) {
    return this.selectCount(entity => {
      const unread = (entity.unreadPublicConvo || 0) + (entity.unreadTeamConvo || 0);
      const hasCreated = entity.caseInfo?.createdByIdentity === meIdentity;
      return !entity.isClosedV2 && unread > 0 && hasCreated;
    });
  }

  selectTxnsAssignedAndCreated(meIdentity: string) {
    return this.selectAll({
      filterBy: entity => {
        const hasAssingedToMe = entity.lastAssignedAgents?.includes(meIdentity);
        const hasCreated = entity.caseInfo?.createdByIdentity === meIdentity;
        return !entity.isClosedV2 && (hasAssingedToMe || hasCreated);
      }
    });
  }

  selectTxnsAssigningAndTransfering(meIdentity: string) {
    return this.selectAll({
      filterBy: entity => {
        const isSupportedChannel = [TxnChannel.livechat, TxnChannel.whatsapp].includes(entity.channel);
        const isSupportedStatus = [TxnStatus.assigning, TxnStatus.transferring].includes(entity.status);
        const hasAssinging = entity?.assigningTo === meIdentity;
        return isSupportedChannel && isSupportedStatus && hasAssinging;
      }
    });
  }
}
