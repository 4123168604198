import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MediaFile } from './medias.model';

export interface MediaState extends EntityState<MediaFile> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_txn_medias', idKey: 'id' })
export class MediasStore extends EntityStore<MediaState> {
  constructor() {
    super();
  }
}
