import { OutputProcessMessage } from '@b3networks/shared/common';
import { AttachmentMessageData } from './chat-message-attachment.model';
import { PreviewMessageData } from './chat-message-data.model';
import { InteractiveMessageData } from './chat-message-interactive.model';

export interface BuildMessageUI {
  builtTextMessage: OutputProcessMessage;
  displayTime: string; // HH:mm
  fullTime: string; // Mar 7, 2024,1:12:34 PM

  newByLastMessageChannel: boolean;
  builtTextForLastMessageConvo: OutputProcessMessage; // newByLastMessageChannel = true
}

export interface StateMessageUI {
  cloneRootMessageThread: string;

  isCustomRender: boolean;
  isCustomerTxn: boolean; // livechat,whatsapp
  isInteractiveV2: boolean;
  isErrorFormat: boolean;
  isPreviewMsg: boolean;
  isNotified: boolean;
}

export interface AclActionMessageUI {
  isReplyMessage: boolean;
  isBookmarkMessage: boolean;
  isDeletedMessage: boolean;
  isEditedMessage: boolean;
  timeoutDeleteEdit: number;

  allowUseAddBookmark: boolean;
  allowUseReplyAction: boolean;
  allowUseCreateThreadAction: boolean;
  allowEditMessage: boolean;
  allowDeleteMessage: boolean;
  allowPinOrUnpinAction: boolean;
}

export interface ChatMessageAttachmentUI {
  attachmentData: AttachmentMessageData;
  isImgFile: boolean;
  isVideo: boolean;
  isAudio: boolean;
  isFailed: boolean;
  fileType: string;
  logoFileType: string;
  sizeFile: string;
  isStorage: boolean;
  keyWithOrgUuid: string;
}

export interface PreviewMessageUI {
  previewData: PreviewMessageData;
  backgroundImage: string;
  isYoutube: boolean;
  htmlDecode: string;
  extractUrl: string;
  embededUrlYoutube: string;
  srcDocYoutube: string;
}

export interface WebhookUI {
  interactiveData: InteractiveMessageData;
  renderHeaderMarkdown: OutputProcessMessage; // markdown
}

// parser from msg.user
export interface UserStateUI {
  identityUuid: string;
  orgUuid: string;

  icon: string;
  isBot: boolean;
  isSvg: boolean;
  botType: BotType;
}

export enum BotType {
  'omni' = 'omni',
  'robin' = 'robin',
  'default' = 'default'
}
