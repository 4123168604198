import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RoleStore } from './role.store';
import { AssignedMember, AssignUnassignMemberReq, CreateUpdateRoleReq, Role, RoleAction, RoleType } from './role.modal';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(
    private http: HttpClient,
    private store: RoleStore
  ) {}

  getRoles(type = RoleType.organization, memberUuid?: string): Observable<Role[]> {
    let params = new HttpParams().set('type', type);
    if (memberUuid) {
      params = params.set('memberUuid', memberUuid);
    }
    return this.http.get<Role[]>(`/auth/private/v1/roles`, { params: type ? params : null }).pipe(
      map(roles => roles.map(role => new Role(role))),
      tap(roles => {
        if (!memberUuid) {
          this.store.set(roles);
        }
      })
    );
  }

  getAllRoles(): Observable<Role[]> {
    return forkJoin([this.getRoles(RoleType.system), this.getRoles(RoleType.organization)]).pipe(
      map(([systemRoles, organizationRoles]) => [...systemRoles, ...organizationRoles]),
      tap(allRoles => {
        this.store.set(allRoles);
      })
    );
  }

  createRole(req: CreateUpdateRoleReq): Observable<Role> {
    return this.http.post<Role>(`/auth/private/v1/roles`, req);
  }

  updateRole(req: CreateUpdateRoleReq, roleUuid: string): Observable<Role> {
    return this.http.put<Role>(`/auth/private/v1/roles/${roleUuid}`, req).pipe(
      tap(role => {
        this.store.update(roleUuid, role);
      })
    );
  }

  deleteRole(roleUuid: string): Observable<void> {
    return this.http.delete<void>(`/auth/private/v1/roles/${roleUuid}`);
  }

  getActions(roleUuid: string): Observable<RoleAction[]> {
    return this.http.get<RoleAction[]>(`/auth/private/v1/roles/${roleUuid}/actions`).pipe(
      tap((actions: any) => {
        this.store.update(roleUuid, { actions });
      })
    );
  }

  getAssignedMembersOnRole(roleUuid: string): Observable<AssignedMember[]> {
    return this.http.get<AssignedMember[]>(`/auth/private/v1/roles/${roleUuid}/members`);
  }

  assignMembersToRole(roleUuid: string, req: AssignUnassignMemberReq): Observable<void> {
    return this.http.put<void>(`/auth/private/v1/roles/${roleUuid}/assignments`, req);
  }

  unassignMembersFromRole(roleUuid: string, req: AssignUnassignMemberReq): Observable<void> {
    return this.http.put<void>(`/auth/private/v1/roles/${roleUuid}/assignments`, req);
  }
}
