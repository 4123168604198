import { ROBIN_BOT_IDENTITY, SYSTEM_IDENTITY, decodeUserId } from '@b3networks/shared/common';
import { Observable } from 'rxjs';
import { ChatMessage } from '../../chat-message/chat-message.model';
import { BotType, BuildMessageUI } from '../../chat-message/chat-messsage-ui.model';
import { ConvoType, Privacy, Status, UserType } from '../../enums.model';
import { ChannelType, MappingChannelType, NameChannelPersonal } from '../model/enum-channel.model';

export const ChatChannelStoreName = 'chat_channel';

export class ChannelBase {
  id: string;
  updatedAt: number; // date
  createdAt: number; // date
  archivedAt: number; // date
  archivedBy: string;
  createdBy: string;
  name: string;
  description: string;
  privacy: Privacy;
  type: ChannelType;
  status: Status;
  participants: IParticipant[];
  closedL2?: ClosedChannel; // when channel close
  parent: ParentThreadInfo;
  ownerId: string;
  emc: number; // estimated message count
  lastSeenMillis: number | null;
  namespace: string;

  listLastMessageIds: string[] = [];
  _lastMessage: ChatMessage;
  get lastMessage() {
    return this._lastMessage;
  }
  set lastMessage(msg: ChatMessage) {
    if (!msg) return;
    this._lastMessage = msg;
    if (this.type === ChannelType.NEWSFEED)
      if (!this.listLastMessageIds.includes(msg.id)) this.listLastMessageIds = [msg.id, ...this.listLastMessageIds];
  }

  constructor(obj?: Partial<ChannelBase>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  get parentId() {
    return this.parent?.message?.convo;
  }

  get parentMessageId() {
    return this.parent?.message?.id;
  }

  get closedAt() {
    return this.closedL2?.at;
  }

  get ownerIsParticipants() {
    return !!this.ownerId && this.participants?.some(m => m.userID === this.ownerId);
  }

  get isOpen(): boolean {
    return !this.archivedAt;
  }

  get isClosed(): boolean {
    return !!this.closedL2?.id;
  }

  get isArchived(): boolean {
    return !!this.archivedAt || !!this.archivedBy;
  }

  get isGroupChat() {
    return this.type === ChannelType.gc;
  }

  get isThread() {
    return this.type === ChannelType.THREAD;
  }

  get isDirectChat() {
    return this.type === ChannelType.dm;
  }

  get isPersonalChat() {
    return this.type === ChannelType.PERSONAL;
  }

  get isNewsFeedChat() {
    return this.type === ChannelType.NEWSFEED;
  }

  get isThreadChat() {
    return this.type === ChannelType.THREAD;
  }

  get isPersonalBookmark() {
    return this.type === ChannelType.PERSONAL && this.name === NameChannelPersonal.BOOKMARKS;
  }

  get isGeneral(): boolean {
    return this.type === ChannelType.gc && this.name === 'general';
  }

  get displayConvoType(): string {
    return this.type === ChannelType.dm ? 'Member' : 'Team';
  }

  get displayDetailConvoType(): string {
    return this.type === ChannelType.dm ? 'Member' : 'Channel';
  }

  get isPublic() {
    return this.privacy === Privacy.public;
  }

  get convoType() {
    switch (this.type) {
      case ChannelType.dm:
        return ConvoType.direct;
      case ChannelType.gc:
        return ConvoType.groupchat;
      case ChannelType.THREAD:
        return ConvoType.THREAD;
      case ChannelType.PERSONAL:
        return ConvoType.personal;
      case ChannelType.NEWSFEED:
        return ConvoType.NEWSFEED;
      case ChannelType.KB:
        return ConvoType.KB;
    }
  }

  get userType() {
    return UserType.TeamMember;
  }
}

// only support : direct and group
export class Channel extends ChannelBase {
  // withMe
  // TODO: move to UI state
  unreadCount: number;
  mentionCount: number;
  isStarred: boolean;
  nameUserDirect: string;
  isBot: boolean;
  isDisableUser: boolean;

  // client UI
  meUuid: string; // chatUser
  directChatUsers: DirectChatUser = <DirectChatUser>{};
  isDraft$: Observable<boolean>;
  icon: string;
  isSvg: boolean;
  classIcon: string;
  isTemporary: boolean; // need to refetch detail

  // state
  actions: ActionStateChannel = <ActionStateChannel>{};

  constructor(obj?: Partial<Channel>) {
    super(obj);
    if (obj) {
      Object.assign(this, obj);

      this.type = MappingChannelType(obj.type);

      if (obj?.privacy?.toLowerCase() === 'private') {
        this.privacy = Privacy.private;
      } else if (obj?.privacy?.toLowerCase() === 'public') {
        this.privacy = Privacy.public;
      }

      if (obj?.emc) this.emc = +obj.emc || 0;
      if (obj?.createdAt) this.createdAt = +obj?.createdAt;
      if (obj?.updatedAt) this.updatedAt = +obj?.updatedAt;
      if (obj?.archivedAt) this.archivedAt = +obj?.archivedAt;
      if (obj?.lastSeenMillis) this.lastSeenMillis = +this.lastSeenMillis || 0;
      if (obj?.lastMessage) {
        this.lastMessage = new ChatMessage({
          ...obj.lastMessage,
          buildMsg: <BuildMessageUI>{
            newByLastMessageChannel: true
          }
        });
      }
      if (obj?.unreadCount) this.unreadCount = +this.unreadCount || 0;
      if (obj?.mentionCount) this.mentionCount = +this.mentionCount || 0;
      if (!obj.isTemporary) {
        this.isTemporary = false;
      }
      if (obj?.actions) {
        this.actions = <ActionStateChannel>{ ...obj.actions };
      }
      if (obj?.directChatUsers) this.directChatUsers = <DirectChatUser>{ ...obj.directChatUsers };

      // set icon
      this.setIconChannel();
      this.initActionsState();
      this.initDirectChatUsersState();
    }
  }

  withMeUuid(meUuid: string) {
    this.meUuid = meUuid;
    if (!!meUuid && this.type === ChannelType.dm) {
      // because participant only 2 user (me and you) in direct chat
      this.directChatUsers = <DirectChatUser>{
        meUuid: meUuid,
        otherUuid:
          this.participants?.length === 1
            ? meUuid
            : this.participants[0].userID === meUuid
              ? this.participants[1].userID
              : this.participants[0].userID
      };
    }
    this.initDirectChatUsersState();
    return new Channel(this);
  }

  // filter orther public channel
  get isMyChannel() {
    return (
      (this.type === ChannelType.dm && !this.isDisableUser) ||
      this.type === ChannelType.PERSONAL ||
      (this.type === ChannelType.gc && this.isMember) ||
      this.type === ChannelType.NEWSFEED ||
      this.type === ChannelType.KB // mine
    );
  }

  get displayName(): string {
    switch (this.type) {
      case ChannelType.dm:
        return this.nameUserDirect || this.directChatUsers?.otherUuid;
      case ChannelType.PERSONAL:
        return this.name[0].toUpperCase() + this.name.slice(1);
      default:
        // because group only id&name property,no type property
        return this.name;
    }
  }

  get isOwner() {
    return !!this.meUuid && this.ownerId === this.meUuid;
  }

  get isMember() {
    return !!this.meUuid && this.participants?.some(m => m.userID === this.meUuid);
  }

  get isUnread(): boolean {
    return this.unreadCount > 0 || this.mentionCount > 0;
  }

  get isRobinBot() {
    return this.directChatUsers?.botType === BotType.robin;
  }

  private setIconChannel() {
    if (this.type === ChannelType.PERSONAL) {
      switch (this.name) {
        case NameChannelPersonal.BOOKMARKS:
          this.icon = 'bookmark';
          this.isSvg = false;
          break;
        default:
          this.icon = 'person';
          break;
      }
    } else if (this.type === ChannelType.gc) {
      this.isSvg = false;
      this.icon = this.isArchived ? 'inventory_2' : this.privacy === Privacy.private ? 'lock' : 'tag';
      this.classIcon = 'b3n-text-active';
    } else if (this.type === ChannelType.dm) {
      if (this.directChatUsers?.icon) this.icon = 'fiber_manual_record';
      this.isSvg = false;
      this.classIcon = 'b3n-text-active';
    } else if (this.type === ChannelType.THREAD) {
      this.icon = 'thread_outline';
      this.isSvg = true;
    } else if (this.type === ChannelType.NEWSFEED) {
      this.icon = 'newsmode';
      this.isSvg = false;
    }
  }

  private initActionsState() {
    // reset state
    this.actions = <ActionStateChannel>{
      canArchive: false,
      canUnArchive: false,
      canClose: false,
      canJoin: false,
      canLeave: false,
      canStar: false,
      canUnStar: false
    };
    if (this.type === ChannelType.gc && !this.isGeneral) {
      this.actions.canJoin = !this.isMember && !this.isArchived;
      this.actions.canLeave = !this.isOwner && this.isMember && !this.isArchived;

      if (!this.actions.canJoin) {
        if (this.isOwner) {
          this.actions.canArchive = !this.isArchived;
          this.actions.canUnArchive = this.isArchived;
          // this.actions.canClose = true;
        }
      }
    }

    this.actions.canStar = !this.isStarred && this.isMember && !this.isArchived;
    this.actions.canUnStar = this.isStarred && this.isMember && !this.isArchived;
  }

  private initDirectChatUsersState() {
    if (this.directChatUsers.otherUuid && !this.directChatUsers.otherIdentityUuid) {
      try {
        const [orgUuid, identityUuid] = decodeUserId(this.directChatUsers.otherUuid);
        this.directChatUsers.otherIdentityUuid = identityUuid;
        this.directChatUsers.orgUuid = orgUuid;
      } catch (error) {
        this.directChatUsers.otherIdentityUuid = '';
      }
    }

    if (this.directChatUsers.otherIdentityUuid === ROBIN_BOT_IDENTITY) {
      this.directChatUsers.icon = 'b3n_robin_outline';
      this.directChatUsers.isSvg = true;
      this.directChatUsers.isDirectBot = true;
      this.directChatUsers.botType = BotType.robin;
    } else if (this.directChatUsers.otherIdentityUuid === SYSTEM_IDENTITY) {
      this.directChatUsers.icon = 'smart_toy';
      this.directChatUsers.isDirectBot = true;
      this.directChatUsers.botType = BotType.omni;
    } else {
      this.directChatUsers.icon = 'person';
      this.directChatUsers.isDirectBot = false;
    }
  }
}

export interface ActionStateChannel {
  canArchive: boolean;
  canUnArchive: boolean;
  canClose: boolean;
  canJoin: boolean;
  canLeave: boolean;
  canStar: boolean;
  canUnStar: boolean;
}

export interface ParentThreadInfo {
  message: {
    id: string; // created thread by messageId
    convo: string; // channelId
  };
}

export interface ClosedChannel {
  id: string; // close Id
  at: number;
  by: string; // chatUserId
}

export interface ChannelPersonalResponse {
  channels: {
    [key: string]: {
      details: Partial<Channel>;
    };
  };
}

export interface IParticipant {
  userID: string;
  role: string;
  namespace?: string;
}

export interface IParticipantA {
  id: string;
  role: string;
  namespace?: string;
}

export interface DirectChatUser {
  meUuid: string; // chatUser
  otherUuid: string; // chatUser
  otherIdentityUuid: string;
  orgUuid: string;

  isDirectBot: boolean;
  botType: BotType;
  icon: string;
  isSvg: boolean;
}

export interface CreateConvoGroupReq {
  name: string;
  description: string;
  privacy: Privacy;
  type: ChannelType | string;
  participants: string[]; // chatUser
}

// only 1 property
export interface UpdateChannelReq {
  name: string;
  description: string;
  add: string[];
  del: string[];
}

export interface RecentChannel {
  id: string;
  date: number;
}

// if the original message changed, calling the copy API again with the same payload to update the cloned message
export interface CopyMessageRequest {
  srcMessage: {
    convo: string;
    id: string;
    ct: string;
  };
  dstConvoId: string;
  options?: {
    shouldDeduplicate?: boolean; // must be true for bookmark channel
  };
}

export interface CreateThreadRequest {
  thread: {
    details: {
      name: string;
      description: string;
    };
    parent: ParentThreadInfo;
  };
}

export class ActiveThreadByParentRepsonse {
  details: {
    id: string;
    name: string;
    description: string;
    privacy: Privacy;
    type: ChannelType;
    createdBy: string;
    createdAt: number;
    namespace: string;
  };
  lastMessage: ChatMessage;
  parent: ParentThreadInfo;
  users: IParticipantA[];

  constructor(obj: Partial<ActiveThreadByParentRepsonse>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj?.['lastMessage']) {
        this.lastMessage = new ChatMessage({
          ...obj.lastMessage,
          buildMsg: <BuildMessageUI>{
            newByLastMessageChannel: true
          }
        });
      }

      if (obj?.details?.createdAt) this.details.createdAt = +obj.details.createdAt;
    }
  }
}

export class GetChannelWithMeV2Response {
  details: {
    id: string;
    name: string;
    privacy: Privacy;
    type: ChannelType;
    createdBy: string;
    createdAt: number;
    namespace: string;
  };
  lastMessage: ChatMessage;
  seenStatus: {
    unreadCount: number;
    mentionCount: number;
    lastSeenMillis: number;
  };
  parent: ParentThreadInfo;
  closedL2: ClosedChannel; // when channel close
  users: IParticipantA[];
  estimatedMessagesCount: number;
  archived: {
    at: number;
    by: string;
  };

  constructor(obj: Partial<GetChannelWithMeV2Response>) {
    if (obj) {
      Object.assign(this, obj);
      if (obj?.lastMessage)
        this.lastMessage = new ChatMessage({
          ...obj.lastMessage,
          buildMsg: <BuildMessageUI>{
            newByLastMessageChannel: true
          }
        });

      // convert string to number timestamp
      if (obj?.details?.createdAt) this.details.createdAt = +obj.details.createdAt;
      if (obj?.seenStatus?.unreadCount) this.seenStatus.unreadCount = +obj.seenStatus.unreadCount;
      if (obj?.seenStatus?.['mentionsCount']) this.seenStatus.mentionCount = +obj.seenStatus['mentionsCount'];
      if (obj?.seenStatus?.lastSeenMillis) this.seenStatus.lastSeenMillis = +obj.seenStatus.lastSeenMillis;
      if (obj?.estimatedMessagesCount) this.estimatedMessagesCount = +obj.estimatedMessagesCount || 0;
      if (obj?.closedL2?.at) this.closedL2.at = +obj.closedL2.at;
      if (obj?.archived?.at) this.archived.at = +obj.archived.at;
    }
  }
}

export interface RequestRangeThreadsByParent {
  parentId?: string;
  limit: number;
  fromInclusive?: boolean;
  toInclusive?: boolean;
  beforeFromSize?: number; // null = 0
  afterToSize?: number; // null = 0
  isAsc?: boolean;
  options?: {
    withBookmarks?: boolean;
  };

  // closedL2 id
  from?: string;
  to?: string;
}

export class ResponseRangeThreads {
  threads: GetChannelWithMeV2Response[];
  count: number;

  // threadId
  from: string;
  to: string;

  // ui
  channels: Channel[];

  constructor(obj?: Partial<ResponseRangeThreads>) {
    if (obj) {
      Object.assign(this, obj);

      this.threads = obj?.threads?.map(x => new GetChannelWithMeV2Response(x)) || [];
    }
  }

  withConvertChannel(channels: Channel[]) {
    this.channels = channels || [];
    return this;
  }
}

export interface RequestRangeThreadsByUser {
  limit: number;
  fromInclusive?: boolean;
  toInclusive?: boolean;
  beforeFromSize?: number; // null = 0
  afterToSize?: number; // null = 0
  isAsc?: boolean;
  options?: {
    withBookmarks?: boolean;
  };

  // threadId
  from?: string;
  to?: string;
}

export interface ReqGetMultiFromNamespaces {
  options: {
    skipParticipants: boolean; // must be true if caller is subscriber
    skipLastMessage: boolean;
    skipSeenData: boolean;
  };
  channels: GetMultiFromNamespacesChannel[];
}

export interface GetMultiFromNamespacesChannel {
  namespaceId: string; // orgUuid
  id: string; // newsfeedId
}
