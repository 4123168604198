import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserDirectory, UserDirectoryQuery } from '@b3networks/api/directory';
import {
  MemberNewsfeedQuery,
  MemberNewsFeedService,
  Newsfeed,
  RequestUpdateManyMemberNF,
  RequestUpdateMemberNFAction,
  RoleNewsFeed
} from '@b3networks/api/inbox';
import { MyErrorStateMatcher, X } from '@b3networks/shared/common';
import { ButtonLoadingDirective } from '@b3networks/shared/ui/material';
import { ToastService } from '@b3networks/shared/ui/toast';
import { finalize, map, Observable, startWith, switchMap } from 'rxjs';
import { ConvoHelperService } from '../../../../core/adapter/convo-helper.service';

export interface TransferOwnershipInput {
  feed: Newsfeed;
}

@Component({
  selector: 'b3n-transfer-ownership',
  templateUrl: './transfer-ownership.component.html',
  styleUrls: ['./transfer-ownership.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ButtonLoadingDirective,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule,
    MatProgressSpinnerModule
  ]
})
export class TransferOwnershipComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  memberOwnerCtr = new FormControl(null, [this.checkRequired()]);
  members$: Observable<UserDirectory[]>;
  progressing = false;
  loading = true;

  constructor(
    private dialogRef: MatDialogRef<TransferOwnershipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TransferOwnershipInput,
    private userDirectoryQuery: UserDirectoryQuery,
    private convoHelperService: ConvoHelperService,
    private memberNewsFeedService: MemberNewsFeedService,
    private memberNewsFeedQuery: MemberNewsfeedQuery,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.memberNewsFeedService
      .getMemberByFeedID(this.data.feed.channelId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe();

    this.members$ = this.memberOwnerCtr.valueChanges.pipe(
      startWith(''),
      map(value => {
        const text = typeof value === 'string' ? value?.trim() : '';
        const users = this.userDirectoryQuery.getUsersNoBot();
        return this.convoHelperService
          .fuseSearchHelper(users, text, ['displayName'], -1)
          .result?.sort((a, b) => a.score - b.score)
          .map(x => x.item);
      })
    );
  }

  displayFn(user: UserDirectory) {
    return user ? user.displayName : '';
  }

  transfer() {
    const value = this.memberOwnerCtr.value as UserDirectory;
    if (!value) return;
    this.progressing = true;
    const participant = this.memberNewsFeedQuery.getItem(this.data.feed.channelId)?.participant || [];

    if (participant.some(x => x.chatUserId === value.chatUserId)) {
      this.memberNewsFeedService
        .updateManyMemberByFeedID(this.data.feed?.channelId, <RequestUpdateManyMemberNF>{
          orgUuid: X.orgUuid,
          members: [value.identityUuid],
          role: RoleNewsFeed.owner,
          action: RequestUpdateMemberNFAction.transferOwne
        })
        .pipe(finalize(() => (this.progressing = false)))
        .subscribe(
          () => {
            this.toastService.success('Transferred successfully');
            this.dialogRef.close(true);
          },
          err => this.toastService.error(err?.message)
        );
    } else {
      this.memberNewsFeedService
        .updateManyMemberByFeedID(this.data.feed.channelId, <RequestUpdateManyMemberNF>{
          orgUuid: X.orgUuid,
          members: [value.identityUuid],
          role: RoleNewsFeed.subscriber,
          action: RequestUpdateMemberNFAction.add
        })
        .pipe(
          switchMap(() =>
            this.memberNewsFeedService.updateManyMemberByFeedID(this.data.feed?.channelId, <RequestUpdateManyMemberNF>{
              orgUuid: X.orgUuid,
              members: [value.identityUuid],
              role: RoleNewsFeed.owner,
              action: RequestUpdateMemberNFAction.transferOwne
            })
          )
        )
        .pipe(finalize(() => (this.progressing = false)))
        .subscribe(
          () => {
            this.toastService.success('Transferred successfully');
            this.dialogRef.close(true);
          },
          err => this.toastService.error(err?.message)
        );
    }
  }

  checkRequired(): any {
    return (control: AbstractControl): { [key: string]: boolean } => {
      if (!control.value) {
        return null;
      }
      return control.value instanceof UserDirectory ? null : { required: true };
    };
  }
}
