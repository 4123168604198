import { Contact } from '@b3networks/api/contact';
import { UserDirectory } from '@b3networks/api/directory';
import { UA } from 'jssip';
import { RTCSession } from 'jssip/lib/RTCSession';
import { Observable } from 'rxjs';

export class WebrtcInitData {
  username: string;
  endpoint: string;
  port: string;
  sipUsername: string;
  sipPassword: string;
  userAgent: string;
  sturn: string;
  domain: string;
  expiredAt: number;

  jwt?: string;
  isNoCloudFont: boolean;

  constructor(obj?: Partial<WebrtcInitData>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  /**
   * return `wss://${this.endpoint}${this.port ? ':' + this.port : ''}`;
   * sudo su
   * ping sip-wss-1.b3networks.com
   * netstat -ln | grep 443 |grep 13.251.192.231
   */
  get wsUrl() {
    if (!this.isNoCloudFont) {
      // sip-wss-1.b3networks.com, sip-wss-2.b3networks.com
      if (this.endpoint?.startsWith('sip-wss-')) {
        const prefix = this.endpoint.split('.')?.[0]?.replace('sip-wss-', 'c') || '';
        if (prefix) {
          return `wss://${`${this.domain}/_${prefix}`}${this.port ? ':' + this.port : ''}/?ts=${new Date().getTime()}`;
        }
      }

      // sipth01.b3networks.com, sipth02.b3networks.com
      if (this.endpoint?.startsWith('sipth')) {
        const prefix = this.endpoint.split('.')?.[0]?.replace('sipth', 'ct') || '';
        if (prefix) {
          return `wss://${`${this.domain}/_${prefix}`}${this.port ? ':' + this.port : ''}/?ts=${new Date().getTime()}`;
        }
      }
    }
    return this.endpoint ? `wss://${this.endpoint}${this.port ? ':' + this.port : ''}/?ts=${new Date().getTime()}` : '';
  }

  get sipAddress() {
    return `${this.endpoint}${this.port ? ':' + this.port : ''}`;
  }

  getSipAddress() {
    return `sip:${this.sipUsername.replace(':', '')}@${this.endpoint}${this.port ? ':' + this.port : ''}`;
  }

  // getSipAddressUserName() {
  //   return `sip:${this.username}@${this.endpoint}${this.port ? ':' + this.port : ''}`;
  // }
}

export enum UAEventStatus {
  connecting = 'connecting',
  connected = 'connected',
  registered = 'registered',

  // fail
  unregistered = 'unregistered',
  // handle reconnect
  disconnected = 'disconnected',
  registrationFailed = 'registrationFailed',
  registrationExpiring = 'registrationExpiring',

  // status call
  failed = 'failed'
}

export interface WebrtcState {
  statusUA: {
    status: UAEventStatus;
    reason?: string;
  };
  session: RTCSession;
  sessionsWaiting?: SessionManageState[];
  callManagement: CallManagement;
  ua: UA;
}

export interface SessionManageState {
  session: RTCSession;
  callManagement: CallManagement;
}

export interface CallManagement {
  isRemote: boolean;
  ringing: boolean;
  isHold: boolean;
  canHold: boolean;
  canDTMF: boolean;
  timerCall: TimerCall;
  member: UserDirectory | Contact;
  // display UI
  status: string;
  displayMember: Observable<string>;
  isRoom: boolean;
  isMute: boolean;
}

export class TimerCall {
  second = 0;
  private intervalTime: any;

  countTimeCall(callback?: Function) {
    this.second = 0;
    this.intervalTime = setInterval(() => {
      this.second++;

      if (callback) {
        callback();
      }
    }, 1000);
  }

  clearIntervalTime() {
    if (this.intervalTime) {
      clearInterval(this.intervalTime);
      this.intervalTime = null;
    }
  }
}

export enum Originator {
  LOCAL = 'local',
  REMOTE = 'remote',
  SYSTEM = 'system'
}

export enum SessionDirection {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing'
}

export interface SipResponse {
  username: string;
  password: string;
  fullUsername: string;
  domain: string;
  expiredAt: number;
}

export enum HEADER_EXTRA_ADDITIONAL {
  X_TAG_B3 = 'X-TAG-B3'
}

export interface OptionsAdditional {
  extraHeaders: string[];
}
