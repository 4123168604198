import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { MemberNewsfeed, MemberNewsfeedUI } from './member.model';

export interface MemberNewsfeedState extends EntityState<MemberNewsfeed> {}

export type MemberNewsfeedUIState = EntityState<MemberNewsfeedUI>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_newsfeed_member', idKey: 'newsfeedId' })
export class MemberNewsfeedStore extends EntityStore<MemberNewsfeedState> {
  override ui: EntityUIStore<MemberNewsfeedUIState>;

  constructor() {
    super();
    this.createUIStore({}, { deepFreezeFn: obj => obj }).setInitialEntityState(entity => <MemberNewsfeedUI>{});
  }
}
