<h1 mat-dialog-title>{{ isEnable2fa ? 'Enable' : 'Disable' }} two-factor authentication</h1>

<div mat-dialog-content>
  <div *ngIf="loading" class="flex justify-center">
    <mat-spinner [diameter]="40" [strokeWidth]="2"></mat-spinner>
  </div>

  <div *ngIf="!loading" class="flex flex-col">
    <ng-container *ngIf="isEnable2fa; else disable">
      <div class="flex justify-center">
        <qrcode [qrdata]="qrCode" [width]="150"></qrcode>
      </div>

      <ol class="pl-4">
        <li>
          <p>Set up an account by scanning the QR code or entering the following code into any Authenticator app</p>
          <h2 class="text-center mb-4">{{ qrSecret }}</h2>
        </li>
        <li>
          <p>Enter the given 6-digit code</p>
          <mat-form-field hideRequiredMarker="true" class="w-full">
            <mat-label>Verification code</mat-label>
            <input matInput [formControl]="verificationCodeCtr" required (paste)="onPaste($event)" />
            <mat-error *ngIf="verificationCodeCtr.invalid">{{ getVerificationCodeError() }}</mat-error>
          </mat-form-field>
        </li>
      </ol>
    </ng-container>
    <ng-template #disable>
      <div class="text-[#313338] mb-4">Enter the 6-digit code you received from your authenticator app</div>
      <mat-form-field hideRequiredMarker="true" class="w-full">
        <mat-label>Verification code</mat-label>
        <input matInput [formControl]="verificationCodeCtr" required (paste)="onPaste($event)" />
        <mat-error *ngIf="verificationCodeCtr.invalid">{{ getVerificationCodeError() }}</mat-error>
      </mat-form-field>
    </ng-template>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="verificationCodeCtr.invalid"
    [loading]="progressing"
    (click)="verify()"
  >
    Verify
  </button>
</div>
