import { Pipe, PipeTransform } from '@angular/core';
import { UserDirectoryQuery } from '@b3networks/api/directory';

@Pipe({
  name: 'userOnline',
  standalone: true
})
export class UserOnlinePipe implements PipeTransform {
  constructor(private userDirectoryQuery: UserDirectoryQuery) {}

  transform(userUuid: string) {
    return this.userDirectoryQuery.isOnline$(userUuid);
  }
}
