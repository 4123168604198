import { Pipe, PipeTransform } from '@angular/core';

// This pipe to handle title case but still keep acronyms capitalize title
// Ex: - isTitleCase = true:  'edit text to SMS' => 'Edit Text To SMS'
//     - isTitleCase = false:  'edit text to SMS' => 'Edit text to SMS'
@Pipe({
  name: 'acronymsCapitalizeCase',
  pure: false,
  standalone: true
})
export class AcronymsCapitalizeCasePipe implements PipeTransform {
  transform(input: string, isTitleCase?: boolean): string {
    let value = input.trim().split('_').join(' ');

    if (!value) {
      return '';
    } else {
      let valueUpdated = value.charAt(0).toUpperCase() + value.slice(1);
      let wordsStand = valueUpdated.split(' ');

      wordsStand = wordsStand.map((item, index) => {
        if (item !== item.toUpperCase() && index !== 0) {
          return isTitleCase ? item.charAt(0).toUpperCase() + item.substr(1).toLowerCase() : item.toLowerCase();
        }
        return item;
      });

      return wordsStand.join(' ');
    }
  }
}
