import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CustomerChatBox } from './livechat.model';
import { LiveChatStore } from './livechat.store';

@Injectable({ providedIn: 'root' })
export class LiveChatQuery extends Query<CustomerChatBox> {
  answers$ = this.select('answers');
  ui$ = this.select('ui');

  constructor(protected override store: LiveChatStore) {
    super(store);
  }

  get getUi() {
    return this.getValue().ui;
  }

  selectOpenView() {
    return this.select(state => state.ui.isOpenChat);
  }

  selectShowMoreWidget() {
    return this.select(state => state.ui.isShowMoreWidget);
  }

  selectViewChat() {
    return this.select(state => state.ui.viewChat);
  }
}
