import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TxnChannel } from '../txn/txn.model';
import { SourceChannelState, SourceChannelStore } from './sourceChannel.store';

@Injectable({ providedIn: 'root' })
export class SourceChannelQuery extends QueryEntity<SourceChannelState> {
  constructor(protected override store: SourceChannelStore) {
    super(store);
  }

  getDataFormTicket() {
    return this.getValue().dataFormTicket;
  }

  getSourceChannelByChannel(channel: TxnChannel) {
    return this.getAll({
      filterBy: sources => sources.channel === channel
    });
  }
}
