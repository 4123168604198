import { InfoNumber } from '@b3networks/api/license';

export class OrgLinkMember {
  acceptedAt: number;
  createdAt: number;
  leftAt: number;
  rejectedAt: number;
  logoUrl: string;
  name: string;
  role: string;
  shortName: string;
  uuid: string;

  constructor(obj?: Partial<OrgLinkMember>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class OrgLink {
  name: string;
  organizations: OrgLinkMember[];
  uuid: string;
  status?: 'Accepted' | 'Pending';
  isDefaultMemberAccepted: boolean;
  createdAt: number;

  constructor(obj?: Partial<OrgLink>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export enum ORG_CONNECT_STATUS {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  pending = 'PENDING',
  rejected = 'REJECTED'
}

export class OrgConnection {
  name: string;
  recipient: RecipientOrInitiator;
  uuid: string;
  status: ORG_CONNECT_STATUS;
  initiator: RecipientOrInitiator;
  createdAt: number;
  confirmedAt?: number;
  isRecipientOrg?: boolean;

  //ui
  myOrg: string;
  constructor(obj?: Partial<OrgConnection>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  withMyOrg(orgUuid: string) {
    this.myOrg = orgUuid;
    return this;
  }

  get anotherOrg() {
    return this.myOrg === this.initiator.uuid ? this.recipient : this.initiator;
  }
}

export class SharingResource {
  id: string;
  recipientOrgUuid: string;
  originatorOrgUuid: string;
  resourceType: string;
  resourceId: string;
  status: string;
  createdAt: number;
  resourceName?: string;
  updatedAt?: number;
  originatorOrgName?: string;
}

export class SharingResourceNumber {
  licenseId: number;
  key: string;
  info: InfoNumber;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  zoomNumber: boolean;
  extensionInfo: string;
}

export class ShareResourceInbox {
  type: string;
  id: string;
}

export class ResourceActive {
  uuid: string;
  name: string;
  active?: boolean;
}

export interface ShareResourceReq {
  recipientOrgUuid: string;
  resources?: ShareResourceInbox[];
  teamUuid?: string;
  originatorOrgUuid?: string;
}

export interface RecipientOrInitiator {
  logoUrl?: string;
  name: string;
  uuid: string;
  shortName: string;
}

export interface ViewOrgLink {
  key: RecipientOrInitiator;
  value: boolean;
}
