import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DomainConfig } from './domain-config.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'callcenter_domainConfig', cache: { ttl: 10 * 60 * 1000 } })
export class DomainConfigStore extends Store<DomainConfig> {
  constructor() {
    super(new DomainConfig());
  }

  updateWithCacheable(state: DomainConfig) {
    this.update(state);
    this.setHasCache(true);
  }
}
