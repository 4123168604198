import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from '@b3networks/api/common';
import { Channel } from '@b3networks/api/inbox';
import { X_B3_HEADER } from '@b3networks/shared/common';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrgConnectionStore } from './org-connection.store';
import {
  OrgConnection,
  OrgLink,
  ResourceActive,
  ShareResourceInbox,
  ShareResourceReq,
  SharingResource,
  SharingResourceNumber
} from './org-link.model';
import { OrgLinkStore } from './org-link.store';

export interface CreateGroupBody {
  organizationUuid: string;
  organizationGroupUuid?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrgLinkService {
  constructor(
    private http: HttpClient,
    private store: OrgLinkStore,
    private stortOrgConnection: OrgConnectionStore
  ) {}

  getGroups(userUuid?: string) {
    const headers = new HttpHeaders();
    if (userUuid) {
      headers.set(X_B3_HEADER.userUuid, userUuid);
    }

    return this.http.get<OrgLink[]>(`auth/private/v1/organizations/groups`, { headers }).pipe(
      tap(orgLink => {
        this.store.set(orgLink);
      })
    );
  }

  getInboxResourceShared(orgs, userUuid?: string, status?: string) {
    const headers = new HttpHeaders();
    if (userUuid) {
      headers.set(X_B3_HEADER.userUuid, userUuid);
    }

    let params = new HttpParams();
    params = params.set('originatorOrgUuid', orgs.originatorUuid);
    params = params.set('recipientOrgUuid', orgs.recipientUuid);
    if (status) {
      params = params.set('status', status);
    }

    return this.http.get<any[]>(`inbox/private/v1/resources/share/inbox`, { params, headers });
  }

  getInboxResource(orgUuid: string): Observable<ResourceActive[]> {
    const headers = new HttpHeaders();
    if (orgUuid) {
      headers.set(X_B3_HEADER.userUuid, orgUuid);
    }

    return this.http.get<any[]>(`inbox/private/v2/inboxes/supplier/${orgUuid}`, { headers }).pipe(
      map(res => {
        if (res.length) {
          const resource: ResourceActive[] = res.map(item => {
            return { uuid: item.inboxUuid, name: item.inboxName } as ResourceActive;
          });
          return resource;
        }
        return [];
      })
    );
  }

  getChannelResource(channel: string): Observable<Channel[]> {
    let params = new HttpParams();
    if (channel) params = params.set('channel', channel);
    return this.http.get<Channel[]>('inbox/private/v1/sourceChannel', { params }).pipe();
  }

  getNumbersResource(userUuid: string, pageable: Pageable): Observable<ResourceActive[]> {
    const headers = new HttpHeaders();
    if (userUuid) {
      headers.set(X_B3_HEADER.userUuid, userUuid);
    }

    let params = new HttpParams().set('includeResources', 'true');
    params = params.set('baseSkus', '624397d0-32ab-4cc3-a623-6fc72903bee6');
    params = params.set('addonSkus', '78f90911-e6ef-4c79-afaa-f554fd20f9c5');

    if (pageable) {
      params = params.set('page', pageable.page.toString());
      params = params.set('size', pageable.perPage.toString());
    }

    return this.http.get<any[]>(`license/private/v2/licenses/resources/available`, { params, headers }).pipe(
      map(res => {
        if (res.length) {
          const resources: ResourceActive[] = res.map(item => {
            return { uuid: item.id, name: item.resource?.key } as ResourceActive;
          });
          return resources.filter(item => item.name);
        }
        return [];
      })
    );
  }

  getListConnections(orgUuid: string) {
    return this.http.get<OrgConnection[]>(`auth/private/v1/connections`).pipe(
      map(res => res.map(item => new OrgConnection(item).withMyOrg(orgUuid))),
      map(res => {
        this.stortOrgConnection.setConnectedOrgs(res);
        this.stortOrgConnection.update({
          loaded: true
        });
        return res;
      })
    );
  }

  getSharingResourceOfConnection(userUuid: string, connectionUuid: string, type?: string, status?: string) {
    const headers = new HttpHeaders();
    if (userUuid) {
      headers.set(X_B3_HEADER.userUuid, userUuid);
    }

    let params = new HttpParams();
    if (type) {
      params = params.set('resourceType', type);
    }
    if (status) {
      params = params.set('status', status);
    }

    return this.http.get<SharingResource[]>(`auth/private/v1/connections/${connectionUuid}/resources`, {
      params,
      headers
    });
  }

  getResourceShareNumber(sharedOrgUuid: string, status: string) {
    return this.http.get<SharingResourceNumber[]>(`/license/private/v3/licenses/${status}/${sharedOrgUuid}`);
  }

  getResourceSharedInbox(recipientUuid: string, originatorUuid: string, status: string) {
    let params = new HttpParams();
    params = params.set('originatorOrgUuid', originatorUuid);
    params = params.set('recipientOrgUuid', recipientUuid);
    if (status) {
      params = params.set('status', status);
    }
    return this.http.get<SharingResource[]>(`inbox/private/v1/resources/share/inbox`, { params });
  }

  getResourceSharedTicketChannel(originatorUuid: string, recipientUuid: string, status: string) {
    let params = new HttpParams();
    params = params.set('originatorOrgUuid', originatorUuid);
    params = params.set('recipientOrgUuid', recipientUuid);
    if (status) {
      params = params.set('status', status);
    }
    return this.http.get<SharingResource[]>(`inbox/private/v1/resources/share/ticketChannel`, { params });
  }

  createConnection(userUuid: string, recipientOrgUuid: string) {
    const headers = new HttpHeaders().set(X_B3_HEADER.userUuid, userUuid);
    return this.http.post<OrgConnection>(
      `auth/private/v1/connections`,
      { recipientOrgUuid: recipientOrgUuid },
      { headers }
    );
  }

  createGroup(userUuid: string, body: CreateGroupBody) {
    const headers = new HttpHeaders().set(X_B3_HEADER.userUuid, userUuid);
    return this.http.post<OrgLink>(`auth/private/v1/organizations/groups`, body, { headers }).pipe(
      tap(res => {
        this.store.upsert(res.uuid, res);
      })
    );
  }

  acceptGroup(userUuid: string, groupUuid: string) {
    const headers = new HttpHeaders().set(X_B3_HEADER.userUuid, userUuid);
    return this.http.put<any>(`auth/private/v1/organizations/groups/${groupUuid}/accept`, {}, { headers });
  }

  denyGroup(userUuid: string, groupUuid: string) {
    const headers = new HttpHeaders().set(X_B3_HEADER.userUuid, userUuid);
    return this.http.put<any>(`auth/private/v1/organizations/groups/${groupUuid}/reject`, {}, { headers });
  }

  acceptConnection(userUuid: string, connectionUuid: string) {
    const headers = new HttpHeaders().set(X_B3_HEADER.userUuid, userUuid);
    return this.http.post<any>(`auth/private/v1/connections/${connectionUuid}/accept`, {}, { headers });
  }

  denyConnection(userUuid: string, connectionUuid: string) {
    const headers = new HttpHeaders().set(X_B3_HEADER.userUuid, userUuid);
    return this.http.post<any>(`auth/private/v1/connections/${connectionUuid}/reject`, {}, { headers });
  }

  shareResourceInbox(body: ShareResourceReq) {
    return this.http.post<any>(`inbox/private/v1/resources/share`, body);
  }

  shareResourceTicketChannel(body: ShareResourceReq) {
    return this.http.post<any>(`inbox/private/v1/resources/share/ticketChannel`, body);
  }

  shareResourceTeams(body: ShareResourceReq) {
    return this.http.post<any>(`auth/private/v1/organizations/teams/share`, body);
  }

  shareResourceNumber(licenseId: string, recipientOrgUuid: string) {
    return this.http.put<any>(`license/private/v3/licenses/${licenseId}/share`, { sharedOrgUuid: recipientOrgUuid });
  }

  revokeShareResourceInboxs(originatorOrgUuid: string, recipientOrgUuid: string, resources: ShareResourceInbox[]) {
    return this.http.post<void>(`inbox/private/v1/resources/revoke`, {
      originatorOrgUuid,
      recipientOrgUuid,
      resources: resources
    });
  }

  revokeShareResourceChannel(originatorOrgUuid: string, recipientOrgUuid: string, resources: ShareResourceInbox[]) {
    return this.http.post<void>(`inbox/private/v1/resources/revoke/ticketChannel`, {
      originatorOrgUuid,
      recipientOrgUuid,
      resources: resources
    });
  }

  revokeShareResourceNumber(id: string, sharedOrgUuid: string) {
    return this.http.put<void>(`/license/private/v3/licenses/${id}/revoke`, {
      sharedOrgUuid
    });
  }

  revokeShareResourceTeams(recipientOrgUuid: string, teamUuid: string) {
    return this.http.post<void>(`/auth/private/v1/organizations/teams/revoke`, {
      recipientOrgUuid,
      teamUuid
    });
  }
}
