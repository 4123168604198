import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IdentityProfileQuery } from '@b3networks/api/auth';
import { isSameDay, isSameYear } from 'date-fns';

//similar format to Gmail timestamp
// show only time if it is on the same day => HH:mm
// show date if the action and current timing is on different day
@Pipe({
  name: 'durationV5',
  pure: true,
  standalone: true
})
export class DurationV5Pipe extends DatePipe implements PipeTransform {
  constructor(private orgQuery: IdentityProfileQuery) {
    super('en-US', orgQuery.currentOrg?.utcOffset);
  }

  override transform(duration: any): any {
    if (!duration) duration = 0;
    const org = this.orgQuery.currentOrg;
    const inputDate = new Date(duration);
    const currentDate = new Date();

    if (isSameDay(inputDate, currentDate)) {
      // Format as time (hh:mm a) if the date is today
      return super.transform(inputDate, 'hh:mm a', org?.utcOffset);
    } else if (isSameYear(inputDate, currentDate)) {
      // Format as date (MMM d) if it's the same year but a different day
      return super.transform(inputDate, 'MMM d', org?.utcOffset);
    } else {
      // Format as full date (yyyy-MM-dd) if it's a different year
      return super.transform(inputDate, 'yyyy-MM-dd', org?.utcOffset);
    }
  }
}
