import { MetadataTxnWidget } from '@b3networks/api/auth';
import { TxnType } from '@b3networks/api/callcenter';
import { GroupType } from '@b3networks/api/chat';
import { Contact } from '@b3networks/api/contact';
import { User } from '@b3networks/api/workspace';
import { SearchBy } from '@b3networks/chat/shared/core';
import { startOfDay } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import { WORKING_HOURS_MODE } from '../form-data/form-data.model';
import { CaseActivity } from '../support-center/activities/activities.model';
import { WhatsappInfo } from './model/whatsapp.model';

// Create enum SystemMsgType to avoid importing enum SystemMsgType from module workspace leading to circular dependency between "api-txn" and "api-workspace"
export enum SystemMsgType {
  createComment = 'createComment',
  updateComment = 'updateComment',
  archived = 'archived',
  unarchived = 'unarchived'
}

export enum TxnChannel {
  livechat = 'livechat',
  whatsapp = 'whatsapp',
  sms = 'sms',
  email = 'email',
  supportCenter = 'supportCenter',
  call = 'call',
  INTERNAL_SPACE = 'INTERNAL_SPACE'
}

export class Txn {
  title: string;
  channel: TxnChannel;
  txnUuid: string;
  channelUuid: string;
  txnType: TxnType;
  createdAt: number;
  endedAt: number;
  lastAssignedAgents: string[]; // maping active txns , identity string
  unreadPublicConvo: number;
  unreadTeamConvo: number;
  metadata?: MetadataTxnWidget;
  status: TxnStatus;
  latestMsgInMilisecond: number;

  // inbox flow
  inboxUuid: string;
  publicConvoId: string;
  teamConvoId: string;
  customerUuid: string;
  customerName: string;
  customerChatUserId: string;
  severityId: number;
  typeId: number;
  productIds: number[];
  transcriptKey: string;
  assigningTo: string; // only assigning or transfering
  whatsapp?: WhatsappInfo;
  // support center
  caseInfo: CaseInfo;
  lastOwnerAssignedAgents: User;
  affectedOrgName: string;
  affectedOrgUuid: string;
  sid: number;
  //whatsapp
  failReason: string;
  //ms-data
  workingHourMode: WORKING_HOURS_MODE;
  // ui
  isTemporary: boolean;

  icon: string;
  iconName: string;
  isNoSvg: boolean;
  iconColor: string;
  iconInbox: string;

  statusColor: string;
  myOrgUuid: string;
  //watching
  watchers: string[];

  constructor(obj?: Partial<Txn>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj?.whatsapp) {
        this.whatsapp = new WhatsappInfo(obj.whatsapp);
      }

      if (obj?.caseInfo) {
        this.caseInfo = new CaseInfo(obj.caseInfo);
      }

      this.icon = this.generateIcon();
      this.statusColor = this.generateStatusColor();
    }
  }

  withOrgUuid(orgUuid: string) {
    this.myOrgUuid = orgUuid;
    return this;
  }

  get totalUnread() {
    return (this.unreadPublicConvo || 0) + (this.unreadTeamConvo || 0);
  }

  get allowActionDisplay() {
    return !!this.inboxUuid;
  }

  get isOrgHandler() {
    return this.caseInfo ? this.myOrgUuid === this.caseInfo.ownerOrgUuid : false;
  }

  get isOrgCreator() {
    return this.caseInfo ? this.myOrgUuid === this.caseInfo.createdByOrg : false;
  }

  get ishandleByBot() {
    return this.lastAssignedAgents?.includes('8f5fbd1c-0e00-11eb-adc1-0242ac120002');
  }

  get displayStatus() {
    return this.status === TxnStatus.ended ? 'Completed' : this.status;
  }

  get isClosed() {
    return this.status === TxnStatus.ended;
  }

  get isClosedV2() {
    return [TxnStatus.ended, TxnStatus.failed].includes(this.status);
  }

  get groupType() {
    return this.channel === TxnChannel.livechat
      ? GroupType.LIVECHAT
      : this.channel === TxnChannel.whatsapp
        ? GroupType.WhatsApp
        : this.channel === TxnChannel.call
          ? GroupType.call
          : GroupType.SupportCenter;
  }

  get channelDisplay(): string {
    switch (this.channel) {
      case TxnChannel.call:
        return 'Call';
      case TxnChannel.livechat:
        return 'Live chat';
      case TxnChannel.supportCenter:
        return 'Support ticket';
      case TxnChannel.email:
        return 'Email';
      case TxnChannel.sms:
        return 'SMS';
      case TxnChannel.whatsapp:
        return 'WhatsApp';
      default:
        return this.channel;
    }
  }

  get statusDisplay() {
    switch (this.status) {
      case TxnStatus.ended:
        return 'Ended';
      case TxnStatus.waiting:
        return 'Waiting';
      case TxnStatus.assigning:
        return 'Assigning';
      case TxnStatus.assigned:
        return 'Assigned';
      case TxnStatus.transferring:
        return 'Transferring';
      case TxnStatus.failed:
        return 'Failed';
      default:
        return this.status;
    }
  }

  private generateIcon() {
    switch (this.channel) {
      case TxnChannel.livechat:
        this.isNoSvg = false;
        this.iconName = 'Live chat';
        this.iconColor = 'i-blue';
        this.iconInbox = 'b3n_inbox_livechat';
        return 'livechat';
      case TxnChannel.supportCenter:
        this.isNoSvg = false;
        this.iconName = 'Support ticket';
        this.iconColor = 'i-purple';
        this.iconInbox = 'b3n_inbox_ticket';
        return 'b3n_txn_ticket';
      case TxnChannel.call:
        this.iconName = 'Call';
        this.isNoSvg = false;
        this.iconColor = 'i-orange';
        this.iconInbox = 'b3n_inbox_call';
        return 'b3n_call';
      case TxnChannel.whatsapp:
        this.isNoSvg = false;
        this.iconName = 'WhatsApp';
        this.iconColor = 'i-green';
        this.iconInbox = 'b3n_inbox_whatsapp';
        return 'whatsapp';
      case 'email':
        this.isNoSvg = false;
        this.iconName = 'Mail';
        this.iconColor = 'i-navi';
        this.iconInbox = 'b3n_inbox_mail';
        return 'email';
      default:
        this.isNoSvg = false;
        this.iconName = '';
        this.iconColor = 'i-blue';
        this.iconInbox = 'b3n_inbox_livechat';
        return 'livechat';
    }
  }

  private generateStatusColor() {
    if (this.channel === TxnChannel.supportCenter) {
      switch (this.status) {
        case TxnStatus.ended:
          return 'green-color';
        default:
          return 'orange-color';
      }
    } else {
      switch (this.status) {
        case TxnStatus.assigned:
          return 'blue-color';
        case TxnStatus.ended:
          return 'green-color';
        case TxnStatus.failed:
          return 'red-color';
        default:
          return 'orange-color';
      }
    }
  }
}

// support center
export class CaseInfo {
  id: number;
  sid: number;
  accessControlId: string;

  ownerOrgUuid: string;
  ownerOrgPhoto: string;
  ownerOrgName: string;

  createdByIdentity: string;
  createdByIdentityName: string;
  createdByOrg: string;
  createdByOrgName: string;

  srcDomain?: string;
  srcOrgUuid?: string;

  title: string;
  dueAt: number;
  description: string;

  severityId: number;
  typeId: number;
  productIds: number[];

  relatedCases: RelatedCase[];
  activities: CaseActivity[];
  watchers: string[];

  // for case query api
  txnUuid: string;

  //ui
  newDescription: string;

  constructor(obj?: Partial<CaseInfo>) {
    if (obj) {
      Object.assign(this, obj);
      if (obj['orgUuid'] && !this.ownerOrgUuid) {
        this.ownerOrgUuid = obj['orgUuid']; // fallback for querying api
        this.ownerOrgName = obj['orgName'];
        this.ownerOrgPhoto = obj['orgPhoto'];
      }
    }
  }

  get isReachedDueDate() {
    return differenceInDays(startOfDay(new Date()), startOfDay(new Date(this.dueAt))) > 0;
  }
}

export class QueryCaseResp {
  countOpen: number;
  countClosed: number;
  countAll: number;
  items: CaseInfo[];

  constructor(obj?: Partial<QueryCaseResp>) {
    if (obj) {
      Object.assign(this, obj);
      this.items = (obj.items || []).map(i => new CaseInfo(i));
    }
  }
}
export interface CreateCaseCommentReq {
  description?: string;
  mentionIds?: string[];
  descriptionRaw?: string;
  formattedType?: 'html';
  commentId?: number;
  action?: ActionComment;
}

export interface RelatedCase {
  sid: number;
  orgUuid: string;
  title: string;
  txnUuid: string;
}

export interface AssignLeftReq {
  txnUuid: string;
  agentUuid: string; // support for usecase that supervisor kick someone
}

export interface RespActivePendingTxn {
  txns: Txn[];
  contacts: Contact[];
}

export interface RespDetailTxn {
  txn: Txn;
  contact: Contact;
}

export interface RequestGetAllTxns {
  customerUuid: string;
  type: 'call' | 'chat';
  channel: TxnChannel;
  page?: number;
  perPage?: number;
}

export interface RequestFilterTxns {
  status: TxnStatus;
  inboxUuid?: string; // optional
  assignedMode: AssignedMode;
  groupBy: TxnGroupBy;
}

export interface RequestFilterTxnsV2 {
  assignedMode?: AssignedMode;
  statuses: TxnStatus[];
  inboxUuids?: string[]; // optional
  channels?: TxnChannel[]; // optional
  customerUuid?: string;
  groupBy: TxnGroupBy;
  viewMode?: ViewMode;
}

export interface RequestFilterTxnsV4 {
  statuses: TxnStatus[];
  inboxUuids: string[];
  channels: TxnChannel[];
  viewMode: ViewMode;

  textSearch: string;
  searchBy: SearchBy;

  reporters: string[];
  assignees: string[];
  unassignedInbox: boolean;
}

export interface ReqTxnActiveByMeHasUnreadCount {
  statuses: TxnStatus[];
  channels: TxnChannel[];
}

export interface ResponseTxnActiveByMeHasUnreadCount {
  createdByMe: Txn[];
  watcher: Txn[];
  assigned: Txn[];
  mention: Txn[];
}

export interface RequestFilterCustomerTxns {
  customerUuid: string;
  status: string;
  inboxUuid: string;

  page: number;
  perPage: number;
}

export interface RequestFilterCustomerTxnsV2 {
  customerUuid: string;
  inboxUuids: string[];
  channels: TxnChannel[];
  assignees: string[];
  statuses: TxnStatus[];
  reporters: string[];
  textSearch: string;
  sortField?: string; // created_at
  sortOrder?: 'ASC' | 'DESC'; // asc,desc
  viewMode?: ViewMode; //query for sc channel
  searchBy?: SearchBy;
  createdByIdentity?: string;
}

export enum TxnStatus {
  // pending = 'pending',
  waiting = 'waiting',
  assigning = 'assigning',
  assigned = 'assigned',
  transferring = 'transferring',
  ended = 'ended',
  failed = 'failed', // ended

  //v2
  open = 'open',
  close = 'close'
}

export enum AssignedMode {
  me = 'me', // assigned to me
  others = 'others', // assigned to others
  all = 'all'
}

export enum TxnGroupBy {
  txn = 'txn',
  customer = 'customer'
}

export enum ActionTxn {
  _requestAssign = '_requestAssign',
  _rejectAssign = '_rejectAssign',
  _acceptAssign = '_acceptAssign'
}

export interface RequestActionTxn {
  agentUuid: string; // for requestAssign
}

export interface RequestUpdateTxns {
  typeId?: number;
  severityId?: number;
  addProductIds?: number[];
  removeProductIds?: number[];
  //additional request field
  caseInfo?: CaseUpdateRequest;
  title?: string;
}

export interface CaseUpdateRequest {
  title: string;
  description: string;
  rawDescription: string;
  srcDomain: string;
  srcOrgUuid: string;
  dueDate: number;
  mentionIds: string[];
}

export interface RequestUploadFileTxn {
  tmpKey: string; // s3Key
  channel: TxnChannel;
}

export enum ViewMode {
  assigned = 'assigned', // assign to me
  createdByMe = 'createdByMe', // created by me
  submittedTicket = 'submittedTicket', // only sc channel
  watcher = 'watcher', // watching
  mention = 'mention' // related to me
}

export enum WatcherMode {
  me = 'me'
}

export enum MentionMode {
  me = 'me'
}

export class ReqSendMsgInbox {
  chatMsgData?: string;
  channelMsgData?: string;
  channelMsgDataObj?: PostCommentRequest;
  actorUuid?: string;
  actorRole?: ActorRole;
}

export interface PostCommentRequest {
  formattedType: string;
  description: string;
  descriptionRaw: string;
  mentionIds: string[];
  action: ActionComment;
  commentId?: number;
  msgId?: string;
}

export enum ActorRole {
  agent = 'agent',
  customer = 'customer'
}

export enum ActionComment {
  create = 'create',
  update = 'update',
  delete = 'delete'
}

export interface ResponseTokenInbox {
  token: string;
}

export interface RequestFilterUniversalSearch {
  inboxUuids: string[];
  keyword: string;
  statuses: TxnStatus[];
  createdByUuids: string[];
}

// export enum TxnStatus {
//   open = 'open',
//   close = 'close'
// }
