import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ChatWidgetV2 } from './chat-widget.model';

export interface ChatWidgetState extends EntityState<ChatWidgetV2>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox-chat-widget', idKey: 'uuid' })
export class ChatWidgetStore extends EntityStore<ChatWidgetState> {
  constructor() {
    super();
  }
}
