import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { AssignNumberToInboxReq, VerifiedWABA, VerifiedWABAReq, WhatsappNumbers } from './wa-numbers.model';
import { WaNumbersQuery } from './wa-numbers.query';
import { WaNumbersStore } from './wa-numbers.store';

@Injectable({
  providedIn: 'root'
})
export class WaNumbersService {
  constructor(private http: HttpClient, private store: WaNumbersStore, private query: WaNumbersQuery) {}

  getListWhatsappNumbers() {
    return this.http.get<WhatsappNumbers[]>(`inbox/private/v1/whatsapp/numbers`).pipe(
      map(res => {
        return res?.map(data => new WhatsappNumbers(data));
      }),
      tap(list => {
        this.store.upsertMany(list, { baseClass: WhatsappNumbers });
      })
    );
  }

  getVerifyWABA() {
    return this.http.get<VerifiedWABA>(`inbox/private/v1/whatsapp/verified-waba`);
  }

  verifyWABA(req: VerifiedWABAReq) {
    return this.http.post<void>(`inbox/private/v1/whatsapp/verified-waba`, req);
  }

  assignWhatsappNumberToInbox(req: AssignNumberToInboxReq) {
    return this.http.post<void>(`inbox/private/v1/whatsapp/numbers`, req);
  }

  unAssignWhatsappNumberToInbox(phoneNumberId: string) {
    return this.http.post<void>(`inbox/private/v1/whatsapp/numbers/${phoneNumberId}:unassign`, {});
  }

  deleteVerifiedWABA() {
    return this.http.delete<void>('inbox/private/v1/whatsapp/verified-waba');
  }

  removePhoneNumberToStore(phoneNumberId: string) {
    this.store.remove(phoneNumberId);
  }
}
