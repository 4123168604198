import { Contact } from '@b3networks/api/contact';
import { SYSTEM_IDENTITY } from '@b3networks/shared/common';
import { TxnChannel } from '../txn/txn.model';

export class Notification {
  id: number;
  txnUuid: string;
  channel: TxnChannel;
  type: NotificationType;
  createdAt: number;
  customer: Contact;
  inboxUuid: string;

  triggeredByUuid: string;
  triggeredByName: string;
  triggeredByPhotoUrl: string;

  // only type === assigning
  affectedUuid: string;
  affectedName: string;
  affectedPhotoUrl: string;

  expiryTime: number;
  isRead: boolean;
  isClicked: boolean;

  //only type = rejectAssign
  assignerUuid: string;
  assigneeUuid: string;

  // ui
  meIdentity: string;
  duration: number;

  constructor(obj: Partial<Notification>) {
    if (obj) {
      Object.assign(this, obj);
      if ([NotificationType.transferring, NotificationType.assigning].includes(this.type)) {
        this.duration = this.remainTime / 1000;
      }
    }
  }

  withMe(meIdentity: string) {
    this.meIdentity = meIdentity;
    return this;
  }

  get isTriggerred() {
    return this.meIdentity === this.triggeredByUuid;
  }

  get isAffected() {
    return this.meIdentity === this.affectedUuid;
  }

  get isRejectAssignByAssigner() {
    return this.triggeredByUuid === this.assignerUuid;
  }

  get remainTime() {
    return this.expiryTime > 0 ? Math.floor(this.expiryTime - Date.now()) : null;
  }

  get isValidNotify() {
    return [NotificationType.assigning, NotificationType.transferring].includes(this.type) && this.remainTime > 0;
  }

  get backgroundColor() {
    switch (this.type) {
      case NotificationType.assigningTimeout:
      case NotificationType.rejectAssign:
        return 'warning';
      case NotificationType.accepted:
        return 'success';
    }
    return null;
  }

  get isShowNotifyBrowser() {
    switch (this.type) {
      case NotificationType.assigning: {
        switch (this.channel) {
          case TxnChannel.call:
          case TxnChannel.livechat:
          case TxnChannel.whatsapp:
            return this.meIdentity === this.affectedUuid || this.meIdentity === this.triggeredByUuid;

          default:
            return false;
        }
      }

      case NotificationType.transferring: {
        switch (this.channel) {
          case TxnChannel.call:
          case TxnChannel.livechat:
          case TxnChannel.whatsapp:
            return this.meIdentity === this.affectedUuid || this.meIdentity === this.triggeredByUuid;

          default:
            return false;
        }
      }

      case NotificationType.accepted:
      case NotificationType.rejectAssign:
        return this.channel === TxnChannel.call || this.meIdentity !== this.triggeredByUuid;

      case NotificationType.assigningTimeout: {
        switch (this.channel) {
          case TxnChannel.livechat:
          case TxnChannel.whatsapp:
            return (
              this.meIdentity === this.triggeredByUuid ||
              this.triggeredByUuid === SYSTEM_IDENTITY ||
              this.meIdentity === this.affectedUuid
            );

          default:
            return false;
        }
      }

      default:
        return false;
    }
  }

  get displayText() {
    switch (this.type) {
      case NotificationType.assigning: {
        if (this.channel === TxnChannel.call)
          return this.meIdentity === this.affectedUuid
            ? `Incoming call from ${this.customer?.numbers?.[0].number}`
            : '';
        if (this.meIdentity === this.triggeredByUuid) return `Assigning to ${this.affectedName}`;
        if (this.triggeredByUuid === SYSTEM_IDENTITY) return `A chat has been assigned to you`;
        // return `${this.triggeredByName} assigned a live chat to you`;
        return `A chat has been assigned to you`;
      }

      case NotificationType.transferring: {
        if (this.channel === TxnChannel.call)
          return this.meIdentity === this.affectedUuid
            ? `Incoming call from ${this.customer?.numbers?.[0].number}`
            : '';
        if (this.meIdentity === this.triggeredByUuid) return `Transferring to ${this.affectedName}`;
        if (this.triggeredByUuid === SYSTEM_IDENTITY) return `A chat has been transferred to you`;
        // return `${this.triggeredByName} transferred a live chat to you`;
        return `A chat has been transferred to you`;
      }

      case NotificationType.assigningTimeout: {
        if (this.meIdentity === this.affectedUuid) return 'Your assignment expired';
        if (this.meIdentity === this.triggeredByUuid || this.triggeredByUuid === SYSTEM_IDENTITY)
          return `Your assignment request to ${this.affectedName} expired!`;
        return '';
      }

      case NotificationType.rejectAssign:
        return this.channel === TxnChannel.call || this.meIdentity === this.triggeredByUuid
          ? `Rejected successfully`
          : `Cancelled by ${this.triggeredByName}`;

      case NotificationType.accepted: {
        // if (this.meIdentity === this.triggeredByUuid) {
        //   return `You accepted your assignment request!`;
        // }
        // return `${this.triggeredByName} accepted your assignment request!`;
        return `Assigned successfully`;
      }

      default:
        return '';
    }
  }
}

export enum NotificationType {
  assigning = 'assigning',
  rejectAssign = 'rejectAssign',
  accepted = 'accepted',
  assigningTimeout = 'assigningTimeout',
  transferring = 'transferring',
  selfAssigned = 'selfAssigned'
}

export interface ResponseNotifications {
  contacts: Contact[];
  notifications: Notification[];
}

export interface QueryNotificationReq {
  afterExpiryTime: number; // now
  isClicked: boolean;
}

export const InboxNotificationStoreName = 'inbox-notifications';
