export enum LicenseFeatureCode {
  // base code
  license_base = 'license_base',
  developer = 'license_base_developer',
  call_group = 'license_extension_group',
  extension = 'extension',
  auto_attendant = 'license_ivr_enabled',
  license_sip = 'license_sip',
  license_byoc = 'license_byoc',
  license_sip_byop = 'license_sip_byop',
  license_fax = 'license_fax',
  license_hot_desking = 'license_hot_desking',
  license_file_explorer = 'file_explorer',
  license_portal = 'license_portal',
  license_base_sim = 'license_base_sim',
  license_power_sip_trunk = 'license_power_sip_trunk',
  license_base_homephone = 'license_base_homephone',
  license_simple_sbc = 'license_simple_sbc',

  //add-ons code
  team_chat = 'team_chat',
  license_livechat = 'license_livechat',
  microsoft_team = 'device_ms_team',
  license_business_actions = 'license_business_actions',
  ms_team = 'device_ms_team',
  ms_team_operator_connect = 'ms_teams_operator_connect',
  ip_phone = 'device_ip_phone',
  dnc = 'license_dnc_checking_enabled',
  number = 'license_number',
  cr = 'call_recording',
  cr_unlimited = 'license_call_recording_unlimited_enabled',
  phone_desktop = 'phone_desktop',
  browser_device = 'device_web_rtc',
  license_center_supervisor = 'license_center_supervisor',
  license_call_center_enabled = 'license_call_center_enabled',
  license_center_agent = 'license_center_agent',
  license_campaign = 'license_campaign',
  sim = 'device_sim',
  license_sms_campaign = 'license_sms_campaign',
  license_bulk_filter = 'license_bulk_filter',
  license_dpo = 'license_dpo',
  license_smpp = 'license_smpp',
  license_sip_ip_authen = 'license_sip_ip_authen',
  license_sip_ha = 'license_sip_ha',
  license_concurrent_call = 'license_concurrent_call',
  license_personal_whitelist = 'license_personal_whitelist',
  license_whatsapp = 'license_whatsapp',
  license_support_center = 'license_support_center',
  license_email = 'license_email',
  license_zoom = 'license_zoom',
  zoom_operator = 'zoom_operator',
  license_zoom_cloud_resource = 'license_zoom_cloud_resource',
  license_ms_team_resource = 'license_ms_team_resource',
  carrier_connect = 'carrier_connect',
  license_mvr = 'license_mvr'
}

export const SKUPhoneSystem = '624397d0-32ab-4cc3-a623-6fc72903bee6';
export const SKUMVR = '433fa60e-5e26-4a92-8d41-60f63fd0c97a';
