import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Role } from '@b3networks/api/auth';

export interface RoleState extends EntityState<Role> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'auth_role',
  idKey: 'uuid'
})
export class RoleStore extends EntityStore<RoleState> {
  constructor() {
    super();
  }
}
