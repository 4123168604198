import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CallerIdDelegate } from './allowed-caller-id.model';

export interface AllowedCallerIdsState extends EntityState<CallerIdDelegate>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'callcenter_allowed_callerIds', idKey: 'key' })
export class AllowedCallerIdsStore extends EntityStore<AllowedCallerIdsState> {
  constructor() {
    super();
  }
}
