import { Injectable } from '@angular/core';
import { EntityUIQuery, ID, QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';
import { MemberNewsfeed, MemberNewsfeedUI } from './member.model';
import { MemberNewsfeedState, MemberNewsfeedStore, MemberNewsfeedUIState } from './member.store';

@Injectable({ providedIn: 'root' })
export class MemberNewsfeedQuery extends QueryEntity<MemberNewsfeedState> {
  override ui: EntityUIQuery<MemberNewsfeedUIState>;

  constructor(protected override store: MemberNewsfeedStore) {
    super(store);
    this.createUIQuery();
  }

  getItem(feedId: string) {
    return this.getEntity(feedId);
  }

  selectItem(feedId: string) {
    return this.selectEntity(feedId);
  }

  selectPropertyChannel<K extends keyof MemberNewsfeed>(id: string, property: K) {
    return this.selectEntity(id, property);
  }

  selectUIState<K extends keyof MemberNewsfeedUI>(id: string | ID, property: K) {
    return this.ui.selectEntity(id, property);
  }

  selectListPublishers$(feedId: string) {
    return this.selectEntity(feedId, 'participant').pipe(map(x => x?.filter(a => a.isPublisher) || []));
  }

  getListPublishers(feedId: string) {
    return this.getEntity(feedId)?.participant?.filter(a => a.isPublisher) || [];
  }

  selectListSubscribers$(feedId: string) {
    return this.selectEntity(feedId, 'participant').pipe(map(x => x?.filter(a => a.isSubscriber) || []));
  }

  getListSubscribers(feedId: string) {
    return this.getEntity(feedId)?.participant?.filter(a => a.isSubscriber) || [];
  }
}
