import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Inject, Injectable } from '@angular/core';
import { guid } from '@datorama/akita';
import { ListToastsComponent } from '../component/list-toasts/list-toasts.component';
import { TOAST_CONFIG_TOKEN, ToastConfig, ToastData } from '../config/toast-configs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  componentRef: ComponentRef<ListToastsComponent>;

  constructor(
    private overlay: Overlay,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
  ) {}

  updateToast(data: ToastData) {
    if (this.componentRef && data.guid) {
      const findIndex = this.componentRef.instance.list.findIndex(x => x.guid === data.guid);
      if (findIndex > -1) {
        this.componentRef.instance.list[findIndex] = data;
        this.componentRef.instance.cdr.detectChanges();
      }
    }
  }

  removeToast(guid: string) {
    if (this.componentRef && guid) {
      this.componentRef.instance.list = this.componentRef.instance.list.filter(x => x.guid !== guid);
      this.componentRef.instance.cdr.detectChanges();

      if (this.componentRef.instance.list?.length === 0) {
        this.componentRef?.destroy();
        this.componentRef = null;
      }
    }
  }

  addToast(data: ToastData) {
    if (!data.time) {
      data.time = Date.now();
    }
    this.insertOne(data);
    return data;
  }

  removeAll() {
    if (this.componentRef) {
      this.componentRef.instance.list = [];
      this.componentRef.instance.cdr.detectChanges();

      if (this.componentRef.instance.list?.length === 0) {
        this.componentRef?.destroy();
        this.componentRef = null;
      }
    }
  }

  success(message: string, duration?: number) {
    const data = new ToastData({
      guid: guid(),
      message: message,
      type: 'success',
      duration: duration || 3000,
      time: Date.now()
    });

    this.insertOne(data);
    return data;
  }

  warning(message: string, duration?: number) {
    const data = new ToastData({
      guid: guid(),
      message: message,
      type: 'warning',
      duration: duration || 3000,
      time: Date.now()
    });

    this.insertOne(data);
    return data;
  }

  error(message: string, duration?: number) {
    const data = new ToastData({
      guid: guid(),
      message: message,
      type: 'error',
      duration: duration || 5000,
      time: Date.now()
    });

    this.insertOne(data);
    return data;
  }

  private insertOne(data: ToastData) {
    if (!this.componentRef) {
      const overlayRef = this.createOverlay();
      const componentPortal = new ComponentPortal(ListToastsComponent);
      this.componentRef = overlayRef.attach(componentPortal);
    }

    this.componentRef.instance.list = [...this.componentRef.instance.list, data];
    this.componentRef.instance.cdr.detectChanges();
  }

  private insertMany(data: ToastData[]) {
    if (!this.componentRef) {
      const overlayRef = this.createOverlay();
      const componentPortal = new ComponentPortal(ListToastsComponent);
      this.componentRef = overlayRef.attach(componentPortal);
    }

    if (this.componentRef) {
      this.componentRef.instance.list = [...this.componentRef.instance.list, ...data];
    } else {
      this.componentRef.instance.list = data;
    }
    this.componentRef.instance.cdr.detectChanges();
  }

  private createOverlay() {
    const positionStrategy = this.getPositionStrategy();
    return this.overlay.create({ positionStrategy });
  }

  private getPositionStrategy() {
    return this.overlay
      .position()
      .global()
      .top((this.toastConfig && this.toastConfig.position ? this.toastConfig.position.top : 20) + 'px')
      .right((this.toastConfig && this.toastConfig.position ? this.toastConfig.position.right : 20) + 'px');
  }
}
