import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedAuthModule } from '@b3networks/shared/auth';
import { SharedCommonModule } from '@b3networks/shared/common';
import { ButtonLoadingModule, SharedUiMaterialModule } from '@b3networks/shared/ui/material';
import { OrgLinkDetailsComponent } from './org-link-details/org-link-details.component';
import { ShareResourceDialogComponent } from './org-link-details/share-resource-dialog/share-resource-dialog.component';
import { OrgLinkInviteComponent } from './org-link-invite/org-link-invite.component';
import { OrgLinkComponent } from './org-link.component';

const routes: Routes = [
  { path: '', component: OrgLinkComponent },
  { path: ':uuid', component: OrgLinkDetailsComponent }
];

@NgModule({
  declarations: [OrgLinkComponent, OrgLinkDetailsComponent, OrgLinkInviteComponent, ShareResourceDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    SharedUiMaterialModule,
    ButtonLoadingModule,
    SharedAuthModule,
    SharedCommonModule
  ]
})
export class PortalOrgFeatureOrgLinkModule {}
