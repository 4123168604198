import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TfaInfo, TfaService } from '@b3networks/api/auth';
import { AuthenticatorAppComponent } from './authenticator-app/authenticator-app.component';
import { RecoveryKeyComponent } from './recovery-key/recovery-key.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ToastService } from '@b3networks/shared/ui/toast';

enum TypeTFA {
  email = 'email',
  authenticator = 'authenticator'
}

export interface EnableTwofaV2Data {
  email: string;
  tfaInfo: TfaInfo;
  mobileNumber: string;
}

@Component({
  selector: 'b3n-enable-twofa-v2',
  templateUrl: './enable-twofa-v2.component.html',
  styleUrls: ['./enable-twofa-v2.component.scss']
})
export class EnableTwofaV2Component implements OnInit {
  readonly TypeTFA = TypeTFA;
  selectType: TypeTFA;
  doStatus: 'Enable' | 'Disable';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnableTwofaV2Data,
    private dialogRef: MatDialogRef<EnableTwofaV2Component>,
    private dialog: MatDialog,
    private toastService: ToastService,
    private tfaService: TfaService
  ) {}

  ngOnInit() {
    this.doStatus = this.data?.tfaInfo?.tfaEnabled ? 'Disable' : 'Enable';
  }

  continue() {
    this.dialogRef.close(false);
    switch (this.selectType) {
      case TypeTFA.email:
        this.dialog
          .open(VerifyEmailComponent, { width: '560px', disableClose: true, autoFocus: false, data: this.data })
          .afterClosed()
          .subscribe((key: string) => {
            if (key) {
              this.viewRecoveryKey(key);
            }
          });
        break;
      case TypeTFA.authenticator:
        this.dialog
          .open(AuthenticatorAppComponent, {
            width: '440px',
            disableClose: true,
            autoFocus: false,
            data: this.data
          })
          .afterClosed()
          .subscribe((key: string) => {
            if (key) {
              this.viewRecoveryKey(key);
            }
          });
        break;
    }
  }

  viewRecoveryKey(key: string) {
    this.dialog
      .open(RecoveryKeyComponent, {
        width: '560px',
        disableClose: true,
        data: key
      })
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close(true);
        this.tfaService.get2FaInfo().subscribe();
      });
  }
}
