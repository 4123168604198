export const API_RUN_AT_BACKDOOR = [
  '/file/private/v3/assets',
  '/file/private/v3/files',
  '/file/private/v3/thumbnail',
  '/file/private/v3/temp',
  '/file/private/v3/zip',
  '/file/public/v3/files',
  '/file/public/v3/thumbnail',
  '/file/public/v3/assetThumbnail',
  '/file/uploadToken',
  '/file/assetUploadToken',
  '/file/public/file/jwt'
];

export const PRIVATE_FILE_PREFIX = '/file/private';
export const PUBLIC_FILE_PREFIX = '/file/public';
