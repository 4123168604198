import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IdentityProfileQuery,
  OrgLinkMember,
  OrgLinkService,
  ResourceActive,
  SharingResource,
  SharingResourceNumber,
  TeamService
} from '@b3networks/api/auth';
import { TxnChannel } from '@b3networks/api/inbox';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { ConfirmDialogComponent, ConfirmDialogInput } from '@b3networks/shared/ui/confirm-dialog';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, combineLatest, debounceTime, finalize, startWith, takeUntil } from 'rxjs';
import { API_TYPE_CONNECT, RESOURCE_CONNECT, ROUTE_LINK } from '../shared/constants';
import { ResourceData, ShareResourceDialogComponent } from './share-resource-dialog/share-resource-dialog.component';

export interface OrgLinkDetailsData {
  orgLinkMembers: OrgLinkMember[];
  profileUuid: string;
  linkUuid: string;
}

export class FilterData {
  view?: string;
  status?: string;
  search?: string;
}

export enum VIEW {
  shared = 'shared',
  received = 'received'
}

@Component({
  selector: 'b3n-org-link-details',
  templateUrl: './org-link-details.component.html',
  styleUrls: ['./org-link-details.component.scss']
})
export class OrgLinkDetailsComponent extends DestroySubscriberComponent implements OnInit {
  readonly listTabs: string[] = [
    // RESOURCE_CONNECT.dnc, TODO: Extra thereafter
    // RESOURCE_CONNECT.inbox,
    RESOURCE_CONNECT.channel,
    // RESOURCE_CONNECT.numbers, B3-14955
    RESOURCE_CONNECT.teams
  ];
  readonly resourceType = RESOURCE_CONNECT;
  readonly view_resource = VIEW;
  displayedColumns: string[] = ['uuid', 'resource', 'created', 'status', 'action'];
  dataSource: MatTableDataSource<SharingResource | SharingResourceNumber>;
  resourceCurrentOrg: ResourceActive[] = [];
  resourceCurrentOrgFiltered: ResourceActive[] = [];
  resourceRecipientOrg: ResourceActive[] = [];
  filterData = new FilterData();
  form: UntypedFormGroup;
  loading = false;
  pageSize = 5;
  orgName: string;
  orgs = {
    recipientUuid: '',
    originatorUuid: ''
  };
  currentIndexTab = 0;
  currentTab: string = this.listTabs[0];
  currentPage: number;
  cloneDataResource: SharingResource[];
  cloneDataResourceNumber: SharingResourceNumber[];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  get viewResourceCtrl() {
    return this.form.controls['view'] as FormControl;
  }

  get searchResourceCtrl() {
    return this.form.controls['search'] as FormControl;
  }

  get status() {
    return this.form.controls['status'] as FormControl;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private orgLinkService: OrgLinkService,
    private teamService: TeamService,
    private identityProfileQuery: IdentityProfileQuery
  ) {
    super();
  }

  ngOnInit(): void {
    this.currentPage = 0;
    this.orgName = this.route.snapshot.params['name'];
    this.orgs.recipientUuid = this.route.snapshot.params['recipientOrg'];
    this.orgs.originatorUuid = this.route.snapshot.params['initiatorOrg'];
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      view: [VIEW.shared],
      status: [''],
      search: ['']
    });

    this._filterChanged();
  }

  goBack() {
    this.router.navigate([ROUTE_LINK.org_link]);
  }

  tabChange(key: MatTabChangeEvent) {
    this.currentPage = 0;
    this.resourceCurrentOrg = [];
    this.resourceRecipientOrg = [];
    this.currentIndexTab = key.index;
    this.currentTab = key.tab.textLabel === 'Support ticket channel' ? RESOURCE_CONNECT.channel : key.tab.textLabel;
    this.initForm();
  }

  loadResource(key: string) {
    this.loading = true;
    switch (key) {
      // TODO: Implement DNC
      // case RESOURCE_CONNECT.dnc:
      //   break;
      // case RESOURCE_CONNECT.inbox:
      //   this._getInboxResource(this.identityProfileQuery.currentOrg.orgUuid);
      //   break;
      case RESOURCE_CONNECT.channel:
        this._getChannelResource();
        break;
      //   B3-14955
      // case RESOURCE_CONNECT.numbers:
      //   this._getNumbersResource();
      //   break;
      case RESOURCE_CONNECT.teams:
        this.resourceCurrentOrg.length && this.resourceRecipientOrg.length
          ? this._getSharedResourceTeam()
          : this._getTeamsResource(this.identityProfileQuery.currentOrg.orgUuid);
        break;
      default:
        break;
    }
  }

  addResource() {
    this.dialog
      .open(ShareResourceDialogComponent, {
        width: '400px',
        data: {
          resourceActive: this.resourceCurrentOrgFiltered,
          currentTab: this.currentTab,
          dataShared: this.dataSource.data,
          recipientOrgUuid:
            this.identityProfileQuery.currentOrg.orgUuid === this.orgs.originatorUuid
              ? this.orgs.recipientUuid
              : this.orgs.originatorUuid
        } as ResourceData,
        autoFocus: false,
        disableClose: true
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.loadResource(this.currentTab);
        }
      });
  }

  deleteResource(shareResource: SharingResource) {
    const recipientOrgUuid =
      this.identityProfileQuery.currentOrg.orgUuid === this.orgs.originatorUuid
        ? this.orgs.recipientUuid
        : this.orgs.originatorUuid;

    let resourceObservable$: Observable<void>;
    let title: string = '';
    let message: string = '';

    switch (this.currentTab) {
      // case RESOURCE_CONNECT.inbox:
      //   resourceObservable$ = this.orgLinkService.revokeShareResourceInboxs(
      //     shareResource.originatorOrgUuid,
      //     recipientOrgUuid,
      //     [{ type: API_TYPE_CONNECT.Inbox, id: shareResource.resourceId }]
      //   );
      //   title = 'Revoke shared inbox';
      //   message = `Are you sure to revoke the inbox - <strong>${shareResource.resourceName}</strong>?`;
      //   break;
      case RESOURCE_CONNECT.channel:
        resourceObservable$ = this.orgLinkService.revokeShareResourceChannel(
          shareResource.originatorOrgUuid,
          recipientOrgUuid,
          [{ type: API_TYPE_CONNECT.Ticket_Channel, id: shareResource.resourceId }]
        );
        title = 'Revoke shared channel';
        message = `Are you sure to revoke the channel - <strong>${shareResource.resourceName}</strong>?`;
        break;
      //   B3-14955
      // case RESOURCE_CONNECT.numbers:
      //   resourceObservable$ = this.orgLinkService.revokeShareResourceNumber(
      //     shareResource?.['licenseId'],
      //     recipientOrgUuid
      //   );
      //   title = 'Revoke shared number';
      //   message = `Are you sure to revoke the number - <strong>${shareResource?.['key']?.substring(
      //     0,
      //     shareResource?.['key']?.indexOf('@')
      //   )}</strong>?`;
      //   break;
      case RESOURCE_CONNECT.teams:
        resourceObservable$ = this.orgLinkService.revokeShareResourceTeams(recipientOrgUuid, shareResource.resourceId);
        title = 'Revoke shared team';
        message = `Are you sure to revoke the team - <strong>${shareResource.resourceName}</strong>?`;
        break;
      default:
        break;
    }

    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px',
        data: <ConfirmDialogInput>{
          message: message,
          title: title,
          confirmLabel: 'Confirm',
          color: 'warn'
        },
        autoFocus: false
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          if (resourceObservable$) {
            resourceObservable$.subscribe(
              _ => {
                this.toastService.success('This resource revoked successfully');
                this.loadResource(this.currentTab);
              },
              error => this.toastService.warning(error.message)
            );
          }
        }
      });
  }

  copied() {
    this.toastService.success('Copied to clipboard');
  }

  onChangePage(pageIndex?: number) {
    this.currentPage = pageIndex;
    this.paginator.pageIndex = pageIndex;
    this.dataSource = new MatTableDataSource<SharingResource | SharingResourceNumber>(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
  }

  private _filterChanged() {
    this.status.valueChanges.pipe(startWith(this.status?.value)).subscribe(_ => {
      this.filterData = {
        status: this.status.value,
        view: this.viewResourceCtrl?.value,
        search: this.searchResourceCtrl?.value?.trim()?.toLowerCase()
      };
      this.loadResource(this.currentTab);
    });

    this.viewResourceCtrl.valueChanges.subscribe(_ => {
      this.filterData = {
        status: this.status.value,
        view: this.viewResourceCtrl?.value,
        search: this.searchResourceCtrl?.value?.trim()?.toLowerCase()
      };
      if (this.currentTab === RESOURCE_CONNECT.numbers) {
        this.loadResource(this.currentTab);
      } else {
        this._updateFilteredData();
      }
    });

    this.searchResourceCtrl.valueChanges.pipe(debounceTime(300)).subscribe(_ => {
      this.filterData = {
        status: this.status.value,
        view: this.viewResourceCtrl?.value,
        search: this.searchResourceCtrl?.value?.trim()?.toLowerCase()
      };

      if (this.currentTab === RESOURCE_CONNECT.numbers) {
        this._updateTableResource(this._filterResourceSharedNumberBySearch(this.cloneDataResourceNumber));
      } else {
        this._updateFilteredData();
      }
    });
  }

  private _updateFilteredData(): void {
    let data = this.cloneDataResource;
    const { view, search } = this.filterData;

    view === VIEW.shared
      ? this._mappingResourceName(data, this.resourceCurrentOrg)
      : this._mappingResourceName(data, this.resourceRecipientOrg);

    if (view) {
      data = this._filterResourceSharedByView(data);
    }

    if (search) {
      data = this._filterResourceSharedBySearch(data);
    }

    this._updateTableResource(data);
  }

  private _getInboxResource(orgUuid: string) {
    this.orgLinkService
      .getInboxResource(orgUuid)
      .pipe(takeUntil(this.destroySubscriber$))
      .subscribe(
        data => {
          this.resourceCurrentOrg = data;
          this._getResourceSharedInbox(this.filterData.status);
        },
        error => {
          this.loading = false;
          this.cloneDataResource = [];
          this._updateTableResource([]);
        }
      );
  }

  private _getNumbersResource() {
    this.orgLinkService
      .getNumbersResource(this.identityProfileQuery.currentOrg.orgUuid, { page: 0, perPage: 100 })
      .pipe(takeUntil(this.destroySubscriber$))
      .subscribe(
        data => {
          this.resourceCurrentOrg = data;
          this._getSharedResourceNumber();
        },
        error => {
          this.loading = false;
          this.cloneDataResourceNumber = [];
          this._updateTableResource([]);
        }
      );
  }

  private _getTeamsResource(orgUuid: string) {
    combineLatest([
      this.teamService.getTeams(orgUuid, { forceLoad: true }),
      this.teamService.getTeams(
        orgUuid === this.orgs.recipientUuid ? this.orgs.originatorUuid : this.orgs.recipientUuid,
        { forceLoad: true }
      )
    ])
      .pipe(takeUntil(this.destroySubscriber$))
      .subscribe(
        ([currentOrg, recipientOrg]) => {
          this.resourceCurrentOrg = currentOrg.map(item => {
            return { uuid: item.uuid, name: item.name, active: item.active } as ResourceActive;
          });
          this.resourceRecipientOrg = recipientOrg.map(item => {
            return { uuid: item.uuid, name: item.name, active: item.active } as ResourceActive;
          });

          this._getSharedResourceTeam();
        },
        error => {
          this.loading = false;
          this._updateTableResource([]);
        }
      );
  }

  private _getSharedResourceNumber() {
    const orgUuidByView =
      this.viewResourceCtrl.value === VIEW.received ? this.orgs.originatorUuid : this.orgs.recipientUuid;

    this.loading = false;
    this.orgLinkService
      .getResourceShareNumber(orgUuidByView, this.viewResourceCtrl.value)
      .pipe(takeUntil(this.destroySubscriber$))
      .subscribe(
        data => {
          this.resourceCurrentOrgFiltered = this.resourceCurrentOrg.filter(
            rc => !data.some(item => item.key?.replace(/@.*$/, '') === rc.name)
          );

          this.cloneDataResourceNumber = data;

          this._updateTableResource(this._filterResourceSharedNumberBySearch(data));
        },
        error => {
          this.cloneDataResourceNumber = [];
          this._updateTableResource([]);
        }
      );
  }

  private _getSharedResourceTeam() {
    this.orgLinkService
      .getSharingResourceOfConnection(
        this.identityProfileQuery.currentOrg.orgUuid,
        this.route.snapshot.params['uuid'],
        API_TYPE_CONNECT.Teams,
        this.filterData.status
      )
      .pipe(
        takeUntil(this.destroySubscriber$),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        data => {
          // Filter the resources to share from the shared resources.
          this.resourceCurrentOrgFiltered = this.resourceCurrentOrg.filter(
            rc => !data.some(item => item.resourceId === rc.uuid) && rc.active
          );

          this.filterData.view === VIEW.shared
            ? this._mappingResourceName(data, this.resourceCurrentOrg)
            : this._mappingResourceName(data, this.resourceRecipientOrg);

          this.cloneDataResource = data;

          if (this.filterData.view) {
            data = this._filterResourceSharedByView(data);
          }

          if (this.filterData.search) {
            data = this._filterResourceSharedBySearch(data);
          }

          this._updateTableResource(data);
        },
        error => {
          this.cloneDataResource = [];
          this._updateTableResource([]);
        }
      );
  }

  private _getResourceSharedInbox(status: string) {
    this.orgLinkService
      .getResourceSharedInbox(this.orgs.recipientUuid, this.orgs.originatorUuid, status)
      .pipe(
        takeUntil(this.destroySubscriber$),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        data => {
          // Filter the resources to share from the shared resources.
          this.resourceCurrentOrgFiltered = this.resourceCurrentOrg.filter(
            rc => !data.some(item => item.resourceId === rc.uuid)
          );

          this.cloneDataResource = data;

          if (this.filterData.view) {
            data = this._filterResourceSharedByView(data);
          }

          if (this.filterData.search) {
            data = this._filterResourceSharedBySearch(data);
          }

          this._updateTableResource(data);
        },
        error => {
          this.cloneDataResource = [];
          this._updateTableResource([]);
        }
      );
  }

  private _filterResourceSharedByView(data: SharingResource[]) {
    return data.filter(item => {
      return this.filterData.view === VIEW.shared
        ? item.originatorOrgUuid === this.identityProfileQuery.currentOrg.orgUuid
        : item.originatorOrgUuid !== this.identityProfileQuery.currentOrg.orgUuid;
    });
  }

  private _filterResourceSharedBySearch(data: SharingResource[]) {
    return data.filter(
      item =>
        item.resourceId?.includes(this.filterData.search) ||
        item.resourceName?.toLocaleLowerCase().trim().includes(this.filterData.search)
    );
  }

  private _filterResourceSharedNumberBySearch(data: SharingResourceNumber[]) {
    return data.filter(item => item.key?.includes(this.filterData.search));
  }

  private _mappingResourceName(data: SharingResource[], typeResource: ResourceActive[]) {
    data.forEach(s => {
      typeResource.forEach(item => {
        if (item.uuid === s.resourceId && !s.resourceName) {
          s.resourceName = item.name;
        }
      });
    });
  }

  private _updateTableResource(data: SharingResource[] | SharingResourceNumber[]) {
    this.dataSource = new MatTableDataSource<SharingResource | SharingResourceNumber>(data);
    this.dataSource.paginator = this.paginator;
  }

  private _getChannelResource() {
    this.orgLinkService
      .getChannelResource(TxnChannel.supportCenter)
      .pipe(takeUntil(this.destroySubscriber$))
      .subscribe(
        data => {
          this.resourceCurrentOrg = data;
          this._getResourceSharedChannel(this.filterData.status);
        },
        error => {
          this.loading = false;
          this.cloneDataResource = [];
          this._updateTableResource([]);

          if (error && error.code === 'sc.Unauthorized') {
            this.toastService.warning(
              'You need the contact center addon license to perform actions in the shared support ticket channel.',
              5000
            );
          }
        }
      );
  }

  private _getResourceSharedChannel(status: string) {
    const recipientUuid =
      this.orgs.originatorUuid === this.identityProfileQuery.currentOrg.orgUuid
        ? this.orgs.recipientUuid
        : this.orgs.originatorUuid;
    this.orgLinkService
      .getResourceSharedTicketChannel(this.identityProfileQuery.currentOrg.orgUuid, recipientUuid, status)
      .pipe(
        takeUntil(this.destroySubscriber$),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        data => {
          // Filter the resources to share from the shared resources.
          this.resourceCurrentOrgFiltered = this.resourceCurrentOrg.filter(
            rc => !data.some(item => item.resourceId === rc.uuid)
          );

          this.cloneDataResource = data;

          if (this.filterData.view) {
            data = this._filterResourceSharedByView(data);
          }

          if (this.filterData.search) {
            data = this._filterResourceSharedBySearch(data);
          }

          this._updateTableResource(data);
        },
        error => {
          this.cloneDataResource = [];
          this._updateTableResource([]);
        }
      );
  }
}
