export class SessionKB {
  convo: string;
  summary: string; // title
  lastActivityTime: number;

  constructor(obj?: Partial<SessionKB>) {
    if (obj) {
      Object.assign(this, obj);

      if (!this.summary) this.summary = 'No title';
    }
  }
}
