export interface NewsfeedUI {
  loaded: boolean;
  selectedTab: SelectedTabNewsfeed;
  openPostId: string;
}

export enum SelectedTabNewsfeed {
  posts = 'posts',
  files = 'files',
  publishers = 'publishers',
  subscribers = 'subscribers',
  granted_organization = 'granted-organization'
}
