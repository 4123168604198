import { Injectable } from '@angular/core';
import { ID, Order, QueryEntity } from '@datorama/akita';
import { SessionKB } from './sessions.model';
import { SessionsState, SessionsStore } from './sessions.store';

@Injectable({ providedIn: 'root' })
export class SessionsQuery extends QueryEntity<SessionsState> {
  constructor(protected override store: SessionsStore) {
    super(store);
  }

  getItem = (id: string | ID) => this.getEntity(id);
  selectItem = (id: string | ID) => this.selectEntity(id);
  selectPropertyItem = <K extends keyof SessionKB>(id: string, property: K) => this.selectEntity(id, property);
  getActiveIdItem = () => this.getActiveId();
  selectActiveIdItem = () => this.selectActiveId();
  getActiveItem = () => this.getActive();
  selectActiveItem = () => this.selectActive();
  selectManyItem = (id: string[] | ID[]) => this.selectMany(id);
  getState = () => this.getValue();

  selectRecentChannels() {
    return this.selectAll({
      sortBy: 'lastActivityTime',
      sortByOrder: Order.DESC
    });
  }
}
