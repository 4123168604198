import { Injectable } from '@angular/core';
import { ChatMessage, ChatService, MsgType, SystemMessageData, SystemMsgType } from '@b3networks/api/chat';
import { Notification, NotificationsQuery, NotificationsService } from '@b3networks/api/inbox';
import { SessionQuery } from '@b3networks/portal/base/shared';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NotificationReceiveProcessor {
  private _notification$: Subject<Notification> = new Subject();

  constructor(
    private sessionQuery: SessionQuery,
    private chatService: ChatService,
    private toastService: ToastService,
    private notificationsQuery: NotificationsQuery,
    private notificationsService: NotificationsService
  ) {}

  pushEventNotification(notification: Notification) {
    this._notification$.next(notification);
  }

  onNotification(): Observable<Notification> {
    return this._notification$.asObservable().pipe(share());
  }

  process(message: ChatMessage) {
    if (!message) return;
    if (message.err) {
      this.toastService.error(message.err);
      return;
    }

    if (message.mt === MsgType.system) {
      if (!message.body.data) {
        console.error(`custom message without data`);
        return;
      }

      switch (message.body.data.type) {
        case SystemMsgType.notificationSyncComm: {
          const meIdentity = this.sessionQuery.profile.uuid;
          const system = <SystemMessageData>message.body?.data;
          const notification = system?.notification;
          if (notification) {
            const noti = this.notificationsService.addNotification2Store(
              <Notification>{
                ...notification,
                type: notification.type as string,
                isRead: notification.isRead == null ? undefined : notification.isRead,
                isClicked: notification.isClicked == null ? undefined : notification.isClicked
              },
              meIdentity
            );
            this.pushEventNotification(noti);
          }
          break;
        }
        case SystemMsgType.seenNotifcationSyncComm: {
          const meIdentity = this.sessionQuery.profile.uuid;
          const system = <SystemMessageData>message.body?.data;
          const notificationId = system?.notificationId;
          if (notificationId?.length > 0) {
            notificationId?.forEach(notiId => {
              let noti = this.notificationsQuery.getEntity(notiId);
              if (noti) {
                noti = this.notificationsService.addNotification2Store(
                  <Notification>{
                    ...noti,
                    isClicked: true
                  },
                  meIdentity
                );
                this.pushEventNotification(noti);
              }
            });
          }
          break;
        }

        default:
          console.error(`customized system message unhandled: ${message.body.data.type}`, message);
          break;
      }
    }
  }
}
