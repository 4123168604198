import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { ChannelUI, createInitUI } from './model/channel-ui.model';
import { Channel, ChatChannelStoreName, RecentChannel } from './model/channel.model';

export interface ChannelState extends EntityState<Channel>, ActiveState {
  recentChannels: RecentChannel[];
  loaded: boolean; // called getPublicChannels api
  loadedPublic: boolean; // called getPublicChannels api
  loadedMineChannel: boolean; // called getMines api
  loadedPersonalChannel: boolean; // called getPersonalChannels api
  isDisconnected: boolean;
  loadedMyFeeds: boolean;

  rangeThreadsUser?: {
    loadedFirst?: boolean;
    hasMoreBackward?: boolean; // decs
    hasMoreForward?: boolean; // asc
  };
}

export type ChannelUIState = EntityState<ChannelUI>;

export class ChannelMixin extends Channel implements ChannelUI {
  constructor(obj?: Partial<Channel & ChannelUI>) {
    super(obj);
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ChatChannelStoreName, idKey: 'id' })
export class ChannelStore extends EntityStore<ChannelState> {
  override ui: EntityUIStore<ChannelUIState>;
  constructor() {
    super({
      recentChannels: [],
      rangeThreadsUser: {
        loadedFirst: false,
        hasMoreBackward: false,
        hasMoreForward: false
      }
    });

    this.createUIStore({}, { deepFreezeFn: obj => obj }).setInitialEntityState(entity => createInitUI());
  }
}
