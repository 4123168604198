import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { B3_DOMAIN, B3Sentry, initializeLogger, isLocalhost, logger, TESTING_DOMAIN } from '@b3networks/shared/common';
import { enableAkitaProdMode } from '@datorama/akita';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();

  if (!isLocalhost()) {
    console.log = function () {
      // donothing
    };
  }
}

const keepLogDomain = [atob(TESTING_DOMAIN), atob(B3_DOMAIN)];
const shouldKeepLog = keepLogDomain.includes(location.host) || !environment.production;

initializeLogger('PortalBase', environment.production, shouldKeepLog);

let tabUuid;
try {
  tabUuid = window?.['tabUuid'];
  B3Sentry.init({
    nameApp: 'PortalBase',
    enabled: environment.enableSentry,
    debug: !environment.production,
    browserId: `PortalBase_${tabUuid || new Date().getTime()}`,
    isPortal: true
  });
} catch (error) {
  logger.error('Error while initializing Sentry', error);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

window.addEventListener('load', function () {
  (<HTMLElement>document.querySelector('.app-loading')).style.display = 'none';
});
