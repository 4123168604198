import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ReqCreateTxnWhatsapp, RequestSendMsgWhatsapp } from './whatsapp.model';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {
  constructor(private http: HttpClient) {}

  sendMessage(req: RequestSendMsgWhatsapp) {
    return this.http.post<any[]>('inbox/private/v1/whatsapp/messages/send', req).pipe(
      tap(res => {
        console.log('res: ', res);
      })
    );
  }

  createTxnWhatsapp(req: ReqCreateTxnWhatsapp) {
    return this.http.post<{ convoUuid: string; txnUuid: string }>('inbox/private/v1/whatsapp/_create', req);
  }
}
