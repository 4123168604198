import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtensionBase } from '@b3networks/api/bizphone';
import { Page, Pageable } from '@b3networks/api/common';
import { X_PAGINATION } from '@b3networks/shared/common';
import { ID } from '@datorama/akita';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AuthenticationMode,
  BYOPTrunkMapping,
  CarrierConnectApp,
  DetailSipAccount,
  GETBYOPTrunkMappingReq,
  LicenseTypeSipAccount,
  ParamsConnectSipToApp,
  ReqUpdateIpPeer,
  RoutingConfigSip,
  SipAccount,
  TypeSipAccount,
  updateHAToSipTrunkParams
} from './sip-trunk.model';
import { SipTrunkStore } from './sip-trunk.store';
import { ConnectItem } from '@b3networks/api/connect';
import { OrgConnection } from '@b3networks/api/auth';
import { ORG_CONNECT_STATUS } from '@b3networks/portal/org/feature/org-link';

@Injectable({ providedIn: 'root' })
export class SipTrunkService {
  private sipControlSubject = new BehaviorSubject<SipAccount>(new SipAccount());
  sipControl$ = this.sipControlSubject.asObservable();

  constructor(
    private store: SipTrunkStore,
    private http: HttpClient
  ) {}

  setSipControl(control: SipAccount) {
    this.sipControlSubject.next(control);
  }

  getAccounts(licenseType?: LicenseTypeSipAccount) {
    const params = new HttpParams().append('licenseType', licenseType);

    return this.http.get<SipAccount[]>('callcenter/private/v1/sipTrunk', { params: params }).pipe(
      map(entities => entities.map(i => new SipAccount(i))),
      tap(entities => this.store.upsertMany(entities, { baseClass: SipAccount }))
    );
  }

  updateAccountSipTrunk(sipUsername: string, req: Partial<SipAccount>) {
    return this.http.put<SipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}`, req).pipe(
      map(account => new SipAccount(account)),
      tap(account => {
        delete account.detail;
        this.store.upsertMany([account], { baseClass: SipAccount });
      })
    );
  }

  connectPowerSipTrunkApp(sipUsername: string, req: Partial<SipAccount>) {
    return this.http
      .put<SipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}`, req)
      .pipe(map(sip => new SipAccount(sip)));
  }

  getDetailAccountSipTrunk(sipUsername: string, isSecondary = false) {
    return this.http.get<SipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}`).pipe(
      map(account => new SipAccount({ ...account, isSecondary })),
      tap(account => {
        this.store.upsertMany([account], { baseClass: SipAccount });
      })
    );
  }

  getRoutingConfig(sipUsername: string, keyword: string, pagable: Pageable): Observable<Page<RoutingConfigSip>> {
    let params = new HttpParams();
    if (keyword) {
      params = params.append('keyword', keyword);
    }
    if (pagable) {
      params = params.append('page', pagable.page.toString()).append('perPage', pagable.perPage.toString());
    }

    return this.http
      .get<RoutingConfigSip[]>(`callcenter/private/v1/sipTrunk/${sipUsername}/routingConfig`, {
        params: params,
        observe: 'response'
      })
      .pipe(
        map(response => {
          const page = new Page<RoutingConfigSip>();
          page.content = response.body;
          page.totalCount = +response.headers.get(X_PAGINATION.totalCount);
          return page;
        })
      );
  }
  getBYOPTrunkMapping(
    sipUsername: string,
    params?: GETBYOPTrunkMappingReq,
    pagable?: Pageable
  ): Observable<Page<BYOPTrunkMapping>> {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key]) {
          httpParams = httpParams.set(key, params[key]);
        }
      });
    }
    if (pagable) {
      httpParams = httpParams.append('page', pagable.page.toString()).append('perPage', pagable.perPage.toString());
    }
    return this.http
      .get<BYOPTrunkMapping[]>(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/query`, {
        params: httpParams,
        observe: 'response'
      })
      .pipe(
        map(response => {
          const page = new Page<BYOPTrunkMapping>();
          page.content = response.body;
          page.totalCount = +response.headers.get(X_PAGINATION.totalCount);
          return page;
        })
      );
  }

  createRoutingConfig(sipUsername: string, req: RoutingConfigSip) {
    return this.http.post<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/routingConfig`, req);
  }

  updateRoutingConfig(sipUsername: string, req: RoutingConfigSip) {
    return this.http.put<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/routingConfig/${req.number}`, req);
  }

  deleteRoutingConfig(sipUsername: string, rule: string) {
    return this.http.delete<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/routingConfig/${rule}`);
  }

  getCallerIdISDN() {
    return this.http.get<string[]>('callcenter/private/v1/sipTrunk/callerIds/isdn').pipe(
      tap(entities => {
        return this.store.update({
          isdnCallerIds: entities || []
        });
      })
    );
  }

  getAvailableCallerIds() {
    return this.http.get<string[]>('callcenter/private/v1/sipTrunk/callerIds').pipe(
      tap(entities =>
        this.store.update({
          availableCallerIds: entities || []
        })
      )
    );
  }

  resetPassword(sipUsername: string, newPassword: string) {
    return this.http.put(
      `callcenter/private/v1/sipTrunk/${sipUsername}/resetPassword`,
      { newPassword: newPassword },
      { responseType: 'text' }
    );
  }

  getTLSKeyAccount(sipUsername: string) {
    return this.http.get(`appsip/accounts/${sipUsername}/tls-key`);
  }

  addIpWhiteList(sipUsername: string, list: string[]) {
    return this.http
      .put<DetailSipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}/addIpWhiteList`, {
        ips: list
      })
      .pipe(
        tap(detail => {
          this.store.update(sipUsername, { detail: detail });
          const data = [...this.store.getValue().listSip];
          const index = data.findIndex(item => item.sipUsername === sipUsername);
          if (index !== -1) {
            data[index] = new SipAccount({
              ...data[index],
              detail: detail
            });
            this.store.update({ listSip: data });
          }
        })
      );
  }

  removeIpWhiteList(sipUsername: string, ip: string) {
    return this.http
      .put<DetailSipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}/removeIpWhiteList`, {
        ip
      })
      .pipe(
        tap(detail => {
          this.store.update(sipUsername, { detail: detail });
          const data = [...this.store.getValue().listSip];
          const index = data.findIndex(item => item.sipUsername === sipUsername);
          if (index !== -1) {
            data[index] = new SipAccount({
              ...data[index],
              detail: detail
            });
            this.store.update({ listSip: data });
          }
        })
      );
  }

  updateAuthenticationMode(sipUsername: string, mode: AuthenticationMode) {
    return this.http
      .put<DetailSipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}/updateAuthenticationMode`, {
        mode: mode
      })
      .pipe(
        tap(detail => {
          this.store.update(sipUsername, { detail: detail });
          const data = [...this.store.getValue().listSip];
          const index = data.findIndex(item => item.sipUsername === sipUsername);
          if (index !== -1) {
            data[index] = new SipAccount({
              ...data[index],
              detail: detail
            });
            this.store.update({ listSip: data });
          }
        })
      );
  }

  updateLabel(sipUsername: string, label: string) {
    return this.http
      .put(
        `callcenter/private/v1/sipTrunk/${sipUsername}/updateLabel`,
        {
          label: label
        },
        {
          responseType: 'text'
        }
      )
      .pipe(
        tap(text => {
          this.store.update(sipUsername, entity => {
            return {
              ...entity,
              detail: {
                ...entity.detail,
                label: text
              }
            };
          });

          const data = [...this.store.getValue().listSip];
          const index = data.findIndex(item => item.sipUsername === sipUsername);
          if (index !== -1) {
            data[index] = new SipAccount({
              ...data[index],
              detail: {
                ...data[index].detail,
                label: text
              }
            });
            this.setSipControl(data[index]);
            this.store.update({ listSip: data });
          }
        })
      );
  }

  updateIpPeer(sipUsername: string, req: ReqUpdateIpPeer) {
    return this.http.put<DetailSipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}/updateIpPeer`, req).pipe(
      tap(detail => {
        this.store.update(sipUsername, { detail: detail });
        const data = [...this.store.getValue().listSip];
        const index = data.findIndex(item => item.sipUsername === sipUsername);
        if (index !== -1) {
          data[index] = new SipAccount({
            ...data[index],
            detail: detail
          });
          this.store.update({ listSip: data });
        }
      })
    );
  }

  setActiveSip(sipUsername: string | ID) {
    this.store.setActive(sipUsername);
  }

  updateSIPPending(sipUsername: string | ID) {
    const data = this.store.getValue();
    const index = data.listSip.findIndex(item => item.sipUsername === sipUsername);
    if (index !== -1) {
      const updatedSIPPending = [...data.listSip];

      updatedSIPPending[index] = new SipAccount({
        ...data.listSip[index],
        pendingConnectOrg: true
      });
      const updatedAvailableSips = data.availableSips.filter(item => item.id !== updatedSIPPending[index].id);

      this.store.add(updatedSIPPending[index]);
      this.store.update({
        listSip: updatedSIPPending,
        availableSips: updatedAvailableSips
      });
      this.store.set(updatedSIPPending);
    }
  }

  removeActiveSip(sipUsername: string | ID) {
    this.store.removeActive(sipUsername);
  }

  updateAccount(sipUsername: string, state: SipAccount) {
    this.store.update(sipUsername, enitty => ({
      ...enitty,
      ...state
    }));
  }

  import(sipUsername: string, fileKey: string) {
    return this.http.post(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/import`, { fileKey });
  }

  createOrUpdateByopRouting(sipUsername: string, req: BYOPTrunkMapping) {
    let params = new HttpParams();
    const body = { ...req };
    if (req?.oldExtKey) {
      params = params.set('oldExtKey', req.oldExtKey);
      delete body.oldExtKey;
    }

    return this.http.put<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping`, body, {
      params: params
    });
  }
  deleteByopRouting(sipUsername: string, rule: string) {
    return this.http.delete<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/${rule}`);
  }

  getExtAvailableMapping(sipUsername: string) {
    return this.http
      .get<ExtensionBase[]>(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/extension/_all`)
      .pipe(
        map(exts => exts.map(x => new ExtensionBase(x))),
        tap(exts => {
          this.store.update({
            availableExt: exts || []
          });
        })
      );
  }

  getExportBYOPMappingConfig(sipUsername: string) {
    return this.http.get(`/callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/export`, {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

  getValidByopTrunkMappingNumber(sipUsername: string, byopNumber: number) {
    return this.http.get<any>(
      `callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/validateLegacyPBXExt/${byopNumber}`
    );
  }

  getCarrierConnectApp() {
    return this.http.get<CarrierConnectApp[]>(`callcenter/private/v1/carrierConnectApp`).pipe(
      tap(entities =>
        this.store.update({
          carrierConnectApp: entities || []
        })
      )
    );
  }
  updateCarrierConnectApp(req: CarrierConnectApp) {
    return this.http.put<CarrierConnectApp>(`callcenter/private/v1/carrierConnectApp/${req.id}`, req);
  }

  createCarrierConnectApp(req: CarrierConnectApp) {
    return this.http.post<CarrierConnectApp>(`callcenter/private/v1/carrierConnectApp`, req);
  }

  deleteCarrierConnectApp(req: CarrierConnectApp) {
    return this.http.delete<CarrierConnectApp>(`callcenter/private/v1/carrierConnectApp/${req.id}`);
  }

  connectSipToApp(params: ParamsConnectSipToApp) {
    const data = this.store.getValue();
    const index = data.listSip.findIndex(item => item.id === params.sip.id);
    if (index !== -1) {
      const updatedListSips = [...data.listSip];

      updatedListSips[index] = new SipAccount({
        ...data.listSip[index],
        type: params.type,
        connectorConfig: {
          ...data.listSip[index].connectorConfig,
          orgConnectorOrgUuid: params.orgUuid,
          carrierConnectors: [params.connectUuid],
          orgConnectorParty: params.orgName
        }
      });
      const itemFound = updatedListSips[index];
      const newListSips = [...updatedListSips.slice(0, index), ...updatedListSips.slice(index + 1)];
      const updatedAvailableSips = data.availableSips.filter(item => item.id !== itemFound.id);

      this.store.add(itemFound);
      this.store.update({
        listSip: [itemFound, ...newListSips],
        availableSips: updatedAvailableSips
      });
      this.store.set([itemFound, ...newListSips]);
      this.setSipControl(itemFound);
    }
  }

  getAllSipPST(isSetListSip?: boolean) {
    return this.http
      .get<SipAccount[]>(`callcenter/private/v1/sipTrunk?licenseType=${LicenseTypeSipAccount.POWER_SIP_TRUNK}`)
      .pipe(
        map(entities => entities.map(i => new SipAccount(i))),
        tap(entities => {
          this.updateState(entities, isSetListSip);
        })
      );
  }

  updateHAToSipTrunk(sipUsername: string, params?: updateHAToSipTrunkParams) {
    if (params) {
      return this.http.put<SipAccount>(
        `callcenter/private/v1/sipTrunk/${sipUsername}/ha?isRemoveHA=${params.isRemoveHA}&secondarySipUsername=${params.secondarySipUsername}`,
        {}
      );
    } else {
      return this.http.put<SipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}/ha`, {});
    }
  }

  updateCustomParams(customParams: string[]) {
    this.store.update({
      customParams: customParams || []
    });
  }

  updateListApps(listApp: ConnectItem[], telcoApp?: boolean) {
    const currentListApp = this.store.getValue().listApp || [];

    const filteredNewItems = listApp.filter(
      newItem => !currentListApp.some(existingItem => existingItem.name === newItem.name)
    );

    const addNewTypeToApp = filteredNewItems.map(appItem => {
      if (!appItem.type && telcoApp) {
        return { ...appItem, type: TypeSipAccount.ORG_CONNECTOR };
      }

      if (!appItem.type && !telcoApp) {
        return { ...appItem, type: TypeSipAccount.CARRIER_CONNECTOR };
      }

      return appItem;
    });

    this.store.update({
      listApp: [...currentListApp, ...addNewTypeToApp]
    });
  }

  async updateListOrgConnector(listOrgConnector: OrgConnection[], listSips: SipAccount[]) {
    const updatedListSip = await Promise.all(
      listSips.map(async itemSIP => {
        const hasMatchingOrgRejected = listOrgConnector.some(
          itemOrg =>
            itemSIP.type === TypeSipAccount.ORG_CONNECTOR &&
            itemOrg.status === ORG_CONNECT_STATUS.rejected &&
            itemOrg.recipient.uuid === itemSIP.connectorConfig.orgConnectorOrgUuid
        );

        if (hasMatchingOrgRejected) {
          const params = {
            type: TypeSipAccount.EMPTY,
            connectorConfig: {
              ...itemSIP.connectorConfig,
              orgConnectorParty: '',
              orgConnectorOrgUuid: ''
            }
          };

          await this.connectPowerSipTrunkApp(itemSIP.sipUsername, params).toPromise();

          return new SipAccount({
            ...itemSIP,
            type: TypeSipAccount.EMPTY,
            pendingConnectOrg: false,
            connectorConfig: {
              ...itemSIP.connectorConfig,
              orgConnectorOrgUuid: '',
              orgConnectorParty: ''
            }
          });
        } else {
          return itemSIP;
        }
      })
    );

    this.updateState(updatedListSip, true);

    this.store.update({
      listOrgConnector: listOrgConnector ?? []
    });
  }

  updateState(entities: SipAccount[], isSetListSip?: boolean) {
    const listAssignSips: SipAccount[] = [];
    const listAvailSips: SipAccount[] = [];
    entities.forEach(item => {
      if (item?.type && item?.type !== TypeSipAccount.EMPTY && item?.type?.length > 0) {
        listAssignSips.push(item);
      } else {
        if (!item.type || item.type.length === 0) {
          item.type = TypeSipAccount.EMPTY;
        }
        listAvailSips.push(item);
      }
    });
    if (isSetListSip) {
      this.store.update({
        availableSips: listAvailSips || [],
        assignedSips: listAssignSips || [],
        listSip: entities || []
      });
      this.store.set(entities);
    }
  }
}
