<div class="security">
  <div class="area mat-elevation-z2">
    <div class="headerSection">
      <h1>Security status</h1>
      <div class="iconContainer">
        <mat-icon class="invalid" *ngIf="getErrorIssueNumber() > 0 || getWarningIssueNumber() > 0">error</mat-icon>
        <mat-icon class="valid" *ngIf="getErrorIssueNumber() <= 0 && getWarningIssueNumber() <= 0">
          check_circle
        </mat-icon>
      </div>
      <div>{{ getHeaderHint() }}</div>
    </div>

    <div class="issueSection">
      <mat-expansion-panel
        hideToggle
        [expanded]="isPasswordPromError() || isPasswordPromWarning() || isPasswordComplexityError()"
        [disabled]="!isPasswordPromError() && !isPasswordPromWarning() && !isPasswordComplexityError()"
        class="item"
      >
        <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
          <mat-panel-title>
            <div class="flex items-center gap-4">
              <mat-icon
                *ngIf="!isPasswordPromError() && !isPasswordPromWarning() && !isPasswordComplexityError()"
                class="valid"
              >
                check_circle
              </mat-icon>
              <mat-icon class="invalid" *ngIf="isPasswordComplexityError() || isPasswordPromError()">error</mat-icon>
              <mat-icon
                class="invalid"
                *ngIf="!(isPasswordComplexityError() || isPasswordPromError()) && isPasswordPromWarning()"
              >
                error
              </mat-icon>

              <div class="flex flex-col items-start gap-0.5">
                <div class="header__title">Password</div>
                <div class="header__sub-title mat-caption">
                  <ng-container
                    *ngIf="!isPasswordPromError() && !isPasswordPromWarning() && !isPasswordComplexityError()"
                  >
                    Your password is valid
                  </ng-container>
                  <ng-container *ngIf="isPasswordComplexityError()">
                    Your password has expired and need to set a new password to comply with the password policy.
                  </ng-container>
                  <ng-container *ngIf="isPasswordPromError() && !isPasswordComplexityError()">
                    Your password has been expired. You have to fill new password.
                  </ng-container>
                  <ng-container *ngIf="isPasswordPromWarning() && !isPasswordComplexityError()">
                    Your password will expire in
                    <strong>{{ securityCompliance.passwordDaysBeforeExpiry }}</strong> day(s).
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="changePasswordForm" class="expanded flex flex-col gap-4">
          <mat-form-field>
            <input
              matInput
              type="password"
              placeholder="Old password"
              formControlName="oldPassword"
              (keyup.enter)="updatePersonal()"
              (keypress)="resetError()"
            />
            <mat-hint class="error-hint" *ngIf="error.oldPassword">{{ error.oldPassword }}</mat-hint>
          </mat-form-field>
          <mat-form-field class="mb-0">
            <input
              matInput
              type="password"
              placeholder="New password"
              formControlName="newPassword"
              (keyup.enter)="updatePersonal()"
              (keypress)="resetError()"
            />
          </mat-form-field>
          <div class="password-complexity">
            <div>Password must comply with the following requirements:</div>
            <div
              [class.touched]="newPasswordCtrl.touched || newPasswordCtrl.dirty"
              [ngClass]="{
                error: newPasswordCtrl.hasError('minlength') || newPasswordCtrl.hasError('required'),
                success: !newPasswordCtrl.hasError('minlength') && !newPasswordCtrl.hasError('required')
              }"
            >
              - At least {{ passwordPolicy?.minimumLength || 6 }} characters
            </div>
            <div
              *ngIf="passwordPolicy?.maximumLength"
              [class.touched]="newPasswordCtrl.touched || newPasswordCtrl.dirty"
              [ngClass]="{
                error: newPasswordCtrl.hasError('maxlength'),
                success: !newPasswordCtrl.hasError('maxlength')
              }"
            >
              - At most {{ passwordPolicy.maximumLength }} characters
            </div>
            <div *ngFor="let policy of policies">
              <span
                *ngIf="passwordPolicy?.[policy.name] || !passwordPolicy"
                [class.touched]="newPasswordCtrl.touched || newPasswordCtrl.dirty"
                [ngClass]="{
                  error: newPasswordCtrl.hasError(policy.name),
                  success: !newPasswordCtrl.hasError(policy.name)
                }"
                >{{ policy.displayText }}</span
              >
            </div>
          </div>
          <mat-form-field>
            <input
              matInput
              type="password"
              placeholder="Confirm new password"
              formControlName="confirmNewPassword"
              (keyup.enter)="updatePersonal()"
              (keypress)="resetError()"
            />
            <mat-hint class="error-hint" *ngIf="error.confirmPassword">{{ error.confirmPassword }}</mat-hint>
          </mat-form-field>
          <div class="ui error message" *ngIf="error.serverError">{{ error.serverError }}</div>
          <div class="password-actions">
            <button
              mat-raised-button
              [disabled]="changePasswordForm.invalid"
              [loading]="progressing"
              color="primary"
              (click)="updatePersonal()"
            >
              Update
            </button>
          </div>
        </form>
      </mat-expansion-panel>

      <mat-expansion-panel hideToggle [expanded]="!tfaInfo.tfaEnabled" [disabled]="tfaInfo.tfaEnabled" class="item">
        <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
          <mat-panel-title>
            <div class="flex items-center gap-4">
              <mat-icon *ngIf="tfaInfo.tfaEnabled" class="valid">check_circle</mat-icon>
              <mat-icon class="invalid" *ngIf="isTfaError()">error</mat-icon>
              <mat-icon class="invalid" *ngIf="!isTfaError() && isTfaWarning()">error</mat-icon>
              <div class="flex flex-col items-start gap-0.5">
                <div class="header__title">Two-factor authentication</div>
                <div class="header__sub-title mat-caption">
                  <ng-container *ngIf="tfaInfo.tfaEnabled">Two-factor authentication is on.</ng-container>
                  <ng-container *ngIf="isTfaWarning()">Two-factor authentication is off</ng-container>
                  <ng-container *ngIf="isTfaError()">You have to enable it before continue.</ng-container>
                </div>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="flex justify-between items-center pb-2">
          <label>
            <span>Status:</span>
            <strong>{{ tfaInfo.tfaEnabled ? ' On' : ' Off' }}</strong>
          </label>
          <button mat-flat-button color="primary" (click)="setup2fa()" *ngIf="!tfaInfo.tfaEnabled">Enable</button>
        </div>
      </mat-expansion-panel>
    </div>
  </div>

  <div class="text-right mt-5" *ngIf="getErrorIssueNumber() <= 0 && getWarningIssueNumber() <= 0">
    <button mat-flat-button color="primary" (click)="continue()" [loading]="continueProcessing">Continue</button>
  </div>
</div>
