import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter } from 'rxjs/operators';
import { DomainConfig } from './domain-config.model';
import { DomainConfigStore } from './domain-config.store';

@Injectable({ providedIn: 'root' })
export class DomainConfigQuery extends Query<DomainConfig> {
  orgConfig$ = this.select().pipe(filter(c => Object.keys(c)?.length > 0));

  busyRemarks$ = this.select('remarks');
  thresholdConfig$ = this.select('thresholdConfig');
  awayDetectionUnansweredThreshold$ = this.select('awayDetectionUnansweredThreshold');

  constructor(protected override store: DomainConfigStore) {
    super(store);
  }
}
