import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { ChatWidgetState, ChatWidgetStore } from './chat-widget.store';

@Injectable({ providedIn: 'root' })
export class ChatWidgetQuery extends QueryEntity<ChatWidgetState> {
  constructor(protected override store: ChatWidgetStore) {
    super(store);
  }

  getOne(widgetUuid: string) {
    return this.getEntity(widgetUuid);
  }

  selectOne(widgetUuid: string) {
    return this.selectEntity(widgetUuid);
  }

  selectAllWidget() {
    return this.selectAll({
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    });
  }
}
