import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { DraftMsgNewsfeed, DraftMsgNewsfeedUI, ModeDraft } from './draft-message.model';

export interface DraftMsgNewsfeedState extends EntityState<DraftMsgNewsfeed> {}

export type DraftMsgNewsfeedUIState = EntityState<DraftMsgNewsfeedUI>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_newsfeed_draft_msg', idKey: 'id' })
export class DraftMsgNewsfeedStore extends EntityStore<DraftMsgNewsfeedState> {
  override ui: EntityUIStore<DraftMsgNewsfeedUIState>;

  constructor() {
    super();
    this.createUIStore({}, { deepFreezeFn: obj => obj }).setInitialEntityState(
      entity =>
        <DraftMsgNewsfeedUI>{
          mode: ModeDraft.preview
        }
    );
  }
}
